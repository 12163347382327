import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import reducers from './rootReducer';

const { feedback, incorporation, hiring, leadManager, panel, ...rest } = reducers;

// Root reducer persist in session storage
const rootPersistConfig = {
  key: 'root-scp',
  storage: require('redux-persist/lib/storage/session').default,
  whitelist: ['wizard', 'plans', 'uf'],
};

// Incorporation reducer persist in local storage
const incorporationPersistConfig = {
  key: 'incorporation-scp',
  storage: require('redux-persist/lib/storage').default,
};

// Incorporation reducer persist in local storage
const feedbackPersistConfig = {
  key: 'helpcenter-feedback',
  storage: require('redux-persist/lib/storage').default,
};

// Hiring reducer persist in local storage
const hiringPersistConfig = {
  key: 'hiring-scp',
  storage: require('redux-persist/lib/storage').default,
};

const leadManagerDetailPersistConfig = {
  key: 'leadManagerDetail-scp',
  storage: require('redux-persist/lib/storage').default,
};

const panelPersistConfig = {
  key: 'panel-scp',
  storage: require('redux-persist/lib/storage').default,
};

const rootReducer = combineReducers({
  incorporation: persistReducer(incorporationPersistConfig, incorporation),
  feedback: persistReducer(feedbackPersistConfig, feedback),
  hiring: persistReducer(hiringPersistConfig, hiring),
  leadManagerDetail: persistReducer(leadManagerDetailPersistConfig, leadManager),
  panel: persistReducer(panelPersistConfig, panel),
  ...rest,
});

const middlewares = applyMiddleware(thunk);

const devTools = process.env.stage !== 'prod' ? composeWithDevTools(middlewares) : middlewares;

export function initializeStore(initialState: any = {}) {
  return createStore(persistReducer(rootPersistConfig, rootReducer), initialState, devTools);
}

export type StoreType = ReturnType<typeof rootReducer>;
