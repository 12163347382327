export const SET_PAGE_VISITED = 'SET_PAGE_VISITED';
export const SET_NAME_LASTNAME = 'SET_NAME_LASTNAME';
export const SET_NAME_LASTNAME_VALID = 'SET_NAME_LASTNAME_VALID';
export const SET_MORE_INFO = 'SET_MORE_INFO';
export const SET_MORE_INFO_VALID = 'SET_MORE_INFO_VALID';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_EMAIL_VALID = 'SET_EMAIL_VALID';
export const SET_WORKING_INFO = 'SET_WORKING_INFO';
export const SET_WORKING_INFO_VALID = 'SET_WORKING_INFO_VALID';
export const SET_REFUND_INFO = 'SET_REFUND_INFO';
export const SET_REFUND_INFO_VALID = 'SET_REFUND_INFO_VALID';
export const SET_PLAY_RISKY_ACTIVITIES = 'SET_HAS_RISKY_ACTIVITIES';
export const SET_RISKY_ACTIVITIES = 'SET_RISKY_ACTIVITIES';
export const SET_RISKY_ACTIVITIES_VALID = 'SET_RISKY_ACTIVITIES_VALID';
export const SET_RISKY_ACTIVITIES_ENTRY_VALID = 'SET_RISKY_ACTIVITIES_ENTRY_VALID';
export const SET_HAS_MEDICAL_CONDITIONS = 'SET_HAS_MEDICAL_CONDITIONS';
export const SET_MEDICAL_CONDITIONS = 'SET_MEDICAL_CONDITIONS';
export const SET_MEDICAL_CONDITIONS_VALID = 'SET_MEDICAL_CONDITIONS_VALID';
export const SET_MEDICAL_CONDITIONS_ENTRY_VALID = 'SET_MEDICAL_CONDITIONS_ENTRY_VALID';
export const SET_HAS_FAMILY_GROUP = 'SET_HAS_FAMILY_GROUP';
export const SET_FAMILY_GROUP = 'SET_FAMILY_GROUP';
export const SET_FAMILY_GROUP_VALID = 'SET_FAMILY_GROUP_VALID';
export const SET_FAMILY_GROUP_ENTRY_VALID = 'SET_FAMILY_GROUP_ENTRY_VALID';
export const SET_HAS_FAMILY_GROUP_MEDICAL_CONDITIONS = 'SET_HAS_FAMILY_GROUP_MEDICAL_CONDITIONS';
export const SET_FAMILY_GROUP_MEDICAL_CONDITIONS_VALID =
  'SET_FAMILY_GROUP_MEDICAL_CONDITIONS_VALID';
export const SET_FAMILY_GROUP_MEDICAL_CONDITIONS_ENTRY_VALID =
  'SET_FAMILY_GROUP_MEDICAL_CONDITIONS_ENTRY_VALID';
export const SET_HAS_BENEFICIARIES = 'SET_HAS_BENEFICIARIES';
export const SET_LIFE_INSURANCE_BENEFICIARIES = 'SET_LIFE_INSURANCE_BENEFICIARIES';
export const SET_LIFE_INSURANCE_BENEFICIARIES_VALID = 'SET_LIFE_INSURANCE_BENEFICIARIES_VALID';
export const SET_DOCUMENT_GENERATED = 'SET_DOCUMENT_GENERATED';
export const RESET_INCORPORATION = 'RESET_INCORPORATION';
export const SET_INCORPORATION_SENDED = 'SET_INCORPORATION_SENDED';
export const SET_LEAD_INFO = 'SET_LEAD_INFO';
export const SET_SUMMARY_WATCHED = 'SET_SUMMARY_WATCHED';
export const SET_STATUS = 'SET_STATUS';
export const SET_ID = 'SET_ID';
export const SET_ID_LEAD = 'SET_ID_LEAD';
