import { gql } from '@apollo/client';
import planFragment from '../fragments/planFragment';

export default gql`
  query listPlans {
    listPlans {
      ...planFragment
    }
  }
  ${planFragment}
`;
