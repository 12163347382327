import { incorporationStatus } from '../../utils/constants';
import {
  SET_INCORPORATION_PROGRESS,
  RESET_INCORPORATION_PROGRESS,
  SET_FORMS_IN_PROGRESS,
  RESET_FORMS_IN_PROGRESS,
  SET_FORMS_TO_SIGN,
  RESET_FORMS_TO_SIGN,
  SET_FORMS_SIGNED,
  RESET_FORMS_SIGNED,
  SET_EMPTY_PROGRESS,
  RESET_EMPTY_PROGRESS,
  SET_FORMS_BY_STATUS,
  RESET_FORMS_BY_STATUS,
  SET_LAST_REFRESH,
} from './actionTypes';

const initialState = {
  incorporationProgress: 0,
  formsInProgress: [],
  formsToSign: [],
  formsSigned: [],
  workersProgress: [],
  emptyProgress: true,
};

const reducer = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_INCORPORATION_PROGRESS:
      return {
        ...state,
        incorporationProgress: Number(payload),
      };

    case RESET_INCORPORATION_PROGRESS:
      return {
        ...state,
        incorporationProgress: initialState.incorporationProgress,
      };

    case SET_FORMS_IN_PROGRESS:
      return {
        ...state,
        formsInProgress: payload,
      };

    case RESET_FORMS_IN_PROGRESS:
      return {
        ...state,
        formsInProgress: initialState.formsInProgress,
      };

    case SET_FORMS_TO_SIGN:
      return {
        ...state,
        formsToSign: payload,
      };

    case RESET_FORMS_TO_SIGN:
      return {
        ...state,
        formsToSign: initialState.formsToSign,
      };

    case SET_FORMS_SIGNED:
      return {
        ...state,
        formsSigned: payload,
      };

    case RESET_FORMS_SIGNED:
      return {
        ...state,
        formsSigned: initialState.formsSigned,
      };

    case SET_EMPTY_PROGRESS:
      return {
        ...state,
        emptyProgress: payload,
      };

    case RESET_EMPTY_PROGRESS:
      return {
        ...state,
        emptyProgress: true,
      };

    case SET_FORMS_BY_STATUS:
      return {
        ...state,
        formsInProgress: payload[incorporationStatus.IN_PROCESS],
        formsToSign: payload[incorporationStatus.SENT],
        formsSigned: payload[incorporationStatus.SIGNED],
      };

    case RESET_FORMS_BY_STATUS:
      return {
        ...state,
        formsInProgress: initialState.formsInProgress,
        formsToSign: initialState.formsToSign,
        formsSigned: initialState.formsSigned,
      };

    case SET_LAST_REFRESH:
      return {
        ...state,
        lastRefresh: payload,
      };

    default:
      return state;
  }
};

export default reducer;
