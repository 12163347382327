import { gql } from '@apollo/client';
import ufFragment from '../fragments/ufFragment';

export default gql`
  query getUFToday {
    getUFToday {
      ...ufFragment
    }
  }
  ${ufFragment}
`;
