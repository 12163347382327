import { gql } from '@apollo/client';

import featureFlagFragment from '../fragments/featureFlagFragment';

export default gql`
  query getFeatureFlag($name: String!) {
    getFeatureFlag(name: $name) {
      ...FeatureFlagFragment
    }
  }
  ${featureFlagFragment}
`;
