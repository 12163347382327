const componentsId = {
  landingHeaderSection: 'landing_header_section',
  landingHeroSection: 'landing_hero_section',
  landingHeroBtnEstimate: 'landing_hero_btn_estimate',
  landingHiringFloatingBox: 'landing_hire_floating_box',
  landingHeroBtnPrices: 'landing_hero_btn_precios',
  landingHeroBtnPricesFloat: 'landing_hero_btn_precios_flotante',
  landingInsuranceSection: 'landing_insurance_section',
  landingInsuranceCard: 'landing_insurance_card_',
  landingInsuranceCarousel: 'landing_insurance_carousel',
  landingQuoteSection: 'landing_quote_section',
  landingQuestionsSection: 'landing_questions_section',
  landingQuestionsCard: 'landing_questions_card_',
  landingQuestionsBtn: 'landing_questions_btn',
  landingEstimateStepsSection: 'lading_estimate_steps_section',
  landingEstimateStepsBtnCotizar: 'landing_estimate_steps_btn_cotizar',
  landingBenefitsSection: 'lading_benefits_section',
  landingBenefitsCard: 'landing_benefits_card_',
  landingBenefitsCarousel: 'landing_benetifs_carousel',
  landingBenefitsLink: 'landing_benefits_link',
  landingBenefitsMoreBtn: 'landing_benefits_more_btn',
  landingBannerSection: 'landing_banner_section',
  landingBannerCardInfo: 'landing_banner_card_info',
  landingBannerBtnCotizar: 'landing_banner_btn_cotizar',
  landingAvailablePlansSection: 'landing_available_plans_section',
  landingAvailablePlansCard: 'landing_available_plans_card_',
  landingAvailablePlansCardBtn: 'landing_available_plans_card_btn_',
  landingAvailablePlansCarousel: 'landing_available_plans_carousel',
  landingAvailablePlansBtnViewCoverages: 'landing_available_plans_btn_view_coverages',
  landingAvailablePlansBtnCotizar: 'landing_available_plans_btn_cotizar',
  landingAvailablePlansToggle: 'landing_available_plans_toggle',
  landingAvailablePlansTabs: 'landing_available_plans_tabs',
  fallbackAvailablePlansImg: 'landing_available_plans_img',
  fallbackAvailablePlansImgMobile: 'landing_available_plans_img_mobile',
  landingHeroBtnScroll: 'landing_hero_btn_scroll',

  formHireBtnBack: 'form_hire_btn_back',
  formHireInputName: 'form_hire_input_name',
  formHireInputEmail: 'form_hire_input_email',
  formHireInputTelephone: 'form_hire_input_telephone',
  formHireInputPosition: 'form_hire_input_position',
  formHireBtnSubmit: 'form_hire_btn_submit',
  formHirePlaceholderPhone: 'form_hire_placeholder_phone',

  modalSuccessHire: 'modal_success_hire',
  modalSuccessHireBtn: 'modal_success_hire_btn',
  modalErrorHire: 'modal_error_hire',
  modalErrorHireBtn: 'modal_error_hire_btn',

  wizardStep: (currentStep: string) => `wizard_step_${currentStep}`,
  personalInfoStep: {
    btnBack: 'step_personal_info_btn_back',
    btnNext: 'step_personal_info_btn_next',
  },
  emailStep: {
    btnBack: 'step_email_btn_back',
    btnNext: 'step_email_btn_next',
  },
  workersStep: {
    input: 'step_workers_input',
    lessWorkersIcon: 'step_workers_less_workers_icon',
    lessWorkersArrowForwardIcon: 'less_workers_arrow_forward_icon',
    btnBack: 'step_workers_btn_back',
    btnNext: 'step_workers_btn_next',
  },
  plansStep: {
    cardId: 'step_plans_available_plans_card_',
    cards: 'step_plans',
    cardsBtn: 'step_plans_card_btn_',
    btnBack: 'step_plans_btn_back',
    btnNext: 'step_plans_btn_next',
    toggle: 'step_plans_toggle',
    tab: 'setp_plans_tab_types',
    carousel: 'step_plans_available_plans_carousel',
  },
  coveragesStep: {
    btnBack: 'step_coverages_btn_back',
    cardListId: 'step_coverage_card_list',
    btnActionId: (key: string) => `step_coverage_${key}_btn_action`,
    cardId: (key: string) => `coverage_step_card_${key}`,
    btnNext: 'step_coverages_btn_next',
  },
  variantsStep: {
    btnBack: 'step_variant_btn_back',
    btnNext: 'step_variant_btn_next',
    btnInformationModal: 'step_variant_btn_information_modal',
    informationModal: 'step_variant_modal_information',
    informationModalBtn: 'step_variant_modal_information_btn',
    distributionWithoutCharges: 'step_variant_card_without-charges',
    distrubutionWithCharges: 'step_variant_card_with-charges',
    distributionBtn: 'step_variant_card_distribution_btn',
    distributionVariant: 'step_variant_card_distribution',
    distributionModal: 'step_variant_modal_distribution',
    distributionModalToggle: 'step_variant_modal_distribution_toggle',
    distributionModalInput: (key: number) => `variant_ste_modal_input_${key}`,
    distributionModalBtn: 'step_variant_modal_distribution_btn',
    distributionCardlBtn: 'step_variant_card_distribution_btn',
    cardId: (key: string) => `step_variant_card_${key}`,
  },
  summaryStep: {
    btnBack: 'step_summary_btn_back',
    btnNext: 'step_summary_btn_next',
    planCard: 'step_summary_plan_card',
    pricesCard: 'step_summary_price_card',
    btnHealth: 'step_summary_btn_health',
    btnLife: 'step_summary_btn_life',
    btnDental: 'step_summary_btn_dental',
    btnCoverages: 'step_summary_btn_coverages',
    btnHiring: 'step_summary_btn_hiring',
    btnModalDistribution: 'step_summary_btn_modal_distribution',
    btnImprovePlan: 'step_summary_btn_improve_plan',
  },
  formStep: {
    btnBack: 'form_hire_btn_back',
    btnNext: 'form_hire_btn_submit',
    inputCompanyName: 'companyName',
    inputPosition: 'position',
    inputCompanyRut: 'companyRut',
    inputPhone: 'phone',
    codeRefer: 'referCode',
    assistCode: 'assistCode',
    btnReferCode: 'form_hire_refer_code_btn',
  },
  wizardResumeCapsule: 'wizard_review_capsule',

  recoverhiring: {
    form: {
      id: 'recover_hiring_form',
      btnBack: 'recover_hiring_form_btn_back',
      btnNext: 'recover_hiring_form_btn_next',
    },
    pin: {
      id: 'recover_hiring_pin',
      inputPin: 'recover_hiring_pin_input',
      btnResend: 'recover_hiring_pin_btn_resend',
      btnBack: 'recover_hiring_pin_btn_back',
      btnNext: 'recover_hiring_pin_btn_next',
    },
  },

  plansRetryBtn: 'plans_retry_btn',

  plansDetailsModal: 'available_plans_coverage_details_modal',

  modalIndividualInsurance: {
    modal: 'modal_individual_insurance',
    btn: 'modal_individual_insurance_btn_close',
  },
  benefitsBiceVidaCard: 'benefits_bicevida_card_',
  benefitsBiceBivaBtn: 'benefits_bicevida_btn',
  benefitsListLink: 'summary_benefits_link_more',
  summaryBannerCardInfo: 'summary_banner_card_info',
  summaryBannerBtn: 'summary_banner_btn_landing',

  riskyActivitiesStep: {
    riskyActivities: 'risky_activities_step',
    riskyActivitiesTrue: 'risky_activities_card_true',
    riskyActivitiesFalse: 'risky_activities_card_false',
    riskyActivitiesList: 'risky_activities_entry_step',
    linkMoreInfoMobile: 'risky_activities_link',
    infoModal: {
      modal: 'risky_activities_modal',
      button: 'risky_activities_modal_btn',
    },
    modal: {
      add: 'risky_activities_add_modal',
      edit: 'risky_activities_edit_modal',
      button: 'risky_activities_save_btn_modal',
    },
    remove: 'risky_activities_remove_modal',
    removeBtn: 'risky_activities_remove_btn_modal',
    cancelBtn: 'risky_activities_cancel_btn_modal',
    addBtn: 'risky_activities_new_btn',
  },
  personalInfoHealthSystemSelect: 'personal_info-health_system',
  jobInfoMonthSelect: 'job_info-month',
  jobInfoYearSelect: 'job_info-year',

  selectBank: 'bank',
  selectTypeAccount: 'typeAccount',
  inputAccount: 'account',
  checkboxRefund: 'checkbox_refund',

  medicalInfoStep: {
    medicalInfo: 'medical_info_step',
    medicalInfoTrue: 'medical_info_card_true',
    medicalInfoFalse: 'medical_info_card_false',
    medicalInfoList: 'medical_info_entry_step',
    linkMoreInfoMobile: 'medical_info_link',
    infoModal: {
      modal: 'medical_info_modal',
      button: 'medical_info_modal',
    },
    modal: {
      add: 'medical_info_add_modal',
      edit: 'medical_info_edit_modal',
      remove: 'medical_info_remove_modal',
      button: 'medical_info_save_btn_modal',
      inputDisease: 'medical_info_disease_input',
      selectMonth: 'medical_info_month_select',
      selectYear: 'medical_info_year_select',
    },
    remove: 'medical_info_remove_modal',
    removeBtn: 'medical_info_remove_btn_modal',
    cancelBtn: 'medical_info_cancel_btn_modal',
    editBtn: 'medical_info_edit_btn_modal',
    addBtn: 'medical_info_new_btn',
  },

  familyGroupStep: {
    familyGroup: 'family_group_step',
    familyGroupTrue: 'family_group_card_true',
    familyGroupFalse: 'family_group_card_false',
    familyGroupList: 'family_group_entry_step',
    linkMoreInfoMobile: 'family_group_link',
    infoModal: {
      modal: 'family_group_modal',
      button: 'family_group_modal',
    },
    modal: {
      add: 'family_group_add_modal',
      edit: 'family_group_edit_modal',
      button: 'family_group_continue_btn_modal',
      buttonStepTwo: 'family_group_save_btn_modal',
    },
    remove: 'family_group_remove_modal',
    removeBtn: 'family_group_remove_btn_modal',
    cancelBtn: 'family_group_cancel_btn_modal',
    addBtn: 'family_group_new_btn',
    relationshipSelect: 'family_group_modal_relationship_select',
    healthSystemSelect: 'family_group_modal_healthSystem_select',
  },
  familyMedicalInfoStep: {
    familyMedicalInfo: 'family_medical_info_step',
    familyMedicalInfoTrue: 'family_medical_info_card_true',
    familyMedicalInfoFalse: 'family_medical_info_card_false',
    familyMedicalInfoList: 'family_medical_info_entry_step',
    linkMoreInfoMobile: 'family_medical_info_link',
    infoModal: 'family_medical_info_modal',
    modal: {
      add: 'family_medical_info_add_modal',
      edit: 'family_medical_info_edit_modal',
      disease: 'disease',
      month: 'month',
      year: 'year',
      diagnosisDate: 'diagnosisDate',
      buttonNext: 'family_medical_info_save_next_modal',
      buttonSave: 'family_medical_info_save_save_modal',
    },
    remove: 'family_medical_info_remove_modal',
    removeBtn: 'family_medical_info_remove_btn_modal',
    cancelBtn: 'family_medical_info_cancel_btn_modal',
  },

  beneficiariesStep: {
    beneficiaries: 'beneficiaries_step',
    beneficiariesTrue: 'beneficiaries_card_true',
    beneficiariesFalse: 'beneficiaries_card_false',
    beneficiariesList: 'beneficiaries_entry_step',
    linkMoreInfoMobile: 'beneficiaries_link',
    infoModal: {
      modal: 'beneficiaries_modal',
      button: 'beneficiaries_modal',
    },
    modal: {
      add: 'beneficiaries_add_modal',
      edit: 'beneficiaries_edit_modal',
      button: 'beneficiaries_continue_btn_modal',
      buttonStepTwo: 'beneficiaries_save_btn_modal',
    },
    remove: 'beneficiaries_remove_modal',
    removeBtn: 'beneficiaries_remove_btn_modal',
    cancelBtn: 'beneficiaries_cancel_btn_modal',
    addBtn: 'beneficiaries_new_btn',
    relationshipSelect: 'beneficiaries_modal_relationship_select',
    healthSystemSelect: 'beneficiaries_modal_healthSystem_select',
  },
  summaryPdf: {
    preModal: 'incorporation_confirm_modal',
    preModalBtn: 'incorporation_confirm_modal_btn',
    captchaModalBackButton: 'incorporation_captcha_back_button',
    captchaModalConfirmButton: 'incorporation_captcha_confirm_button',
  },
  downloadPdf: {
    btnDownloadPdf: 'incorporation_download_pdf_btn',
    btnRetryPdf: 'incorporation_retry_pdf_btn',
    containerDownloadPdf: 'incorporation_download_pdf_container',
  },
  incorporationMenu: {
    iconMenu: 'incorporation_menu_icon',
    tooltipBtn: 'incorporation_menu_tooltip_btn',
    asideIcon: 'incorporation_menu_aside_icon',
    asideSection: (section: string) => `incorporation_menu_aside_section_${section}`,
    asideBtnClose: 'incorporation_menu_aside_btn_close',
  },

  startHiringBtn: 'start_hiring_btn',
  startHiringRequotes: 'start_hiring_requotes_btn',
  startHiringCheckbox: 'start_hiring_checkbox',

  hiring: {
    activityText: 'activity',
    phoneText: 'phone',
    addressText: 'address',
    regionSelect: 'hiring_region_select',
    districtSelect: 'hiring_district_select',
    streetNumber: 'streetNumber',
    addressApartment: 'addressApartment',
    hiringNextBtn: 'hiring_btn',
    hiringBackBtn: 'hiring_back_btn',
    dashboard: 'hiring_dashboard',
    hiring: 'hiring_form',
    hiringCompanyCard: 'hiring_card',
    companies: {
      companies: 'companies_step',
      companiesTrue: 'companies_card_true',
      companiesFalse: 'companies_card_false',
      modalPrimaryInfo: {
        add: 'companies_add_primary_info_modal',
        edit: 'companies_edit_primary_info_modal',
        button: 'companies_next_btn_modal',
      },
      modalAdditionalInfo: {
        add: 'companies_add_additional_info_modal',
        edit: 'companies_edit_additional_info_modal',
        button: 'companies_save_btn_modal',
      },
      remove: 'companies_remove_modal',
      removeBtn: 'companies_remove_btn_modal',
      cancelBtn: 'companies_cancel_btn_modal',
      addBtn: 'companies_new_btn',
    },
    addressSection: {
      container: 'hiring_company_address_section',
    },
    selectAdminSection: {
      container: 'hiring_select_admin_section',
    },
    infoAdminSection: {
      container: 'hiring_info_admin_section',
    },
    companiesSection: {
      container: 'hiring_companies_section',
    },
    panelSection: {
      container: 'hiring_panel_section',
      completeFormBtn: 'hiring_panel_complete_form',
      shareFormBtn: 'hiring_panel_share_form',
      shareModalContainer: 'hiring_modal_share_form_container',
      shareModalBtn: 'hiring_modal_share_email_btn',
      shareModalOkBtn: 'hiring_modal_share_btn',
      copyModalBtn: 'hiring_modal_copy_btn',
      incorporationSection: 'hiring_incorporation_section',
      progressSection: 'hiring_progress_section',
      restoreBtn: 'hiring_progress_restore_btn',
      downloadPdfBtn: 'hiring_progress_download_pdf_btn',
      moreOptionsBtn: 'hiring_progress_more_options_btn',
      hiredSection: 'hired_incorporation_section',
    },
    finalConfirmation: {
      container: 'hiring_final_confirmation_section',
    },
  },

  incorporation: {
    onboardingSection: {
      container: 'incorporation_onboarding_container',
      nextBtn: 'incorporation_onboarding_next_btn',
      emptyState: 'incorporation_onboarding_empty_state',
    },
    namesSection: {
      container: 'incorporation_names_section',
    },
    personalDataSection: {
      container: 'incorporation_personal_data_section',
    },
    emailSection: {
      container: 'incorporation_email_section',
    },
    companyInfo: {
      container: 'incorporation_company_data_section',
    },
    refoundSection: {
      container: 'incorporation_refound_section',
    },
    riskSection: {
      container: 'incorporation_risk_section',
      ingresoContainer: 'incorporation_input_risk_section',
      addBtn: 'incorporation_add_more_risk_btn',
    },
    medicalSection: {
      container: 'incorporation_preexisting_medical',
      ingresoContainer: 'incorporation_preexisting_input_section',
      addBtn: 'incorporation_add_more_preexisting_btn',
    },
    medicalFamilySection: {
      container: 'incorporation_preexisting_family_medical_section',
      addFamilyContainer: 'incorporation_preexisting_add_family_section',
      addBtn: 'incorporation_add_more_family_btn',
      addFamilyDeceaseContainer: 'incorporation_preexisting_add_family_decease_section',
      addFamilyDeceaseInputContainer: 'incorporation_preexisting_add_family_decease_input_section',
    },

    beneficiarySection: {
      container: 'incorporation_beneficiary_section',
      addBtn: 'incorporation_beneficiary_btn',
    },
    summarySection: {
      container: 'incorporation_summary_section',
    },
  },

  inputCopyLink: 'input_copy_link',

  plansComparation: {
    actionBtn: 'plans_comparation_action_btn',
    modal: 'plans_comparation_modal',
    modalSelectionBtn: 'plans_comparation_modal_selection_btn',
    modalSelectionCard: (type: string, id: number) => `plans_comparation_modal_card_${type}_${id}`,
    modalSelection: 'plans_comparation_modal_selection',
    modalCompare: 'plans_comparation_modal_compare',
    modalCompareSelectPlan: (index: number) => `plans_comparation_modal_compare_plan_${index}`,
  },

  reasonSocialSelect: 'multi_reason_name_rut',

  helpDesk: {
    home: {
      categoryCard: (id: string) => `help_desk_home_category_card_${id}`,
      bannerBtn: 'help_desk_home_banner_btn',
    },
  },

  userQuestionFeedback: {
    reasonSelection: 'no_useful_question_select',
  },

  panelIncorporation: {
    list: {
      downloadAllBtn: 'panel_incorporation_list_download_all_btn',
      refreshBtn: 'panel_incorporation_list_refresh_btn',
    },
  },

  improvePlanModal: {
    modalContainer: 'improve_plan_modal_container',
    cancelBtn: 'improve_plan_modal_back_btn',
    improveBtn: 'improve_plan_modal_action_btn',
  },

  improvingPlanModal: {
    modalContainer: 'improving_plan_modal_container',
    backBtn: 'improving_plan_modal_back_btn',
  },

  login: {
    container: 'panel_login_container',
    button: 'panel_login_btn',
  },

  leadManager: {
    container: 'lead_manager_container',
    leadsTab: 'lead_manager_leads_tab',
    incorporationsTab: 'lead_manager_incorporations_tab',
    downloadCSVBtn: 'lead_manager_btn_download',
  },

  leadManagerDetail: {
    estimateBtn: 'lead_manager_detail_btn_estimate',
  },

  leadManagerEstimate: {
    container: 'lead_estimate_container',
    leadsTab: 'lead_estimate_leads_tab',
    downloadCSVBtn: 'lead_estimate_btn_download',
    modalError: 'lead_estimate_modal_error',
  },

  benefits: {
    pharmacy: {
      section: 'benefits_pharmacy_section',
    },
    health: {
      section: 'benefits_health_section',
      card: 'benefits_health_section_card',
      button: 'benefits_health_section_button',
    },
    pyme: {
      section: 'benefits_pyme_section',
      estimateButton: 'benefits_pyme_section_estimate_button',
    },
    telehealth: {
      section: 'benefits_telehealth_section',
    },
    pymeCard: {
      section: 'benefits_pyme_section',
      dentalButton: 'benefits_pyme_section_dental_button',
      circleButton: 'benefits_pyme_section_circle_button',
    },
    coverage: {
      section: 'benefits_coverage_section',
      button: 'benefits_coverage_section_button',
      cardButton: 'benefits_coverage_section_card_button',
    },
    circle: {
      section: 'benefits_circle_section',
    },
    refund: {
      section: 'benefits_refund_section',
      carousel: 'benefits_refund_section_carousel',
    },
    features: {
      section: 'benefits_features_section',
      carousel: 'benefits_features_section_carousel',
    },
    banner: {
      section: 'benefits_banner_section',
      card: 'benefits_card_banner',
      buttonEstimate: 'benefits_banner_estimate_button',
    },
  },

  landing: {
    hero: {
      section: 'landing_hero_section',
      button: 'landing_hero_button',
      imageList: 'landing_hero_images_worker_',
      infoList: 'landing_hero_info_list_',
    },
    features: {
      section: 'landing_features_section',
    },
    coverages: {
      section: 'landing_coverages_section',
      mobileButton: 'landing_coverages_button_mobile',
      deskButton: 'landing_coverages_button_desktop',
      cardList: 'landing_coverages_card_',
      cardButton: 'landing_coverages_card_button_',
    },
    availablePlans: {
      section: 'landing_coverages_section',
      cardList: 'landing_available_plans_card_',
      buttonDetail: 'landing_available_button_details',
      buttonNow: 'landing_available_button_quotation',
    },
    benefits: {
      section: 'landing_benefits_section',
      cardList: 'landing_benefits_card_',
      button: 'landing_benefits_button',
      carousel: 'landing_benefits_carousel',
    },
    circle: {
      section: 'landing_circle_section',
      button: 'landing_circle_button',
    },
    pawer: {
      section: 'landing_pawer_section',
      button: 'landing_pawer_button',
    },
    quotation: {
      section: 'landing_quotation_section',
      list: 'landing_quotation_list_',
      button: 'landing_quotation_button_list_',
      modal: 'landing_quotation_modal_requirements',
    },
    requirements: {
      section: 'landing_requirements_to_contract',
    },
    whyChoose: {
      section: 'landing_why_choose_section',
    },
    banner: {
      section: 'landing_banner_section',
    },
  },

  coverages: {
    main: 'coverages_main',
    hero: {
      section: 'coverages_hero_section',
      button: 'coverages_hero_button',
    },
    accordionsSection: 'coverages_accordion_section',
    accordion: {
      howItWorks: 'coverages_accordion_how_it_works_',
      whatItCovers: 'coverages_accordion_what_it_covers_',
      whatItCoversBtn: 'coverages_accordion_what_it_covers_btn_',
      whatItDoesNotCover: 'coverages_accordion_what_it_does_not_cover_',
      whatItDoesNotCoverPdf: 'coverages_accordion_what_it_does_not_cover_pdf_',
    },
    bannerPrimary: {
      card: 'coverages_cat_card_banner',
      buttonEstimate: 'coverages_cat_banner_estimate_button',
    },
    bannerSecondary: {
      card: 'coverages_prices_and_plans_banner',
      button: 'coverages_prices_and_plans_banner_button',
    },
    bannerPawer: {
      card: 'coverages_pawer_banner',
    },
    modal: 'coverages_modal',
  },
  holdingAuthorizationModal: 'holding_authorization_modal',
  holdingAuthorizationCheckbox: 'holding_authorization_checkbox',

  pricesAndPlans: {
    hero: {
      section: 'prices_and_plans_hero_section',
      button: 'prices_and_plans_hero_button',
    },
    types: {
      section: 'prices_and_plans_types_section',
      toggle: 'prices_and_plans_types_toggle',
      planCard: 'prices_and_plans_types_plan_card_',
      planCardDetailBtn: 'prices_and_plans_types_plan_card_detail_btn_',
      additionalCoverage: 'prices_and_plans_types_additional_coverage_',
      additionalCoverageBtn: 'prices_and_plans_types_additional_coverage_btn_',
    },
    banner: {
      section: 'prices_and_plans_banner_section',
      button: 'prices_and_plans_banner_button',
    },
    documents: {
      section: 'prices_and_plans_documents_section',
      download: 'prices_and_plans_documents_download_',
    },
  },
  wizardSingleStep: {
    section: 'callcenter_wizard_single_step_section',
    btn_confirm: 'callcenter_wizard_btn_confirm',
    modal: {
      section: 'callcenter_wizard_single_step_modal',
      btn_cancel: 'callcenter_wizard_modal_btn_cancel',
      btn_confirm: 'callcenter_wizard_modal_btn_confirm',
    },
    variants: {
      card: 'callcenter_wizard_single_step_variant_card_',
    },
    summary: {
      section: 'callcenter_wizard_single_step_summary_section',
      btn_back_quote: 'callcenter_wizard_single_step_summary_btn_back_quote',
      btn_next_hire: 'callcenter_wizard_single_step_summary_btn_next_hire',
      btn_open_modal: 'callcenter_wizard_single_step_summary_btn_open_modal',
    },
  },
  modalInformationFormId: {
    modalForm: 'modal_information_form_modal',
    buttonFinish: 'modal_information_form_button_finish',
    buttonGenerate: 'modal_information_form_button_generate',
    badgeCoverageRange: 'modal_information_form_coverage_range',
    initialDate: 'modal_information_form_initial_date',
    datePickerAcceptationDate: 'modal_information_form_date_picker_acceptation_date',
    acceptationDate: 'modal_information_form_acceptation_date',
    datePickerReceptionDate: 'modal_information_form_date_picker_reception_date',
    receptionDate: 'modal_information_form_reception_date',
    checkModal: 'modal_information_form_check_decease',
  },
  activitySelectionFormId: {
    inputs: {
      name: 'name_activity_selection_input',
      activity: 'activity_selection_input_textarea',
    },
    activitySelectionModal: 'activity_selection_modal',
    showActivitiesButton: 'activity_selection_show_activities_button',
    confirmModalButton: 'activity_selection_modal_confirm_button',
    findSiiDataModal: 'find_sii_data_modal',
    confirmFindSiiDataModalButton: 'find_sii_data_modal_confirm_button',
    cancelFindSiiModalButton: 'find_sii_data_modal_cancel_button',
    understandFindSiiModalButton: 'find_sii_data_modal_understand_button',
  },

  successPage: {
    quoteTab: {
      container: 'success_page_quote_section',
      hireBtn: 'success_page_hire_button',
      reQuoteBtn: 'success_page_estimate_button',
      moreCoveragesBtn: 'success_page_see_coverages_button',
    },
    stepsTab: {
      container: 'success_page_steps_section',
      reQuoteBtn: '_quote_btn',
    },
    hireTab: {
      container: 'success_page_hire_section',
      goToIncorporationsBtn: 'hire_section_go_to_incorporations',
    },
    reQuoteTab: {
      container: 'success_page_estimate_tab',
      reQuoteBtn: '_quote_btn',
      backBtn: '_quote_back_btn',
    },
    companyTab: {
      container: 'success_page_company_tab',
      editCompany: 'company_section_edit_company_btn',
      editAdmin: 'company_section_edit_admin_btn',
      goToStepsBtn: 'go_to_steps_btn',
      setAdminDataBtn: 'set_admin_data',
      setCompanyDataBtn: 'set_company_data',
    },

    incorporationTab: {
      noProgressBtn: 'success_page_go_to_steps_section',
      seeAllSignedWorkers: 'see_signed_workers',
    },

    hireBtnTab: 'hire_btn_tab',
    tabs: 'success_page_tabs',
  },

  backPanel: {
    estimateContainer: 'back_panel_estimate',
  },

  successSignaturePage: {
    btnCards: (index: number) => `card_success_btn_${index}`,
  },

  modalCompanyInfoHiring: {
    inputs: {
      companyRut: 'modal_company_hiring_input_rut',
      companyName: 'modal_company_hiring_input_name',
    },
    button: 'modal_company_hiring_button_confirm',
  },

  modalErrorToken: {
    modal: 'modal_error_token',
    button: 'modal_error_token_btn',
  },
};

export default componentsId;
