import { incorporationStatus } from '../../utils/constants';
import {
  SET_NAME_LASTNAME,
  SET_MORE_INFO,
  SET_EMAIL,
  SET_WORKING_INFO,
  SET_REFUND_INFO,
  SET_PLAY_RISKY_ACTIVITIES,
  SET_RISKY_ACTIVITIES,
  SET_HAS_MEDICAL_CONDITIONS,
  SET_MEDICAL_CONDITIONS,
  SET_HAS_FAMILY_GROUP,
  SET_FAMILY_GROUP,
  SET_FAMILY_GROUP_VALID,
  SET_HAS_FAMILY_GROUP_MEDICAL_CONDITIONS,
  SET_LIFE_INSURANCE_BENEFICIARIES,
  SET_DOCUMENT_GENERATED,
  RESET_INCORPORATION,
  SET_NAME_LASTNAME_VALID,
  SET_MORE_INFO_VALID,
  SET_EMAIL_VALID,
  SET_WORKING_INFO_VALID,
  SET_REFUND_INFO_VALID,
  SET_RISKY_ACTIVITIES_VALID,
  SET_RISKY_ACTIVITIES_ENTRY_VALID,
  SET_MEDICAL_CONDITIONS_VALID,
  SET_MEDICAL_CONDITIONS_ENTRY_VALID,
  SET_FAMILY_GROUP_ENTRY_VALID,
  SET_FAMILY_GROUP_MEDICAL_CONDITIONS_VALID,
  SET_FAMILY_GROUP_MEDICAL_CONDITIONS_ENTRY_VALID,
  SET_HAS_BENEFICIARIES,
  SET_LIFE_INSURANCE_BENEFICIARIES_VALID,
  SET_INCORPORATION_SENDED,
  SET_PAGE_VISITED,
  SET_LEAD_INFO,
  SET_SUMMARY_WATCHED,
  SET_STATUS,
  SET_ID,
  SET_ID_LEAD,
} from './actionTypes';

const initialState: Incorporation = {
  _id: '',
  // SET_PAGE_VISITED
  isPageVisited: false,
  // SET_NAME_LASTNAME
  name: '',
  paternalLastname: '',
  maternalLastname: '',
  isNameLastnameValid: false,
  // SET_MORE_INFO
  rut: '',
  bornDate: '',
  gender: '',
  healthCareSystem: '',
  healthCareCode: 0,
  isMoreInfoValid: false,
  // SET_EMAIL
  email: '',
  isEmailValid: false,
  // SET_WORKING_INFO
  companyName: '',
  companyRut: '',
  hiringDate: { month: '', year: '' },
  isWorkingInfoValid: false,
  // SET_REFUND_INFO
  hasBankAccount: true,
  bank: '',
  bankCode: 0,
  accountType: '',
  accountCode: 0,
  accountNumber: '',
  isRefundInfoValid: false,
  // SET_PLAY_RISKY_ACTIVITIES
  playSportOrRiskyActivities: undefined,
  // SET_RISKY_ACTIVITIES
  sportOrRiskyActivities: [],
  isRiskyActivitiesValid: false,
  isRiskyActivitiesEntryValid: false,
  // SET_HAS_MEDICAL_CONDITIONS
  hasPreexistingMedicalCondition: undefined,
  // SET_MEDICAL_CONDITIONS
  preexistingMedicalCondition: [],
  isMedicalInfoValid: false,
  isMedicalInfoEntryValid: false,
  // SET_HAS_FAMILY_GROUP
  hasFamilyGroup: undefined,
  // SET_FAMILY_GROUP
  familyGroup: [],
  isFamilyGroupInfoValid: false,
  isFamilyGroupInfoEntryValid: false,
  // SET_HAS_FAMILY_GROUP_MEDICAL_CONDITIONS
  hasFamilyGroupWithPreexistingMedicalConditions: undefined,
  isFamilyGroupMedicalInfoValid: false,
  isFamilyGroupMedicalInfoEntryValid: false,
  // SET_LIFE_INSURANCE_BENEFICIARIES
  hasBeneficiaries: undefined,
  lifeInsuranceBeneficiaries: [],
  isLifeInsuranceBeneficiariesValid: false,
  // SET_SUMMARY_DOCUMENT
  summaryWatched: false,
  // SET_DOCUMENT_GENERATED
  documentGenerated: '',
  // SET_INCOPORATION_SENDED
  incorporationSended: false,
  // SET_LEAD_INFO
  leadCompanyEmail: '',
  leadCompanyRut: '',
  // SET_STATUS
  status: incorporationStatus.IN_PROCESS,
  id: '',

  // SET_ID_LEAD
  idLead: '',
};

const reducer = (state: Incorporation = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_PAGE_VISITED:
      return {
        ...state,
        isPageVisited: payload,
      };

    case SET_NAME_LASTNAME:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_NAME_LASTNAME_VALID:
      return {
        ...state,
        isNameLastnameValid: payload,
      };

    case SET_MORE_INFO:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_MORE_INFO_VALID:
      return {
        ...state,
        isMoreInfoValid: payload,
      };

    case SET_EMAIL:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_EMAIL_VALID:
      return {
        ...state,
        isEmailValid: payload,
      };

    case SET_WORKING_INFO:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_WORKING_INFO_VALID:
      return {
        ...state,
        isWorkingInfoValid: payload,
      };

    case SET_REFUND_INFO:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_REFUND_INFO_VALID:
      return {
        ...state,
        isRefundInfoValid: payload,
      };

    case SET_PLAY_RISKY_ACTIVITIES:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_RISKY_ACTIVITIES:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_RISKY_ACTIVITIES_VALID:
      return {
        ...state,
        isRiskyActivitiesValid: payload,
      };

    case SET_RISKY_ACTIVITIES_ENTRY_VALID:
      return {
        ...state,
        isRiskyActivitiesEntryValid: payload,
      };

    case SET_HAS_MEDICAL_CONDITIONS:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_MEDICAL_CONDITIONS:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_MEDICAL_CONDITIONS_VALID:
      return {
        ...state,
        isMedicalInfoValid: payload,
      };

    case SET_MEDICAL_CONDITIONS_ENTRY_VALID:
      return {
        ...state,
        isMedicalInfoEntryValid: payload,
      };

    case SET_HAS_FAMILY_GROUP:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_FAMILY_GROUP:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_FAMILY_GROUP_VALID:
      return {
        ...state,
        isFamilyGroupInfoValid: payload,
      };

    case SET_FAMILY_GROUP_ENTRY_VALID:
      return {
        ...state,
        isFamilyGroupInfoEntryValid: payload,
      };

    case SET_HAS_FAMILY_GROUP_MEDICAL_CONDITIONS:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_FAMILY_GROUP_MEDICAL_CONDITIONS_VALID:
      return {
        ...state,
        isFamilyGroupMedicalInfoValid: payload,
      };

    case SET_FAMILY_GROUP_MEDICAL_CONDITIONS_ENTRY_VALID:
      return {
        ...state,
        isFamilyGroupMedicalInfoEntryValid: payload,
      };

    case SET_HAS_BENEFICIARIES:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_LIFE_INSURANCE_BENEFICIARIES:
      return {
        ...state,
        ...payload,
        incorporationSended: false,
      };

    case SET_LIFE_INSURANCE_BENEFICIARIES_VALID:
      return {
        ...state,
        isLifeInsuranceBeneficiariesValid: payload,
      };

    case SET_DOCUMENT_GENERATED:
      return {
        ...state,
        documentGenerated: payload,
      };

    case SET_LEAD_INFO:
      return {
        ...state,
        ...payload,
      };

    case SET_INCORPORATION_SENDED:
      return {
        ...state,
        incorporationSended: true,
      };

    case SET_SUMMARY_WATCHED:
      return {
        ...state,
        summaryWatched: payload,
      };

    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };

    case RESET_INCORPORATION:
      return {
        ...initialState,
      };

    case SET_ID:
      return {
        ...state,
        id: payload,
      };

    case SET_ID_LEAD:
      return {
        ...state,
        idLead: payload,
      };

    default:
      return state;
  }
};

export default reducer;
