import { FORMATERS } from '@BICE_Vida/scp-components';

import { addMonths, format } from 'date-fns';
import es from 'date-fns/locale/es';
import React from 'react';
import { conjuntion } from '@/utils/commonFunctions';
import { IntervalCyber, formatUF } from '@/utils/format';

import routes from './routes';
import {
  BENEFITS_IMAGES,
  BENEFIT_BV_MORE,
  BENEFIT_KINE,
  BENEFIT_MASK,
  BENEFIT_NUTRI,
  BENEFITS_HEALTH_SCAN,
  BENEFITS_HEALTH_DOCTOR,
  BENEFITS_HEALTH_TEST,
  BENEFITS_HEALTH_EYE,
  BENEFITS_HEALTH_MEDICINE,
  BENEFITS_HEALTH_PILL,
  BENEFITS_LOGO_HOSPITAL_ONE,
  BENEFITS_LOGO_HOSPITAL_TWO,
  BENEFITS_LOGO_HOSPITAL_THREE,
  BENEFITS_LOGO_HOSPITAL_FOUR,
  ESTIMATE_CONTACT,
  ESTIMATE_CUSTOMIZE,
  ESTIMATE_HIRE,
  ICON_EMAIL,
  ICON_PHONE,
  INSURANCE_CATASTROPHIC,
  INSURANCE_DENTAL,
  INSURANCE_HEALTH,
  INSURANCE_LIFE,
  RRSS,
  FEEDBACK_THANKS_MODAL,
  KEEP_IMPROVING_MODAL,
  INSURANCE_LIFE_PLUS_CIRCLE,
  INSURANCE_DENTAL_CIRCLE,
  INSURANCE_CATASTROPHIC_CIRCLE,
  IMG_COVERAGE_AMBULANCE,
  IMG_COVERAGE_DENTAL,
  IMG_COVERAGE_HEALTH,
  IMG_COVERAGE_HEART,
  ICON_CHECK,
  IMG_PRICES_AND_PLANS,
  ICON_DOWNLOAD,
  PDFS,
  PYME_DATA_ICON,
  PYME_FORM_ICON,
  PYME_PROGRESS_ICON,
  IMG_COVERAGE_PAWER,
  ICON_CHECK_CIRCLE,
  REQUERIMENTS_TO_CONTRACT,
  BENEFITS_CIRCLE,
  BENEFITS_PHARMACY_REFUND,
  BENEFITS_PHARMACY,
} from './assets';
import {
  minLimitWorkers,
  imgChooseInsurance,
  imgJubilolos,
  imgSaveMoney,
  coveragesIcon,
  whyChooseIcon,
  plansId,
  loadOptions,
  LeadStatusEnum,
  StepsEnum,
  ranges,
  benefitIcons,
  brandingCompanyHeroes,
  featuresIcon,
  bicevidaURLWindowIndividualInsurance,
  benefitsFeaturesIcon,
} from './constants';

import {
  formatIncorporationDate,
  formatStartHiringDate,
  getStartChargeDay,
  startChargeCurrentMonth,
  formatMoney,
} from './format';

export const heroSectionTexts = {
  title: 'Cotiza tu seguro colectivo de salud',
  description: () =>
    `Ahora puedes proteger desde ${minLimitWorkers} trabajadores <b>cotizando y contratando 100% en línea</b>.`,
  btnEstimate: 'Cotiza ahora',
  btnEstimateRecover: 'Retoma tu cotización',
};

export const headerSectionTexts = {
  btnEstimate: 'Cotiza ahora',
  btnEstimateRecover: 'Retoma tu cotización',
  btnRecoverHiring: 'Retoma tu contratación',
};

export const hiringFloatingBoxTexts = {
  title: 'Desde',
  subtitle: '5 trabajadores',
  buttonText: 'Cotiza online',
};

export const insuranceSectionTexts = {
  title: '¿Qué incluye este seguro?',
};

export const insuranceCardsInfoTexts = [
  {
    title: 'Seguro de Salud',
    text: 'Cobertura en consultas médicas, exámenes, medicamentos y atención hospitalaria.',
    icon: INSURANCE_HEALTH,
  },
  {
    title: 'Seguro de Vida',
    text: 'Capital de vida, en caso de fallecimiento del asegurado titular.',
    icon: INSURANCE_LIFE,
  },
  {
    title: 'Seguro Dental',
    text: 'Cobertura en más de 15 prestaciones de salud dental.',
    icon: INSURANCE_DENTAL,
  },
  {
    title: 'Seguro Catastrófico',
    text: 'Respaldo financiero adicional que se activa después de la cobertura del Seguro de Salud.',
    icon: INSURANCE_CATASTROPHIC,
  },
];

export const quoteSectionText = 'Protegemos a 210.000 trabajadores en más de 2.200 empresas';

export const benefitsSectionTexts = {
  title: 'Accede a nuestros beneficios exclusivos',
  description: `<ul>
    <li>Coberturas hospitalarias de hasta un 100%.</li>
    <li>Copago único en más de 20 clínicas.</li>
    <li>Descuentos en clínicas, farmacias, ópticas, atención dental y mucho más.</li>
  </ul>`,
  link: 'Revisa todos los beneficios',
};

export const benefitsPageTexts = {
  pharmacyTitle: 'Convenios y descuentos en medicamentos',
  pharmacySubtitle:
    'Tenemos convenios de reembolso directo en grandes farmacias y una plataforma en línea para comparar medicamentos y aprovechar los mejores precios.',
  pharmacyCards: [
    {
      title: 'Reembolso directo en farmacias Ahumada, Salco Brand y Cruz Verde',
      description:
        'Al indicar que se tiene un seguro BICE VIDA, la cobertura se aplica de forma directa al monto total de los medicamentos con receta médica. ',
      image: BENEFITS_PHARMACY_REFUND,
    },
    {
      title: 'Farmacia fácil',
      description:
        'Tenemos convenios con más de 80 farmacias a lo largo de todo Chile, donde podrás comprar medicamentos a precios convenientes.',
      image: BENEFITS_PHARMACY,
      btn: {
        text: 'Ver farmacias en convenio',
        url: PDFS.PHARMACY,
      },
    },
  ],
  pymeTitle: 'Beneficios y convenios para tu pyme',
  pymeDescription:
    'Pensamos en tus trabajadores en todo momento, desde su incorporación al seguro hasta cuando requieran un descuento.',
  btnEstimate: 'Cotiza ahora',
  digitalTitle: 'Incorporación 100% digital y sin trámites',
  digitalSubtitle: 'Incorporación de trabajadores',
  digitalDescription: `Diseñamos un <b>formulario de incorporación 100% digital</b> para que tú y tus trabajadores se incorporen al seguro fácilmente, simple y rápido. `,
  bannerTitle: (showMobile: boolean) =>
    showMobile
      ? '¿Te gustaría entregar estos beneficios a tu equipo?'
      : '¿Quieres darle estos beneficios a tu equipo?',
  bannerDescription: 'Cotiza y contrata 100% en línea, o con ayuda de una ejecutiva.',
  bannerBtn: 'Cotiza ahora',
  digitalList: [
    {
      icon: 'assignment_ind',
      description: 'Tus trabajadores completan sus datos personales e información médica',
    },
    {
      icon: 'person_add',
      description: 'Ingresan a sus cargas y beneficiarios ',
    },
    {
      icon: 'verified_user',
      description: 'Firman electrónicamente y listo, ya están incorporados!',
    },
  ],
  telehealth: {
    title: (
      <>
        <span>Telesalud 360:</span> atención médica gratis, inmediata e ilimitada para tus
        trabajadores
      </>
    ),
    list: [
      <>Consultas ilimitadas e inmediatas de medicina general.</>,
      <>
        Más de <strong>10 especialidades</strong> que tus trabajadores pueden agendar.
      </>,
      <>
        Sesiones de <strong>atención psicológica</strong> hasta 1 vez por semana.
      </>,
      <>
        Emisión de <strong>exámenes médicos preventivos</strong> sin costo.
      </>,
    ],
  },
  agreementTitle: 'Convenios en centros dentales',
  agreementSubtitle: 'Beneficios  dentales',
  agreementDescription:
    'Los asegurados pueden acceder a descuentos de hasta un 60% en diferentes centros y clínicas dentales en convenio.',
  agreementButton: 'Ver beneficios dentales',
  refundTitle: 'Reembolso de gastos médicos fácil y expedito',
  refundSubtitle: 'Servicios en línea',
  refundCardList: [
    'Solicitud de reembolso con la APP BICE VIDA',
    'Solicitud de reembolso vía Whatsapp',
    'Solicitud de reembolso en Sucursal Virtual',
    'Reembolso directo con Huella I-MED',
    'Reembolso en línea en farmacias Cruz Verde, Ahumada y Salcobrand',
    'Reembolso en Place Vendome, GMO, Schilling y Rotter & Krauss',
  ],
  healthTitle: 'Acceso exclusivo a  beneficios',
  healthSubtitle: 'Beneficios de salud',
  healthButton: 'Ver todos los beneficios',
  healthDescription:
    'Nuestros asegurados cuentan con acceso exclusivo a beneficios en prestaciones ambulatorias, dentales, hospitalarias y wellness, disponible en Círculo BICE VIDA Plus.',
  healthCardList: [
    {
      text: 'En copago de radiología, imágenes, consulta de urgencia, etc',
      img: BENEFITS_HEALTH_SCAN,
      offer: 'Hasta 50% de dcto.',
      place: 'UC Christus',
      link: 'https://circulo.bicevida.cl/beneficio-detalle/408',
    },
    {
      text: 'En copago de prestaciones ambulatorias entre otros dctos',
      img: BENEFITS_HEALTH_DOCTOR,
      offer: 'Hasta 10% de dcto.',
      place: 'Indisa',
      link: 'https://circulo.bicevida.cl/beneficio-detalle/440',
    },
    {
      text: 'En copago de exámenes de laboratorio, imagenología, etc',
      img: BENEFITS_HEALTH_TEST,
      offer: 'Hasta 50% de dcto.',
      place: 'Sanasalud',
      link: 'https://circulo.bicevida.cl/beneficio-detalle/442',
    },
    {
      text: 'En copago de consulta médica oftalmológica',
      img: BENEFITS_HEALTH_EYE,
      offer: 'Hasta 20% de dcto.',
      place: 'Clínica oftalmológica Pasteur',
      link: 'https://circulo.bicevida.cl/beneficio-detalle/720',
    },
    {
      text: 'En medicamentos con cobertura nacional',
      img: BENEFITS_HEALTH_MEDICINE,
      offer: 'Hasta 15% de dcto.',
      place: 'Farmacia Farmaloop',
      link: 'https://circulo.bicevida.cl/beneficio-detalle/715',
    },
    {
      text: 'En medicamentos génericos con receta médica',
      img: BENEFITS_HEALTH_PILL,
      offer: 'Hasta 12% de dcto.',
      place: 'Farmacia Cruz Verde',
      link: 'https://circulo.bicevida.cl/beneficio-detalle/583',
    },
  ],
  circleTitle: 'Nuestros seguros te dan acceso a los descuentos exclusivos del Círculo BICE VIDA',
  circleList: [
    { name: 'Wellness', image: BENEFITS_CIRCLE.HEART, background: '#FCE8E8' },
    { name: 'Ambulatorio', image: BENEFITS_CIRCLE.PILLS, background: '#CFDFFC' },
    { name: 'Hogar', image: BENEFITS_CIRCLE.LAMP, background: '#FFF0B3' },
    { name: 'Dental', image: BENEFITS_CIRCLE.TOOTH, background: '#CDF0C5' },
    { name: 'Tiempo libre', image: BENEFITS_CIRCLE.CUP, background: '#EEDEFF' },
  ],
  circleBtn: 'Ir al Círculo BICE VIDA',
  coverageTitle: '100% de cobertura hospitalaria',
  coverageSubtitle: 'Beneficios hospitalarios',
  coverageButton: 'Ver prestadores preferentes',
  coverageDescription:
    'Tus trabajadores podrán programar su operación en más de 20 prestadores preferentes como clínicas y hospitales a nivel nacional, con 100% de cobertura y con un tope máximo anual de UF 300 (sujeto a condiciones).',
  coverageCardTitle: 'Ventanilla única, reembolso sin papeleos',
  coverageCardButton: 'Ver detalle',
  coverageCardDescription:
    'Los asegurados que requieran hospitalación o cirugías en ciertos hospitales y clínicas, pueden conocer de forma sencilla lo que deben pagar tras el reembolso de la isapre y Bicevida.',
  coverageCardList: [
    BENEFITS_LOGO_HOSPITAL_ONE,
    BENEFITS_LOGO_HOSPITAL_TWO,
    BENEFITS_LOGO_HOSPITAL_THREE,
    BENEFITS_LOGO_HOSPITAL_FOUR,
  ],
  teleMedicineSection: {
    subTitle: 'Beneficios de salud',
    title: 'Salud Digital: Telemedicina sin costo',
    description:
      'En nuestra plataforma de Salud Digital los asegurados y sus cargas pueden acceder a atención médica en línea sin costo. Incluye:',
    list: [
      {
        icon: 'timer',
        description:
          'Atenciones ilimitadas de medicina general con un tiempo de espera de 20 minutos',
      },
      {
        icon: 'event_available',
        description: 'Especialidades médicas con agendamiento',
      },
      {
        icon: 'receipt',
        description: 'Órdenes de exámenes preventivos emitidas con un solo clic',
      },
    ],
  },
  featuresTitle: '¿Qué entregas a tus trabajadores cuando contratas nuestros planes?',
  featuresCards: [
    {
      title: 'Libre elección de prestadores',
      paragraph: 'Para que tus trabajadores decidan dónde y con quién atenderse.',
      icon: benefitsFeaturesIcon.todo,
    },
    {
      title: 'Telemedicina gratis, inmediata e ilimitada',
      paragraph: 'Para cuidar la salud física y mental de tu equipo. ',
      icon: benefitsFeaturesIcon.cross,
    },
    {
      title: 'Hasta 100% de cobertura hospitalaria',
      paragraph: 'Al programar una operación con nuestros prestadores preferentes.',
      icon: benefitsFeaturesIcon.monitor,
    },
    {
      title: 'Atención psicológica gratis',
      paragraph: 'Hasta 1 sesión semanal en nuestra plataforma de telemedicina.',
      icon: benefitsFeaturesIcon.doctor,
    },
    {
      title: 'Descuentos en medicamentos',
      paragraph: 'Más de 80 farmacias en convenio a lo largo del país.',
      icon: benefitsFeaturesIcon.capsule,
    },
    {
      title: 'Tiempo libre',
      paragraph:
        'Descuentos en comercios y actividades recreativas para equilibrar trabajo y tiempo libre.',
      icon: benefitsFeaturesIcon.tennis,
    },
  ],
};

export const benefitsCardsInfoTexts = [
  {
    id: 0,
    text: 'Revisa más convenios del <b>Círculo BICE VIDA</b>',
    btn: 'Ver convenios',
    icon: BENEFIT_BV_MORE,
  },
];

export const estimateStepsSectionInfoTexts = {
  stepsDetails: [
    {
      text: 'Personaliza tu cotización gratis </br> y 100% en línea',
      icon: ESTIMATE_CUSTOMIZE,
    },
    {
      text: 'Envíanos tus datos y te <br/> contactaremos',
      icon: ESTIMATE_CONTACT,
    },
    {
      text: 'Contrata 100% en línea y listo. <br/> Tus trabajadores ya estarán protegidos',
      icon: ESTIMATE_HIRE,
    },
  ],
  estimateBtn: 'Cotiza ahora',
  title: 'Cotiza y contrata 100% en línea',
};

export const questionsSectionTexts = {
  title: '¿Necesitas más información?',
  subTitle: `<p>En nuestro <b> Centro de ayuda </b> encontrarás las respuesta a tus dudas.</p>`,
  btnText: 'Ir a Centro de ayuda',
};

export const questionsInfoTexts = [
  {
    question: '¿Cómo cotizar y contratar tu seguro sin salir de tu casa?',
    response: `<p><b>Puedes cotizar tu seguro colectivo presionando <a href="${process.env.baseUrl}${routes.wizard}">Cotiza ahora</a> y completando los pasos.</b></p>
    <p><b>Si estás de acuerdo con tu cotización, puedes continuar contratando 100% en línea o desde el correo electrónico que te enviamos con tu cotización. Nuestra ejecutiva estará disponible para asesorarte y resolver tus dudas en todo momento.</b></p>
    <span><b>Ten presente que:</b></span>
    <ul>
      <li>Para que tú y tus trabajadores puedan incorporarse al seguro deben completar el formulario de incorporación firmando digitalmente por medio de Adobe Sign.</li>
      <li>Tú y tus trabajadores solo podrán enviar y firmar un formulario de incorporación, por lo que se debe revisar bien antes de enviar.</li>
      <li>Tú y tus trabajadores recibirán copias de los formulario de incorporación firmados en sus correos electrónicos respectivos.</li>
      <li>Nuestra ejecutiva debe recibir todos los formularios de incorporación firmados para finalizar y generar la poliza.</li>
    </ul>`,
    highlight: 'Nuevo',
  },
  {
    question: '¿Cuáles son las condiciones para contratar el seguro?',
    response: `<ul>
        <li>Los trabajadores asegurados podrán ingresar entre los 18 y 64 años y permanecer hasta los 65 años cumplidos.</li>
        <li>Los hijos podrán ingresar hasta los 23 años y permanecer hasta los 24 años cumplidos.</li>
        <li>El cónyuge o conveniente civil podrá ingresar hasta los 64 años y permanecer hasta los 65 años cumplidos.</li>
        <li>Todos los trabajadores deberán estar afiliados a Isapre, Fonasa u otra institución previsional.</li>
      </ul>`,
  },
  {
    question: '¿Cómo puedo hacer mi reembolso?',
    response: `<p>Puedes hacerlo entrando a tu Sucursal Virtual o en nuestra App BICE Vida. Solo tendrás que enviarnos la boleta y un formulario que debe ser firmado por tu médico.</p>
    <p>Enviaremos una respuesta a tu correo electrónico dentro de dos días hábiles, de ser aprobada, haremos directamente el reembolso a tu cuenta dentro de un día hábil.</p>
    <p>Para reembolsar medicamentos, tendrás que adjuntar la boleta y tu receta médica.</p>
    <p>También puedes hacer tu reembolso en cualquiera de nuestras sucursales.</p>`,
  },
  {
    question: '¿Qué pasa si tengo una preexistencia o estoy con un tratamiento en curso?',
    response: `<p>Este seguro no cubre preexistencias y no da continuidad de cobertura a tratamientos que estén en curso.</p>`,
  },
];

export const bannerSectionTexts = {
  title: 'Cotiza tu seguro ',
  description: 'La mejor inversión para tu empresa es proteger a tus trabajadores',
  button: 'Cotiza ahora',
};

export const availablePlansSectionTexts = {
  title: 'Nuestros planes incluyen Vida y Salud',
  warningCharge: (valueUf: string, dateToday: string) =>
    `Precios de planes con IVA incluído y no incluyen cargas. Valor UF: ${valueUf} al ${dateToday}`,
  exclusions: `Este seguro contempla exclusiones, <a href="${process.env.assetsUrl}${PDFS.POLICY}" target="_blank">  revisa las pólizas</a>  de salud, vida, dental e invalidez accidental.`,
  estimateBtn: 'Cotiza ahora',
  coverageDetails: {
    header: 'Algunas coberturas',
  },
  headerDetails: {
    header: 'Plan desde',
    detail: 'Mensual por trabajador',
  },
  coverageBtn: 'Ver más detalles del plan',
  subTitle: 'Complementa tu plan agregando cobertura <b>Dental y Catastrófica </b>',
  assistances: {
    title: 'Asistencias con este plan',
  },
};

export const modalSuccessTexts = {
  title: '¡Listo! Enviamos a tu correo un resumen de tu cotización',
  description: 'Te contactaremos a más tardar en 2 días hábiles entre las 9:00 y las 18:00 horas.',
  button: 'Vuelve al inicio',
};

export const modalFailedTexts = {
  title: 'Tuvimos un problema al enviar tus datos',
  description: 'Por favor vuelve a intentarlo en unos minutos.',
  button: 'Volver',
};

export const formStepTexts = {
  title: 'Completa estos datos y obtén tu cotización',
  subtitle: 'La enviaremos a tu correo electrónico.',
  dialogue: 'Casi terminamos...',
  description: 'Con esta información, podremos ofrecerte ayuda en el proceso de contratación',
  btnBack: 'Volver',
  inputRut: 'RUT de tu empresa',
  inputName: 'Razon social de tu empresa',
  inputPosition: 'Cargo en tu empresa',
  inputPhone: 'Teléfono de contacto',
  refer: 'Código de referido (opcional)',
  assist: 'RUT',
  invalidReferCodeMessage:
    'Este código no es válido como referido. Solicita nuevamente el código a la empresa e ingrésalo.',
  btnReferCode: '¿Tienes un código de referido?',
  btnAssistCode: '¿Estás asistiendo una venta?',
  helperMessage: (value: boolean) =>
    value
      ? 'Has ingresado correctamente el código de referido'
      : 'Ingresa el código de referido de 8 caracteres que te ha compartido la empresa',

  btnNext: () => 'Obtener cotización',
};

export const personalInfoStepTexts = {
  title: '¿Dónde te enviamos la cotización?',
  dialogue: '¡Ya queremos conocer a tu equipo!',
  description:
    'Pedimos tus datos de contacto para enviarte la cotización y brindarte asistencia personalizada.<br/> <b>No recibirás ofertas a menos que lo autorices.</b>',
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
  iconText: 'Hola',
  toaster: 'Tu actualizó tu total mensual',
};

export const companyInfoStepTexts = {
  title: 'Ingresa a tu panel de contratación',
  dialogue: 'Hola, gusto de verte',
  description:
    'Ingresa el RUT de tu empresa y el correo electrónico que has utilizado en la contratación.',
  btnBack: 'Volver',
  btnNext: 'Continuar',
  iconText: 'Hola, gusto de verte',
  infoCardText:
    'Si olvidaste el correo del contratante, puedes recuperarlo llamando a <b>Servicio al Cliente (800 2020 22)</b> o llamando a tu ejecutiva.',
  warning: `<p> Tu empresa no está en proceso de contratar <a href="${process.env.baseUrl}/cotizacion/nombre-apellido"> Cotiza aquí </a> antes de contratar <p>`,
};

export const RecoverHiringPinCodeInfoTexts = {
  title: 'Ingresa el código enviado a tu correo electrónico',
  dialogue: 'Revisa tu correo',
  btnBack: 'Volver',
  btnNext: 'Continuar',
  iconText: 'Revisa tu correo',
  infoCardText: '¿Problemas con tu código? ',
  resendCodeText: 'Reenviar código',
  pinErrorText: 'Este código es incorrecto, vuelve a ingresarlo',
  retrieveMailTitle: '¡Hola! Utiliza este código para retomar tu contratación en Mi Pyme Segura',
};

export const emailStepTexts = {
  title: '¿A qué correo te gustaría recibir tu cotización?',
  dialogue: (name: string) => `Un gusto, ${name}`,
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
};

export const workersStepTexts = {
  title: '¿Cuántos trabajadores tiene tu pyme?',
  description: `Considérate a ti y a todos tus trabajadores.`,
  iconNotification:
    'Incluyéndote a ti, debes contar con 5 trabajadores o más, todos con una edad máxima de 64 años y 364 días.',
  customIconNotificacion: `<p>Además, tu empresa  debe suscribir un convenio de Prestaciones Complementarias con Los Héroes. <a href="https://www.losheroes.cl/empresas/productos/prestaciones-complementarias" target="_blank">Más información acá.</a></p>`,
  alertTitle: 'Requisitos para contratar',
  btnLessWorkers: `¿Tienes menos de ${minLimitWorkers} trabajadores?`,
  catDialog: (isValid: boolean) =>
    `${isValid ? '¡Genial! Podrás cambiar tu elección más adelante' : '¡Hola! Comencemos...'}`,
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
};

export const plansStepTexts = {
  title: 'Elige un plan para tu pyme',
  warningUF: (valueUf: string) => `Precios de planes con IVA incluído. Valor UF: ${valueUf} al`,
  btnCoverage: 'Ver más detalles del plan',
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
  toaster: 'Tu plan se actualizó con éxito',
};

export const coveragesStepTexts = {
  title: '¿Quieres aumentar la cobertura de tu plan?',
  subtitle: (isMobile: boolean, plan: string) =>
    isMobile
      ? `Tienes la opción de agregar coberturas adicionales a tu plan ${plan}.`
      : `Puedes agregar más de una cobertura a tu plan ${plan}.`,
  life: {
    topic: 'life',
    icon: INSURANCE_LIFE_PLUS_CIRCLE,
    title: 'Salud y Vida',
    description: (name: string) => `Plan ${name} (Salud y Vida)`,
    subtitle: 'Incluidos en tu plan',
  },
  dental: {
    topic: 'dental',
    icon: INSURANCE_DENTAL_CIRCLE,
    title: 'Dental',
    description: 'Cobertura dental',
    subtitle: (cost: string) => `${cost} mensual por trabajador`,
  },
  catastrophic: {
    topic: 'catastrophic',
    icon: INSURANCE_CATASTROPHIC_CIRCLE,
    title: 'Catastrófico',
    description: 'Cobertura catastrófica',
    subtitle: (cost: string) => `${cost} mensual por trabajador`,
  },
  warningUF: (valueUf: string, today: string) =>
    `Montos en pesos al día de hoy. Pueden cambiar según la variación de la UF. Valor UF: ${valueUf} al ${today}. Montos con IVA incluido.`,
  cardButton: 'Ver coberturas',
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
  toaster: {
    dental: 'Agregaste cobertura dental',
    catastrophic: 'Agregaste cobertura catastrófica',
    allCoverages: 'Agregaste 2 coberturas adicionales',
  },
};

export const variantsStepTexts = {
  title: '¿Quieres incluir cargas?',
  subtitle: 'Indica si quieres <b>incluir las cargas de tus trabajadores</b> en el seguro.',
  mobileText: ['No,  sólo a trabajadores', 'Si, trabajadores + cargas'],
  informationModal: {
    title: '¿Quiénes son las cargas?',
    text: (branding?: string) => {
      if (branding === brandingCompanyHeroes)
        return `<div>
                  <p>Las cargas son los <b>hijos y cónyuge</b> del trabajador debidamente acreditadas en Los Héroes como causantes de asignación familiar que tendrán sus mismos beneficios.</p>
                  <br>
                  <ul>
                    <li><b>Los hijos</b> podrán ingresar hasta los 23 años y permanecer hasta los 24 años cumplidos.</li>
                    <li><b>El cónyuge</b> podrá ingresar hasta los 64 años y permanecer hasta los 65 años cumplidos</li>
                  </ul>
                <div>`;

      return `<div>
                <p>Las cargas son los <b>hijos, cónyuge o conviviente civil</b> del trabajador que tendrán sus mismos beneficios.</p>
                  <p><b>Los hijos</b> podrán ingresar hasta los 23 años y permanecer hasta los 24 años cumplidos.</p>
                  <p><b>El cónyuge </b>o conveniente civil podrá ingresar hasta los 64 años y permanecer hasta los 65 años cumplidos</p>
              <div>`;
    },
    btn: 'Entendido',
  },
  infoModalBtn: '¿Quiénes son las cargas?',
  toastDistribution: 'Incluiste cargas en tu plan',
  distributionCard: {
    titleModify: 'Tu distribución de cargas',
    title: 'Distribuir cargas',
    subtitle: 'Distribuye las cargas por trabajador',
    btnDistribute: 'Distribuir',
    btnModify: 'Modificar',
    review: 'Con cargas',
    descriptionVariant: (workers: number, text: string) =>
      `${workers} trabajador${workers === 1 ? '' : 'es'} ${text.toLocaleLowerCase()}`,
  },
  distributionModal: {
    title: 'Distribuye las cargas de tus trabajadores',
    counter: (workers: number) =>
      workers !== 0
        ? `Faltan ${workers} ${workers === 1 ? 'trabajador' : 'trabajadores'} por distribuir`
        : 'Completaste la distribución',
    uf: 'UF',
    clp: 'Pesos',
    totalLabel: 'Valor mensual adicional',
    feedback: (max: number) => `Solo puedes distribuir ${max}`,
    noValue: 'Sin costo',
    btn: 'Agrega a la cotización',
  },
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
  toaster: 'Tu actualizó tu total mensual',
};

export const summaryStepTexts = {
  title: 'Resumen de tu cotización',
  subtitle: 'Nos contactaremos contigo como máximo dentro de 2 días hábiles',
  notification: 'Enviamos esta cotización a tu correo',
  notificationIcon: 'iconSummaryInfo',
  btnBack: 'Volver',
  btnNext: () => 'Continuar',
  planSelected: 'Plan cotizado',
  description: (workers: number) => `${workers} trabajadores`,
  amountPlanDescription: 'Total mensual',
  amountBaseDescription: 'Plan base',
  amountVariantsDescription: (amount: number) => `${amount === 0 ? 'Sin' : 'Con'} cargas`,
  amountByVariantDescription: (text: string, workers: number) =>
    `${workers} trabajador${workers === 1 ? '' : 'es'} ${text.toLocaleLowerCase()}`,

  noValue: 'Sin costo',
  btnHealth: 'Salud',
  btnLife: 'Fallecimiento',
  btnDental: 'Dental',
  btnCardActive: 'Ver el detalle de cargas',
  btnCardInactive: 'Ocultar detalle',
  btnCoverages: 'Ver coberturas',
  btnHiring: (mobile = false) => (mobile ? 'Contrata ahora' : 'Contrata en línea'),
  alertText: (email: string) =>
    `Enviamos esta cotización más detallada a tu correo electrónico ${email}`,
  titleWorker: 'Número de trabajadores',
  valuePerWorker: (value: number) => `${value} c/u`,
  coverageName: (value: string) => `Seguro ${value}`,
  buttonTxt: 'Ver distribución de cargas',
  btnImprovePlan: 'Mejorar plan',
  improvedPlanBadge: 'YA MEJORADO',
};

export const plansRetryTexts = {
  button: 'Recargar',
  loading: 'Cargando planes',
  description: 'Tenemos problemas para cargar la información, vuelve a intentarlo.',
};

export const togglePlansTexts = {
  uf: 'UF',
  clp: 'Pesos',
};

export const tabsPlansTexts = {
  premium: {
    id: 'premium',
    text: 'Premium',
  },
  standard: {
    id: 'standard',
    text: 'Estándar',
  },
};

export const footerSectionTexts = {
  copyright: 'Copyright © BICEVIDA - Todos los derechos reservados.',
  clientService: 'Servicio al cliente',
  phone: '800 2020 22',
  socialNetworks: [
    {
      icon: RRSS.FACEBOOK,
      url: 'https://www.facebook.com/bicevida',
      alt: 'Logo Facebook',
    },
    {
      icon: RRSS.INSTAGRAM,
      url: 'https://instagram.com/bicevida?igshid=1hvwy2nj0rlzk',
      alt: 'Logo Instagram',
    },
    {
      icon: RRSS.X,
      url: 'https://twitter.com/bice_vida',
      alt: 'Logo X',
    },
    {
      icon: RRSS.YOUTUBE,
      url: 'https://www.youtube.com/channel/UCNzbIUVP0ckWGuJ3yFgzsYw',
      alt: 'Logo YouTube',
    },
  ],
  links: [
    {
      text: 'Conócenos',
      url: 'https://www.bicevida.cl/conocenos/',
      badge: false,
    },
    {
      text: 'Centro de ayuda',
      url: `${process.env.baseUrl}${routes.helpDesk}`,
      badge: false,
    },
    {
      text: 'Documentación',
      url: 'https://www.bicevida.cl/documentacion-interes/',
      badge: false,
    },
    {
      text: 'Sucursales',
      url: 'https://www.bicevida.cl/sucursales-horarios/',
      badge: false,
    },
  ],
};

export const availablePlansModalText: any = {
  coverageSection: {
    title: 'Coberturas',
    description: 'Mensual por trabajador',
    quotationButton: 'Cotiza ahora',
  },
  btnPDF: 'Revisa todo el plan',
  life: {
    coverage: 'Vida',
  },
  insurance: 'Seguro de',
  assistances: {
    title: 'Asistencias',
    subtitle: 'Más prestaciones para tus trabajadores',
    providerText: (providers: string[]) =>
      `<b>Mi Pyme Segura</b> cuenta con una serie de asistencias extras a la cobertura de tu plan, otorgadas por <b>${conjuntion.format(
        providers
      )}</b>.`,
  },
};

export const losHeroesBenefits = {
  title: 'Descuentos en Farmacias Ahumada',
  subTitle: 'Aprovecha estos beneficios exclusivos por pertenecer a Caja Los Héroes',
  cards: [
    { discount: '40%', description: 'Medicamentos genéricos', image: benefitIcons.pills },
    { discount: '8%', description: 'Medicamentos de marca', image: benefitIcons.heart },
    { discount: '8%', description: 'En el resto de la farmacia', image: benefitIcons.health },
  ],
  cat: {
    image: '/benefit-cat-alert.png',
    text: (
      <>
        Estos descuentos <strong>aplican antes del reembolso en línea</strong> para la compra de
        medicamentos con receta médica, <strong>con un tope de $10.000</strong>
      </>
    ),
  },
};

export const coverageTableTexts = {
  title: 'Coberturas y bonificaciones',
  tooltip: {
    title: 'Bonificación',
    body: 'Después del Sistema Previsional de Salud',
  },
  bonus: 'Bonificación',
  maximum: 'Tope',
  totalMaximum: 'Tope total',
};

export const assistanceTableTexts = {
  events: 'Eventos por año',
  maximum: 'Tope',
  consultingAndDiscounts: {
    title: 'Asesorías y descuentos',
    image: '/benefit-cat-alert.png',
    consulting: {
      title: 'Asesoría legal y para viajes',
      items: [
        'Orientación legal telefónica sobre procesos legales y judiciales.',
        'Orientación telefónica para viajes sobre regulación animalista en el extranjero y alternativas de transporte.',
      ],
    },
    discounts: {
      title: 'Descuentos en alimentos y accesorios.',
      items: [
        'Hasta 30% de descuento en Huargos',
        'Descuentos de 10% en Wankün.',
        'Hasta $8.000 de descuento en cajas de snacks y accesorios',
      ],
    },
  },
};

export enum benefitContentType {
  description,
  list,
  images,
}

export const planBenefits = {
  tabTitle: 'Beneficios',
  title: 'Convenios y descuentos para el bienestar de tus trabajadores',
  subtitle:
    '<b>Nuestros asegurados</b> tienen acceso exclusivo a múltiples beneficios, como reembolsos expeditos y descuentos en distintas prestaciones.',
  benefitsList: [
    {
      title: 'Beneficios de salud',
      content: [
        {
          type: benefitContentType.description,
          content:
            'Descuentos en el copago de exámenes de laboratorio, imagenología, oftalmología, consultas ambulatorias, ' +
            'atenciones de urgencia y medicamentos, entre otros descuentos que se van actualizando mes a mes.',
        },
      ],
    },
    {
      title: '100% de cobertura hospitalaria',
      content: [
        {
          type: benefitContentType.description,
          content:
            'Es un beneficio que les permite a tus trabajadores acceder a una cirugía   a costo cero, en más de 20 ' +
            'clínicas y hospitales a nivel nacional, como el Hospital Clínico de la Universidad de Chile o la Clínica ' +
            'Dávila, por ejemplo.',
        },
        {
          type: benefitContentType.description,
          content:
            'Cuentan con un 100% de cobertura al programar su operación en estos prestadores en convenio y según ' +
            'patologías cubiertas, con un tope máximo anual de UF 300.',
        },
      ],
    },

    {
      title: 'Reembolso fácil de gastos médicos',
      content: [
        {
          type: benefitContentType.description,
          content:
            'Tenemos diferentes maneras de facilitar el reembolso de los gastos médicos de tus trabajadores:',
        },
        {
          type: benefitContentType.list,
          content: [
            'Reembolso directo con su huella a través del sistema I-MED, app de BICE VIDA, vía Whatsapp y en la Sucursal Virtual.',
            'Reembolso en línea en Farmacias Cruz Verde, Ahumada y Salcobrand.',
            'Reembolso en ópticas Place Vendome, GMO, Schilling y Rotter & Krauss.',
          ],
        },
      ],
    },

    {
      title: 'Salud digital: Telemedicina sin costo',
      content: [
        {
          type: benefitContentType.description,
          content:
            'En nuestra plataforma de Salud Digital los asegurados y sus cargas pueden acceder a atención médica en línea e inmediata. Incluye:',
        },
        {
          type: benefitContentType.list,
          content: [
            'Atenciones ilimitadas de medicina general con un tiempo de espera de 20 minutos.',
            'Especialidades médicas con agendamiento.',
            'Órdenes de exámenes preventivos emitidas con un solo clic.',
            'Wikidoc: contenido educativo en línea para ayudar a tus colaboradores a cuidar de su salud de manera más efectiva.',
          ],
        },
      ],
    },
    {
      title: 'Convenios en centros dentales',
      content: [
        {
          type: benefitContentType.description,
          content:
            'Tus trabajadores asegurados pueden acceder a descuentos de hasta un <b>60%</b> en diferentes centros y ' +
            'clínicas dentales en convenio.',
        },
      ],
    },

    {
      title: 'Ventanilla única de pago',
      content: [
        {
          type: benefitContentType.description,
          content:
            'Se trata de un servicio exclusivo que entrega BICE VIDA a tus trabajadores asegurados para que solo ' +
            'cancelen el copago final de la prestación realizada, evitando así todos los trámites con su entidad de ' +
            '<b>salud previsional y compañía de seguro.</b>',
        },
        {
          type: benefitContentType.description,
          content:
            'En caso de hospitalizaciones o cirugías, en ciertas clínicas y hospitales; este servicio permite a tus ' +
            'trabajadores conocer de forma sencilla lo que deben pagar tras el reembolso de la isapre y Bicevida.',
        },
      ],
    },
    {
      title: 'Círculo BICE VIDA',
      content: [
        {
          type: benefitContentType.description,
          content:
            'Tus trabajadores pueden disfrutar de un mundo de beneficios, descuentos, promociones, concursos y datos de ' +
            'interés en diferentes ámbitos: salud, wellness, tiempo libre, dental, entre muchos otros.',
        },
      ],
    },
  ],
};

export const planElitePawerAssistance = {
  title: 'Asistencia para perros y gatos',
  description:
    'Te apoyamos a ti y a tus trabajadores en el cuidado de perros y gatos con esta asistencia que incluye:',
  categories: {
    coverages: {
      pill: 'Coberturas y topes',
      table: {
        title: 'Asistencia para perros y gatos',
        content: [
          [null, 'Tope por evento', 'Eventos por año'],
          ['Ficha médica digital', 'Sin tope', <strong key={3}>Ilimitado</strong>],
          ['Televeterinaria ilimitada', 'Sin tope', <strong key={3}>Ilimitado</strong>],
          ['Etología a precio preferencial', 'Sin tope', <strong key={3}>Ilimitado</strong>],
          ['Orientación legal ', 'Sin tope', <strong key={3}>Ilimitado</strong>],
          ['Orientación de viajes', 'Sin tope', <strong key={3}>Ilimitado</strong>],
          ['Postura de chip', <strong key={2}>$15.000</strong>, <strong key={3}>1</strong>],
          ['Desparasitación', <strong key={2}>$15.000</strong>, <strong key={3}>1</strong>],
          ['Consulta Control Sano', <strong key={2}>$30.000</strong>, <strong key={3}>1</strong>],
          ['Vacunas legales', <strong key={2}>$30.000</strong>, <strong key={3}>1</strong>],
          ['Exámenes preventivos', <strong key={2}>$40.000</strong>, <strong key={3}>1</strong>],
          [
            'Vacunas opcionales (KC - Leucemia Felina)',
            <strong key={2}>$30.000</strong>,
            <strong key={3}>1</strong>,
          ],
          ['Baño y Peluquería', <strong key={2}>$40.000</strong>, <strong key={3}>1</strong>],
          ['Cremación', <strong key={2}>$100.000</strong>, <strong key={3}>1</strong>],
        ],
      },
      catCard: {
        title: 'Asesorías y descuentos',
        lists: [
          {
            type: 'LIST' as const,
            title: 'Asesoría legal y para viajes',
            list: [
              'Orientación legal telefónica sobre procesos legales y judiciales.',
              'Orientación telefónica para viajes sobre regulación animalista en el extranjero y alternativas de transporte.',
            ],
          },
          {
            type: 'LIST' as const,
            title: 'Descuentos en alimentos y accesorios',
            list: [
              <>
                Descubre los{' '}
                <a
                  href="https://www.somospawer.com/es-cl/beneficios"
                  target="_blank"
                  rel="noreferrer"
                >
                  descuentos y convenios
                </a>{' '}
                que Pawer tiene para ti y tus trabajadores.{' '}
              </>,
            ],
          },
        ],
      },
    },
    howTo: {
      pill: 'Cómo se usa',
      information: {
        title: '¿Cómo empiezo a usar la asistencia para mascotas del plan Elite?',
        paragraphs: [
          <>
            Para empezar a disfrutar de los beneficios de esta asistencia, debes{' '}
            <strong>descargar la App de Pawer</strong> desde la{' '}
            <a href="https://apps.apple.com/us/app/pawer/id1630256975">App Store</a> o{' '}
            <a href="https://play.google.com/store/apps/details?id=cl.pawer.mascotas&pli=1">
              Google Play Store
            </a>{' '}
            en tu celular y registrar a tu perro o gato.
          </>,
          <>
            Cuando tengas la aplicación,{' '}
            <strong>inicia sesión con el correo electrónico que registraste.</strong> Tu contraseña
            será tu RUT sin puntos ni guión, seguido del dígito verificador.
          </>,
          <>
            Una vez dentro de la aplicación y con tu sesión iniciada haz clic en el botón{' '}
            <strong>Agregar Mascota</strong> y completa los datos que te solicitaremos sobre tu
            perro o gato. ¡Y listo! Tu asistencia Pawer estará activa.
          </>,
        ],
      },
      catCard: {
        title: 'Importante',
        list: [
          {
            type: 'PARAGRAPH' as const,
            list: [
              <>
                Considera que{' '}
                <strong>
                  la asistencia comienza a tener efecto después de 30 días desde la compra del Plan
                  Elite,
                </strong>{' '}
                pero el servicio de telemedicina está disponible para ti desde el mismo día de la
                contratación.
              </>,
            ],
          },
        ],
      },
    },
    reinbursement: {
      pill: 'Reembolsos',
      information: {
        title: '¿Cómo reembolsar los gastos que cubre la asistencia de Pawer?',
        paragraphs: [
          <>
            Los gastos médicos generados por tu mascota, deberás reembolsarlos a través de la App de
            Pawer, adjuntando los documentos que acrediten el gasto médico.{' '}
            <strong>Recuerda que Pawer es de libre elección.</strong>
          </>,
        ],
      },
      links: [
        {
          link: 'https://apps.apple.com/us/app/pawer/id1630256975',
          alt: 'Apple App store',
          src: '/card-apple-app-store.png',
        },
        {
          link: 'https://play.google.com/store/apps/details?id=cl.pawer.mascotas&pli=1',
          alt: 'Google Play store',
          src: '/card-google-play-store.png',
        },
      ],
    },
    telemedicine: {
      pill: 'Telemedicina veterinaria',
      information: {
        title: '¿Cómo utilizo el servicio de telemedicina que ofrece Pawer?',
        paragraphs: [
          <>
            Ingresa a la App de Pawer y realiza una solicitud en la plataforma. Una vez recibida tu
            solicitud, <strong>el equipo agendará una cita</strong> con uno de sus veterinarios.
          </>,
        ],
      },
    },
  },
};

export const wizardReviewerTexts = {
  total: 'Total mensual',
  details: 'Ver detalle',
  modal: {
    title: 'Detalle de cotización',
    workers: 'Número de trabajadores',
  },
  toast: 'Se actualizó tu total mensual ',
};

export const modalIndividualInsuranceTexts = {
  title: `¿Tienes menos de ${minLimitWorkers} trabajadores?`,
  description: `Te recomendamos un </br>
<a target="_blank" href="${bicevidaURLWindowIndividualInsurance}">Seguro Complementario de Salud Individual</a>, que brinda la mejor protección para ellos y su familia.`,
  btn: 'Entendido',
};

export const bannerCovidText = {
  description:
    'Sigue cuidándote <b>contratando e incorporando</b> a tus trabajadores con nuestra <b>firma digital</b>.',
};

export const benefitsBiceVidaContainer = {
  textTitle: 'Además tendrán acceso a todos los convenios del Círculo BICE VIDA',
  textFooter: 'Revisa más convenios',
};

export const benefitsBiceVidaCard: any = [
  {
    text: 'En compra de mascarillas',
    img: BENEFIT_MASK,
    offer: '30% de descuento',
  },
  {
    text: 'En cursos en línea',
    img: BENEFIT_KINE,
    offer: '25% de descuento',
  },
  {
    text: 'En tratamientos nutricionales',
    img: BENEFIT_NUTRI,
    offer: '30% de descuento',
  },
];

export const benefitsListText = {
  title: 'Beneficios y convenios para todos los planes',
  link: `Revisa todos los beneficios`,
  benefits: [
    {
      title: 'Coberturas hospitalaria',
      description:
        '100% de cobertura hospitalaria en más de 20 instituciones con tope anual de UF 300.',
      icon: 'local_hospital',
    },
    {
      title: 'Salud digital sin costo',
      description:
        'Los asegurados y sus cargas pueden acceder a atención médica en línea sin costo.',
      icon: 'favorite',
    },
    {
      title: 'Círculo BICE VIDA',
      description: 'Descuentos en clínicas, farmacias, ópticas, atención dental y mucho más.',
      icon: 'local_offer',
    },
  ],
};

export const summaryBannerTexts = {
  title: 'Gracias por cotizar con nosotros',
  description: 'Si tienes dudas revisa nuestras preguntas frecuentes',
  btn: 'Vuelve al inicio',
};

export const summaryStartHiringTexts = {
  title: 'Si te gustó esta cotización, comienza el proceso de ',
  titleColor: 'contratación 100% digital',
  titleBanner: 'Estás a un paso de comenzar tu proceso de contratación 100% digital',
  checkbox: 'He leído y acepto esta cotización',
  btnHires: 'Contrata online',
  btnReQuote: 'Volver a cotizar',
};

export const textAltIcon = {
  seeMore: 'Ver más',
  seeLess: 'Ver menos',
  close: 'Cerrar',
  addWorkers: 'Ir a ingresar trabajadores',
};

export const cardListTooltipsTexts = {
  addIllness: 'Agregar enfermedad',
  editIllness: 'Editar enfermedad',
  removeIllness: 'Eliminar enfermedad',
  removeSport: 'Eliminar deporte o actividad',
  editSport: 'Editar deporte o actividad',
  removeVariant: 'Eliminar carga',
  editVariant: 'Editar carga',
  removeBeneficiary: 'Eliminar beneficiario',
  editBeneficiary: 'Editar beneficiario',
  removeCompany: 'Eliminar razón social',
  editCompany: 'Editar razón social',
};

export const conditionalOptionsTexts = {
  true: 'Sí',
  false: 'No',
};

export const incorporationOnboarding = {
  title: 'Incorpórate a nuestro Seguro Colectivo de Salud y Vida',
  description:
    'Te invitamos a completar esta solicitud de incorporación. Una vez terminado lo podrás revisar para luego seguir con tu firma electrónica.',
  dialogue: '¡Hola!',
  btnNext: 'Comencemos',
  emptyState: {
    title: '¡Lo sentimos! El formulario de incorporación ya no está disponible en esta plataforma',
    subTitle:
      'Tu empresa ya cerró el proceso de contratación de Mi Pyme Segura. Si necesitas incorporarte al seguro, <b>contacta al administrador de tu empresa</b> para que realice la gestión. ',
    description: `Si quieres saber más <b>sobre los procesos de incorporación</b> de trabajadores al seguro, puedes consultar nuestro <a href="${process.env.baseUrl}${routes.helpDesk}">Centro de Ayuda.</a> `,
  },
};

export const datePickerTexts = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
};

export const riskyActivitiesStepTexts = {
  section: 'Deporte o actividad riesgosa',
  title: '¿Realizas algún deporte o actividad riesgosa?',
  aside: [
    {
      title: 'Deporte o actividad riesgosa',
      description:
        'Se entiende por deporte o actividad riesgosa aquella en que se requieren medidas de protección o seguridad para realizarla o que implique poner en grave peligro la vida o integridad física de las personas, aún cuando se practique en forma esporádica.',
    },
    {
      description:
        'Las actividades y deportes riesgosos declarados por ti , sólo serán cubiertos por el seguro si la compañía aseguradora las ha aprobado de forma expresa.',
    },
  ],
  linkMoreInfoMobile: 'Ver deporte o actividad riesgosa',
  infoModalBtn: 'Entendido',
  modal: {
    addTitle: 'Ingresa un deporte o actividad',
    editTitle: 'Editar deporte o actividad',
    activity: 'Deporte o actividad riesgosa',
    frecuency: 'Frecuencia',
    button: 'Guardar deporte o actividad',
  },
  remove: {
    title: 'Eliminar deporte o actividad',
    text: (sport: string) => `¿Estás seguro que quieres eliminar ${sport}?`,
  },
  addBtn: 'Agregar otro',
  addToaster: 'Deporte o actividad guardada',
  removeToaster: 'Deporte o actividad eliminada',
};

export const insuredPersonalInfoStepTexts = {
  title: '¿Cómo te llamas?',
  subtitle: 'INFORMACIÓN PERSONAL',
  alertTitle: 'Tu información es confidencial',
  alertDescription:
    'Te garantizamos que toda tu información personal será protegida y no será divulgada.',
  btnBack: '',
  btnNext: 'Comencemos',
};

export const insuredMoreInfoStepTexts = {
  title: (name: string) => ` ${name}, cuéntanos más sobre ti`,
  subtitle: 'INFORMACIÓN PERSONAL',
  sexSubtitle: '¿Cuál es tu sexo?',
  healthSystemFeedback: 'Olvidaste seleccionar tu sistema de salud',
  healthSystemPlaceholder: 'Sistema de salud',
  dateSelectPlaceholder: 'Fecha de nacimiento',
  dateSelectFeedback: 'Tu fecha no está bien escrita, vuelve a intentarlo',
};

export const InsuredEmailStepTexts = {
  title: '¿Cuál es tu correo ?',
  subtitle: 'INFORMACIÓN PERSONAL',
  description: 'En tu correo electrónico recibirás información de tus pagos o reembolsos.',
  emailSuggestion: (suggestion: string) =>
    `¿Quisiste decir ${suggestion}? <a id="suggestion_option_keep">No, mantener</a> | <a id="suggestion_option_replace">Sí, reemplazar</a>`,
};

export const InsuredJobStepTexts = {
  title: 'Ingresa estos datos laborales',
  subtitle: 'INFORMACIÓN PERSONAL',
  dateSubtitle: '¿Cuándo ingresaste a tu empresa?',
  monthPlaceholder: 'Mes',
  dateFeedback: 'ingreso a la empresa',
  monthList: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  yearPlaceholder: 'Año',
  yearFeedback: 'Olvidaste ingresar tu Año de ingreso',
};

export const refundText = {
  title: '¿En qué cuenta quieres el pago de tus reembolsos?',
  subtitle: 'Ingresa una cuenta donde tú seas la persona titular.',
  subtitleup: 'REEMBOLSO',
  checkboxText: 'No tengo cuenta bancaria',
  inforText: 'Si no tienes cuenta bancaria recibirás un cheque con tu reembolso',
  feedBackBank: 'Olvidaste ingresar tu banco',
  feedbackTypeAccount: 'Olvidaste ingresar tu tipo de cuenta',
  placeholderAccountNumber: 'Nº de cuenta',
  placeholderBank: 'Banco',
  placeholderTypeAccount: 'Tipo de cuenta',
};

export const medicalInfoModalTexts = {
  aside: {
    section: 'INFORMACIÓN MÉDICA',
    title: 'Enfermedades o condiciones médicas preexistentes sin cobertura',
  },
  body: {
    title: '¿Qué son las enfermedades o condiciones médicas preexistentes?',
    description:
      'Son aquellas enfermedades, dolencias o situaciones de salud diagnosticadas o conocidas por ti antes de la incorporación a este seguro.',
  },
  disclaimer: {
    title: 'Importante',
    description:
      '<p>Toda enfermedad y/o dolencia preexistente declarada implica que los beneficios de este seguro no operaran si la causa del siniestro fuera producto de una de las enfermedades o dolencias preexistentes señaladas.</p><p>En caso de no declarar, en este formulario, mis enfermedades o dolencias preexistentes, así como las de mi grupo familiar, significara que la cobertura del contrato de seguro no operara si la causa del siniestro fuese producto de una de las enfermedades o dolencias comprendidas en el listado.</p>',
  },
  illnesses: {
    title: 'Enfermedades o condiciones médicas',
    list: [
      'Tumores',
      'Cáncer',
      'Enfermedades cerebro vasculares',
      'Enfermedades sistema respiratorio',
      'Enfermedades sistema cardiovascular',
      'Enfermedades del corazón',
      'Enfermedades sistema circulatorio',
      'Enfermedades sistema digestivo',
      'Enfermedades renales',
      'Enfermedades sistema genitourinario',
      'Enfermedades sistema endocrino ',
      'Enfermedades nutricionales',
      'Enfermedades metabólicas',
      'Enfermedades sistema nervioso',
      'Enfermedades infecciosas',
      'Enfermedades parasitarias',
      'Enfermedades de las articulaciones ',
      'Enfermedades musculares ',
      'Enfermedades de la piel',
      'Enfermedades reumatológicas ',
      'Enfermedades de la sangre',
      'Enfermedades de los ganglios ',
      'Enfermedades linfáticas',
      'Enfermedades mentales ',
      'Enfermedades siquiatricas',
      'Enfermedades del oído ',
      'Enfermedades de la nariz ',
      'Enfermedades de la boca',
      'Enfermedades de la garganta ',
      'Enfermedades de los ojos',
      'Enfermedades sistema oseo ',
      'Obesidad ',
    ],
  },
};

export const medicalInfoStepTexts = {
  section: 'Información médica',
  title: '¿Tienes alguna enfermedad o condición médica preexistente?',
  aside: [
    {
      title: '¿Qué son enfermedades o condiciones médicas preexistentes?',
      description:
        'Son situaciones de salud diagnosticadas o conocidas por ti antes de la incorporación a este seguro.',
    },
  ],
  alert: {
    title: 'Te informamos que:',
    description:
      '<b> No debes declarar haber padecido una patología oncológica, </b> si a la fecha que suscribes este documento han transcurrido 5 años desde la finalización del tratamiento de esa patología, sin recaída posterior.',
  },
  linkMoreInfoMobile: 'Ver enfermedades sin cobertura',
  linkMoreInfoDesktop: 'Ver todas las enfermedades',
  infoModalBtn: 'Entendido',
  modal: {
    addTitle: 'Ingresa una enfermedad',
    editTitle: 'Editar enfermerdad',
    disease: 'Enfermedad o condición médica',
    date: 'Fecha de diagnóstico',
    month: 'Mes',
    year: 'Año',
    button: 'Guardar enfermedad',
    dateFeedback: 'Olvidaste ingresar una fecha de diagnóstico',
  },
  remove: {
    title: 'Eliminar enfermedad',
    text: (disease: string) => `¿Estás seguro que quieres eliminar ${disease}?`,
  },
  addBtn: 'Agregar otro',
  addToaster: 'Enfermedad guardada',
  removeToaster: 'Enfermedad o condición eliminada',
};

export const asideTexts = {
  help: 'Ayuda',
};

export const familyGroupStepTexts = {
  section: 'Grupo familiar',
  title: '¿Quieres incluir cargas al seguro?',
  subtitle:
    'Podrás incluir a tus cargas solo si es que tu empresa ha considerado incorporarlas al Seguro Colectivo.',
  aside: [
    {
      title: '¿Quiénes son las cargas?',
      description:
        'Las cargas son los hijos, cónyuge o conviviente civil del trabajador que tendrán sus mismos beneficios. </br></br> • Los hijos podrán ingresar hasta los 23 años y permanecer hasta los 24 años cumplidos. </br> </br> • El cónyuge o conveniente civil podrá ingresar hasta los 64 años y permanecer hasta los 65 años cumplidos.',
    },
  ],
  linkMoreInfoMobile: '¿Quiénes son las cargas?',
  infoModalBtn: 'Entendido',
  modal: {
    addTitle: 'Ingresa una carga',
    editTitle: 'Editar carga',
    name: 'Nombres',
    paternalLastname: 'Primer apellido',
    maternalLastname: 'Segundo apellido',
    rut: 'RUT',
    button: 'Continuar',
    buttonStepTwo: 'Guardar carga',
    beneficiaryError: 'Ya ingresaste este RUT, ingresa uno nuevo',
    sexSubtitlePartner: '¿Cuál es su sexo?',
  },
  remove: {
    title: 'Eliminar carga',
    text: (family: string) => `¿Estás seguro que quieres eliminar a ${family}?`,
  },
  addBtn: 'Agregar otra',
  addToaster: 'Carga guardada',
  removeToaster: 'Carga eliminada',
  dateSelectPlaceholder: 'Fecha de nacimiento',
  dateSelectFeedback: 'Olvidaste tu fecha de nacimiento',
  relationshipPlaceholder: 'Parentesco',
  relationshipFeedback: 'Olvidaste tu Parentesco',
  healthSystemPlaceholder: 'Sistema de salud',
  healthSystemFeedback: 'Olvidaste tu sistema de salud',
  sexSubtitle: '¿Cuál es el sexo de tu carga?',
};

export const familyMedicalInfoStepTexts = {
  section: 'grupo familiar',
  title: '¿Tus cargas tienen alguna enfermedad preexistente?',
  titleList: 'Ingresa la enfermedad preexistente de tus cargas',
  alert: {
    title: 'Te informamos que:',
    description:
      '<b> No debes declarar haber padecido una patología oncológica, </b> si a la fecha que suscribes este documento han transcurrido 5 años desde la finalización del tratamiento de esa patología, sin recaída posterior.',
  },
  aside: [
    {
      title: '¿Qué son enfermedades o condiciones médicas preexistentes?',
      description:
        'Son situaciones de salud diagnosticadas o conocidas por ti antes de la incorporación a este seguro.',
      btn: 'Ver todas las enfermedades',
    },
  ],
  linkMoreInfoMobile: 'Ver todas las enfermedades',
  modal: {
    addTitle: 'Ingresa una enfermedad',
    preEditTitle: 'Selecciona una enfermedad',
    editTitle: 'Editar enfermedad',
    disease: 'Enfermedad o condición médica',
    date: 'Fecha de diagnóstico',
    month: 'Mes',
    year: 'Año',
    buttonNext: 'Continuar',
    buttonSave: 'Guardar enfermedad',
  },
  remove: {
    title: 'Eliminar enfermedad',
    text: (disease: string) => `¿Estás seguro que quieres eliminar ${disease}?`,
  },
  addBtn: 'Ingresar',
  addToaster: 'Enfermedad guardada',
  removeToaster: 'Enfermedad eliminada',
};

export const beneficiariesStepTexts = {
  section: 'Beneficiarios seguro de vida',
  title: '¿A quiénes dejarás como beneficiarios?',
  subtitle: 'Puedes agregar a uno o más beneficiarios para el Seguro de Vida.',
  aside: [
    {
      title: '¿Quiénes son los beneficiarios?',
      description:
        'Son aquellas personas que tu designas y que tienen derecho a la indemnización del seguro, en el caso de tu fallecimiento.',
    },
    {
      description: 'Se recomienda designar mayores de edad.',
    },
    {
      description: 'No necesariamente deben ser tu cargas.',
    },
  ],
  linkMoreInfoMobile: '¿Quiénes son los beneficiarios?',
  infoModalBtn: 'Entendido',
  modal: {
    addTitle: 'Ingresa un beneficiario',
    editTitle: 'Editar beneficiario',
    name: 'Nombres',
    paternalLastname: 'Primer apellido',
    maternalLastname: 'Segundo apellido',
    rut: 'RUT',
    relationship: 'Parentesco',
    address: 'Dirección',
    phone: 'Teléfono de contacto',
    email: 'Correo electrónico',
    button: 'Continuar',
    buttonStepTwo: 'Guardar beneficiario',
    optionalText: 'Estos campos son opcionales',
  },
  remove: {
    title: 'Eliminar beneficiario',
    text: (beneficiary: string) => `¿Estás seguro que quieres eliminar a ${beneficiary}?`,
  },
  addBtn: 'Agregar beneficiario',
  addBtnAlternative: 'Agregar otro',
  addToaster: 'Beneficiario guardado',
  removeToaster: 'Beneficiario eliminado',
  percentageText:
    'En caso de tu fallecimiento ¿Qué porcentaje del beneficio económico recibirá esta persona?',
  relationshipPlaceholder: 'Parentesco',
  relationshipFeedback: 'Olvidaste tu Parentesco',
  percentageAlert: 'La suma total de los porcentajes de los beneficiarios debe ser 100%',
};

export const downloadDocumentStepTexts = {
  loaderDownload: 'Estamos generando tu documento',
  loaderSent: 'Estamos enviando tu formulario',
  download: {
    title: 'Descarga tu documento',
    description:
      'Después de descargar este documento, imprímelo, revísalo y si está correcto fírmalo. Después envíalo al encargado de tu empresa.',
    action: 'Descargar',
  },
  sent: {
    title: 'Hemos enviado tu formulario ',
    description: (email: string) =>
      `Te llegará una copia del formulario a <b>${email}</b> para que puedas <b>firmarlo</b>. Si no lo ves en tu bandeja de recibidos, revisa en "Spam".`,
  },
  used: {
    title: 'Tu formulario está pendiente de firma',
    description: (email: string) =>
      `Enviamos tu formulario de incorporación a <b>${email}</b>. Si no lo ves en tu bandeja de recibidos, revisa en "Spam".`,
  },
  retry: {
    description: 'Tenemos problemas para cargar la información, vuelve a intentarlo.',
    action: 'Reintentar',
  },
};

export const incorporationMenuTexts: any = {
  tooltipTitle: 'Tus datos se guardarán',
  tooltipBody:
    'Con este menú podrás revisar tu progreso que se irá guardando a medida que avances, recuerda utilizar el mismo navegador.',
  tooltipBtn: 'Entendido',
  asideTitle: 'Progreso de tu solicitud',
  asideSections: {
    personalInformation: {
      id: 'personal_info',
      title: 'Información personal',
      subSections: {
        personalInformationNameLastname: {
          id: 'personal_info_nombres_apellidos',
          title: 'Nombres y apellidos',
        },
        personalInformationMoreInfo: {
          id: 'personal_info_mas_info',
          title: 'Más información',
        },
        personalInformationEmail: {
          id: 'personal_info_email',
          title: 'Correo electrónico',
        },
        personalInformationWorkingInfo: {
          id: 'personal_info_laboral',
          title: 'Datos laborales',
        },
      },
    },
    refund: {
      id: 'reembolso',
      title: 'Reembolso',
    },
    riskyActivitiesAll: {
      id: 'actividades_riesgosas',
      title: 'Deporte o actividad riesgosa',
    },
    medicalInformationAll: {
      id: 'info_medica',
      title: 'Información médica',
    },
    familyGroup: {
      id: 'cargas',
      title: 'Grupo familiar',
      subSections: {
        familyGroupPersonalInformationAll: {
          id: 'cargas_personal_info',
          title: 'Información personal cargas',
        },
        familyGroupMedicalInformationAll: {
          id: 'cargas_info_medica',
          title: 'Enfermedades diagnosticadas',
        },
      },
    },
    lifeInsuranceBeneficiaries: {
      id: 'beneficiarios',
      title: 'Beneficiarios seguro de vida',
    },
    summaryDocument: {
      id: 'resumen',
      title: 'Resumen',
    },
  },
  tooltipMore: 'Ver más',
  tooltipLess: 'Ver menos',
  asideBtn: 'Cerrar',
};

export const dateComponentText = {
  forgotDate: 'Olvidaste ingresar tu fecha de nacimiento',
};

export const summaryDocumentStepTexts = {
  preModal: {
    title: 'Revisa tu formulario de incorporación',
    description:
      'Para que <b>puedas continuar con la firma digital comprueba que todos tus datos estén correctos</b>. Si quieres corregir algún dato puedes hacerlo accediendo al menú de navegación.',
    action: 'Entendido',
  },
  aside: [
    {
      title: 'Revisa tu formulario de incorporación',
      description:
        'Para que <b>puedas continuar con la firma digital comprueba que todos tus datos estén correctos</b>.',
    },
    {
      description:
        'Si quieres corregir algún dato puedes hacerlo accediendo al menú de navegación.',
    },
    {
      description: 'Una vez enviado el formulario <b>no podrás generar uno nuevo</b>.',
    },
  ],
  captchaModal: {
    title: '¿Tu formulario está correcto?',
    description: `Al confirmar tu formulario será enviado a tu correo para que puedas firmar. Una vez
              enviado el formulario <b>no podrás generar uno nuevo.</b>`,
    confirm: 'Confirmar',
    back: 'Volver',
  },
};

export const hiringSummaryPanel = {
  title: 'Panel de contratación',
  subtitle: (status: string, email: string) => {
    if (status !== LeadStatusEnum.CREATED) {
      return `Ya completaste tu proceso de contratación, <b>recibirás tu póliza al mail</b> ${email}`;
    }

    return '¡Hola! Ya recibimos tu cotización. Puedes contratar el seguro en <b>pasos para contratar</b> o seguir recotizando con el <b>cotizador de planes.</b>';
  },
  cardDescriptionInfo: (email: string) =>
    `Te enviamos esta cotización y sus detalles al correo electrónico <b>${email}</b>`,
  cardTitle: '¿Cómo contratar en línea?',
  summaryButton: 'Comenzar a contratar',
  buttonConfirmHiring: 'Contratar',
  workers: (workers: number) => `Para ${workers} trabajadores`,
  disclaimerTooltipText:
    'Podrás continuar cuando completes los datos de la empresa y al menos 5 de tus trabajadores hayan firmado el formulario de incorporación.',
  summaryButtonPricePlan: 'Simular una nueva cotización',
  insurancesLabel: 'Seguros Cotizados',
  variantsLabel: 'Costo adicional por carga',
  totalLabel: 'Valor total mensual',
  companyNameInfo: {
    subtitle: 'Razones sociales',
    description: 'Te facturaremos mensualmente en la siguiente razón social:',
    modal: {
      title: 'Ingresa los datos para agregar la razón social ',
      secondTitle: 'Razón social agregada',
      alertText: 'Se facturará a esta razón social.',
      rutPlaceHolder: 'RUT de tu empresa',
      namePlaceHolder: 'Razón social de tu empresa',
      button: 'Agregar',
    },
    addAnotherButton: 'Agregar otra más',
    finishButton: 'Finalizar',
  },
  cards: [
    {
      icon: PYME_FORM_ICON,
      title: 'Comparte el formulario de incorporación',
      description:
        '<b>Tú y tus trabajadores</b> deben completar y firmar el formulario en línea para incorporarse al seguro.',
    },
    {
      icon: PYME_DATA_ICON,
      title: 'Ingresa los datos de tu pyme',
      description:
        'Completa los datos de tu empresa y los de la <b>persona que administrará el seguro</b>, y estarás listo para concretar la contratación.',
    },

    {
      icon: PYME_PROGRESS_ICON,
      title: 'Progreso de las incorporaciones',
      description:
        'Revisa <b>quiénes han completado y firmado</b> el formulario de incorporación y haz un seguimiento de los trabajadores que faltan.',
    },
  ],
  cyberCard: {
    description: `<b>Recuerda contratar el seguro</b> para recibir la gift card de $100.000.  <a href="${process.env.assetsUrl}${PDFS.CYBER}" target="_blank" rel="noopener noreferrer">Ver bases legales <a/>`,
  },
  warningUF: (valueUf: string, today: string) =>
    `Montos en pesos al día de hoy. Pueden cambiar según la variación de la UF. Valor UF: ${valueUf} al ${today}. Montos con IVA incluido.`,
  hiringStepsTitle: 'Contrata en línea fácilmente',
  hiringStepsDescription:
    'Para continuar con la contratación de tu seguro, tienes que realizar las siguientes acciones:',
  hiringStepsButton: 'Revisar incorporaciones',
  hiringStepsAdvertice:
    'Para asesorarte, nuestras ejecutivas se comunicarán contigo en 2 días hábiles como máximo.',
  hiringModalWarning: {
    title: 'Confirma que quieres seguir sin una razón social adicional',
    description:
      'Puedes avanzar sin ella pero, si necesitas agregar una razón social con posterioridad, solo podrás hacerlo a través de tu ejecutiva. ',
    cancelButtonText: 'Cancelar',
    buttonText: 'Confirmar',
  },
  stepCards: [
    {
      customTitle: (length: number, status: string): string => {
        if (status === 'IN_PROGRESS') return '¿Quieres agregar una razón social adicional?';

        if (status === 'SUCCESS' && length > 1)
          return '¡Listo! Agregaste una razón social adicional';

        return '¡Listo! Continuaremos la contratación con una sola razón social';
      },
      customDescription: (status: string): string => {
        if (status === 'IN_PROGRESS')
          return 'Si necesitas contratar con más de una razón social, tienes que agregarla ahora. Es importante que lo hagas antes de avanzar en la contratación.';

        return 'Recuerda que, si necesitas agregar una nueva razón social, tendrás que hacerlo a través de tu ejecutiva.';
      },
      title: '',
      description: '',
      icon: 'domain',
      textIcon: '',
      textIconIsMobile: (isMobile: boolean) => (isMobile ? 'Sí, agregar' : 'Sí, quiero agregar'),
      secondIcon: 'domain_disabled',
      textSecondIcon: (isMobile: boolean) => (isMobile ? 'No agregar' : 'No quiero agregar'),
      step: StepsEnum.COMPANY_NAME,
    },
    {
      title: 'Ingresa los datos de la empresa',
      description:
        'Completa la información que necesitamos para facturar a las razones sociales que ingresaste y de la persona que administrará el seguro.',
      icon: 'dns',
      textIcon: 'Ingresar datos',
      step: StepsEnum.PYME_DATA,
    },
    {
      title: 'Completa el formulario de incorporación en línea',
      description:
        'Si eres parte de la empresa, tú también debes completar el formulario de incorporación, igual que tus trabajadores.',
      icon: 'assignment',
      textIcon: 'Completar formulario',
      step: StepsEnum.INCORPORATION_FORM,
    },
    {
      title: 'Comparte el formulario de incorporación en línea',
      description:
        'Debes compartir el formulario de incorporación con todos los trabajadores que vas a asegurar. Puedes revisar su avance en la sección “Incorporaciones”.',
      icon: 'share',
      textIcon: 'Compartir formulario',
      step: StepsEnum.SHARE_INCORPORATION,
    },
    {
      title: 'Asegúrate de que el 100% de las incorporaciones estén completas',
      description:
        'Para poder cerrar la contratación, tú y todos los trabajadores que vas a asegurar deben completar y firmar su formulario de incorporación en línea.',
      icon: 'face',
      textIcon: 'Ver incorporaciones',
      step: StepsEnum.CHECK_INCORPORATION,
    },
  ],
  hiringStepButton: 'Revisar incorporaciones',
  modalConfirmHiring: {
    title: (confirmWorkers: number) => `Vas a asegurar a ${confirmWorkers} trabajadores`,
    subtitle: (initialWorkers: number, currentConfirmWorkers: number) =>
      initialWorkers === currentConfirmWorkers
        ? `Tienes ${initialWorkers} trabajadores listos para contratar pero considera que quedan algunos pendientes.`
        : `Considera que tu cotización inicial tiene<br><b>${initialWorkers} trabajadores y tu contratación actual ${currentConfirmWorkers}</b>.`,
    description: (isMobile: boolean) =>
      isMobile ? 'Trabajadores no incluidos:' : 'Trabajadores no incluidos en tu contratación:',
    toSign: (workers: number) => `${workers} están por firmar`,
    inProgress: (workers: number) => `${workers} está en proceso de incorporación`,
    buttonText: 'Continuar',
    confirmMailTitle: (plan: string) => `¡Hola! Utiliza este código para contratar el Plan ${plan}`,
    notStart: (workers: number) =>
      workers > 1
        ? `${workers} No han iniciado el proceso de incorporación`
        : `${workers} No ha iniciado el proceso de incorporación`,
    disclaimer:
      '<b>Los trabajadores que no incluyas</b> en esta contratación <b>pueden incorporarse al seguro</b> después. Pero tendrás que iniciar un nuevo proceso por correo electrónico, completando todos los datos  <b>de forma manual.</b>',
  },
  hiringConfirmationText: {
    title: 'Revisa los datos y acepta la contratación del seguro',
    terms: `He leído y aceptado las <a href="${process.env.assetsUrl}${PDFS.POLICY}" target="_blank">condiciones de la póliza.</a> (Obligatorio)`,
    buttonBackText: 'Volver',
    buttonConfirmText: 'Confirmar y aceptar',
  },
  companyReviewText: {
    labels: {
      rut: 'RUT',
      name: 'Razón social',
      activity: 'Giro',
      region: 'Región',
      district: 'Comuna',
      address: 'Dirección',
    },
    subtitleCompany: 'Datos de la empresa',
    subtitleBilling: 'Datos facturación',
    buttontText: 'Editar',
    modalTitleText: 'Edita los datos de la empresa',
  },
  adminReviewText: {
    labels: {
      fullname: 'Nombre completo',
      rut: 'RUT del administrador',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      position: 'Cargo',
    },
    titleAdmin: 'Datos del administrador',
    modalTitleText: 'Edita los datos del administrador',
    buttontText: 'Editar',
  },
  summaryPriceReviewText: {
    labels: {
      workers: 'N° de trabajadores',
      workersNumber: (workers: number) => `${workers} trabajadores`,
      plan: 'Plan base',
      coverage: 'Coberturas',
      variants: 'Distribución de cargas',
    },
    coveragesLabel: {
      dental: 'Dental',
      catastrophic: 'Catastrófico',
    },
    title: 'Oferta a contratar',
    buttonText: 'Ver coberturas',
    totalMonth: '<b>Total mensual</b>',
    totalMoney: (moneyCLP: string, moneyUF: string) => `<b>${moneyCLP}</b> / ${moneyUF}`,
  },
  pinCodeReview: {
    title: 'Código para aceptar esta contratación',
    subtitle: 'Ingresa el código que enviamos al correo del administrador',
    disclaimerText: 'Este código tiene una duración de 1 hr.',
    buttonText: 'Reenviar código',
    toastText: 'Código reenviado exitosamente',
  },
  dateReview: {
    question: '¿Cuándo quieres que comience la cobertura?',
    alert: (date: string) => `Tu cobertura empieza el <b>${date}</b>`,
    formatDate: (date: Date) => {
      const formatter = new Intl.DateTimeFormat('es-CL', {
        month: 'long',
        day: 'numeric',
      });

      return `Cobertura desde ${formatter.format(date)}`;
    },
  },
};

export const hiringCompanyData = {
  title: 'Información de tu empresa y razones sociales',
  companyTitle: (companyName: string, isMobile: boolean) =>
    isMobile ? `${companyName}` : `Datos de ${companyName}`,
  warningText: 'Faltan datos',
  titleEmptyData: 'Un momento... ¿ya pasaste por los pasos para contratar?',
  linkEmptyData: 'Pasos para contratar',
  descripcion: {
    firstHalf: 'Antes de completar los datos de la empresa, te recomendamos revisar la sección ',
    secondHalf:
      ' y seguir las instrucciones. Así podrás continuar con el proceso de contratación fácilmente.',
  },
  successText: 'Datos completos',
  companyMissingDataTitle: 'Completa los datos que faltan',
  companyMissingDataSubTitle:
    'Te pedimos la información para contactar y facturar correctamente a esta razón social.',
  companyMissingDataButtonText: 'Completar datos',
  adminTitle: (isMobile: boolean) =>
    isMobile ? 'Administrador' : 'Datos de quien administrará el seguro',
  adminMissingDataTitle: 'Define quien administrará el seguro',
  adminMissingDataSubTitle:
    'Esta persona será la encargada de incorporar o eliminar asegurados y recibirá la factura mensual de la póliza a contratar.',
  adminMissingDataButtonText: 'Definir administrador',
  buttonText: 'Revisar incorporaciones',
  phoneFormat: (phone: string) => `+56${phone.charAt(0)} ${phone.slice(1, 10).concat(' ')}`,
  successBox: {
    description:
      '¡Listo! Ya completaste los datos de tu empresa. Ahora, solo te falta revisar que tus trabajadores hayan completado y firmado sus ',
    link: 'formularios de incorporación',
  },
};

export const instructionPanelText = {
  title: 'Avancemos con la incorporación a tu seguro colectivo',
  subtitle: (plan: string, worker: string) =>
    `Tu plan a contratar es  <b> ${plan}</b> para ${worker} trabajadores.`,
  text_complete: 'Completa tu formulario de incorporación',
  greeting: (name: string) => `HOLA ${name}`,
  text_sub_complete: 'Para contratar el seguro colectivo completa tu formulario de incorporación.',
  text_share: 'Comparte el formulario de incorporación',
  text_sub_share:
    'Pide a tus trabajadores que completen y firmen el formulario de incorporación al seguro.',
  text_alert: {
    title: 'Información importante',
    text: 'Cada vez que tus trabajadores completen o firmen sus formularios de incorporación te enviaremos un correo de notificación.',
  },
  button_instruction: {
    btn_complete_text: 'Completar formulario',
    btn_share_text: 'Compartir formulario',
  },
  footer_text: [
    {
      title: 'Recepción de formularios',
      description:
        'Recibirás en tu correo electrónico copias de los formularios firmados por tus trabajadores.',
      icon: 'email',
    },
    {
      title: 'Asistencia de ejecutiva',
      description:
        'Nuestra ejecutiva recibirá copias de todos los formularios y te informará si algo falta.',
      icon: 'question_answer',
    },
    {
      title: 'Fomularios firmados',
      description:
        'Para avanzar y generar la póliza nuestra ejecutiva debe recibir todos los formularios firmados.',
      icon: 'how_to_reg',
    },
  ],

  footer_info: {
    primary: 'Durante la contratación ten en cuenta lo siguiente:',
  },
  text_incorporation: 'Para incorporar al seguro',
};

export const finalConfirmationText = {
  title: '¡Listo! Recibimos la información para </br> cerrar tu contratación',
  subtitle: (email: string, date: string) => {
    const dateFormat = new Date(date);
    const chargeStart = startChargeCurrentMonth(date)
      ? format(dateFormat, 'MMMM', {
          locale: es,
        })
      : format(addMonths(dateFormat, 1), 'MMMM', {
          locale: es,
        });

    return `Pronto <b> enviaremos la póliza </b> a ${email} </br> y el cobro mensual llegará durante la segunda quincena de ${chargeStart} para que puedas pagar.`; // TODO: CAMBIAR TEXTO CUANDO LA CONTRATACION SEA 100% ONLINE
  },
  iconCheckSuccessCat: 'iconSuccessCheckCat' as const,
  variantLabel: (description: string) =>
    description.toLowerCase().includes('con cargas')
      ? 'con 2 o más cargas'
      : description.toLowerCase(),
  infoCardText: (date: string) =>
    `Tus trabajadores están protegidos desde el ${getStartChargeDay(
      date
    )} y pronto recibirán su pack de bienvenida.`,
  successTitle: 'Confimación final',
  review: {
    planLabel: 'Seguro contratado',
    workersLabel: 'N° de trabajadores',
    variantsTitle: 'Distribución de cargas',
    totalLabel: 'Total mensual a pagar',
  },
};

export const modalShareText = {
  principalText: 'Comparte el formulario de incorporación',
  secondaryText: 'Copia el enlace y envíaselo a todos tus trabajadores',
  button: 'Aceptar',
  iconSendEmail: 'Enviar por correo electrónico',
  linkToEmail: (email: string, url: string) =>
    `mailto: ${email}?subject= Link de incorporación al Seguro Colectivo de BICE VIDA&body=Hola! En este link podrás encontrar el formulario de incorporación al seguro colectivo, completarlo y firmarlo </b>${url} `,
};

export const progressPanelText = {
  title: '¿Ya compartiste el formulario?',
  subtitle:
    'Podrás ver la nómina de trabajadores una vez hayan comenzado a completar sus formularios de incorporación. Si aún no has compartido el formulario sigue en ',
  link: 'pasos para contratar',
  description: '<span>Recuerda </span> tú también completar el formulario de incorporación',
};

export const hiringTexts = {
  title: 'Ingresa los datos que necesitamos para facturar a cada una de tus pymes',
  dialogue: 'Comencemos',
  btnNext: 'Continuar',
  namePlaceholder: 'Razón social de tu empresa',
  rutPlaceholder: 'RUT de tu empresa',
  activityPlaceholder: 'Giro de la pyme',
  phonePlaceholder: 'Teléfono de la empresa',
  regionPlaceholder: 'Región',
  streetNumberPlaceholder: 'Nº Calle',
  addressApartmentPlaceholder: 'Nº Depto / Of',
  regionFeedback: 'Olvidaste la region de tu empresa',
  districtPlaceholder: 'Comuna',
  districtFeedback: 'Olvidaste la comuna de tu empresa',
  addressPlaceholder: 'Dirección',
  invalidLead: 'An error occurred',
  redirecting: 'Te estamos redirigiendo',
  loading: 'Cargando',
  toaster: 'Giro obtenido desde SII',
  billingSubtitle: 'Dirección para facturación',
  companyData: 'Completa los datos de la empresa',
  buttonText: 'Guardar',
  companies: {
    dialogue: 'Por último',
    title: '¿Quieres agregar otra razón social?',
    primaryLabel: 'Agregada anteriormente',
    btnNext: 'Continuar',
    btnBack: 'Volver',
    modalPrimaryInfo: {
      addTitle: 'Agregar otra razón social',
      editTitle: 'Editar razón social',
      button: 'Continuar',
    },
    modalAdditionalInfo: {
      addTitle: 'Ingresa esta información',
      editTitle: 'Editar razón social',
      button: 'Guardar razón social',
    },
    remove: {
      title: 'Eliminar razón social',
      text: (company: string) => `¿Estás seguro que quieres eliminar ${company}?`,
    },
    addBtn: 'Agregar otra',
    addToaster: 'Nueva razón social agregada',
    removeToaster: 'Razón social eliminada',
  },
  dashboard: {
    title: `¿Cómo <span>contratar</span> en línea en simples pasos?`,
    steps: [
      'Ingresa los datos de tu empresa y del administrador del seguro.',
      'Comparte el formulario de incorporación 100% digital a tus trabajadores',
      'Tú y tus trabajadores deben completar y firmar el formulario de incorporación',
      'Cuando estén todos los formularios firmados, nuestros ejecutivos te ayudarán a finalizar el proceso',
    ],
    alert:
      'Nos comunicaremos contigo para asesorarte una vez hayas comenzado el proceso de contratación, como máximo en 2 días hábiles.',
    btnNext: (mobile = false) => (mobile ? 'Comenzar' : 'Comencemos'),
  },
  admin: {
    select: {
      dialogue: 'Selecciona',
      title: '¿Quién será el administrador del seguro?',
      description:
        'El administrador del seguro será quien podrá incorporar o excluir asegurados y recibirá la factura mensual de la póliza a contratar.',
      cards: (name: string, position: string) => [
        {
          title: name,
          description: `${position} </br>Persona que cotizó el seguro`,
          id: 'default',
        },
        {
          title: 'Otra persona',
          description: 'Ingresa sus datos para registrarlo como administrador',
          id: 'new',
        },
      ],
      newAdminId: 'new',
    },
    info: {
      dialogue: 'Sigue...',
      title: (oldAdmin: boolean) =>
        `${
          oldAdmin
            ? 'Revisa los datos del administrador del seguro'
            : 'Ingresa los datos del administrador del seguro'
        }`,
      fillRut: 'Ingresa este dato',
      checkInfo: 'Revisa y corrige si es necesario',
    },
  },
};

export const hiredTexts = {
  title: '¡Lo sentimos! El proceso de contratación ya no está disponible en esta plataforma',
  subtitle:
    'Tu empresa ya finalizó la contratación de Mi Pyme Segura. Si necesitas incorporar o dar de baja a algún trabajador del seguro, <b>envía la solicitud por correo a <a href="mailto:movimientos.colectivos@bicevida.cl">movimientos.colectivos@bicevida.cl</a></b>',
  description: `Si quieres saber más <b>sobre los procesos para incorporar o dar de baja trabajadores del seguro</b>, puedes consultar nuestro <a href="${process.env.baseUrl}${routes.helpDesk}">Centro de Ayuda.</a> `,
};

export const activityTexts = {
  updateDataText: '¿Quieres actualizar estos datos?',
  updateDataButton: 'Actualizar datos',
  inputPlaceholder: 'Razón social',
  buttonText: 'Seleccionar Giro de empresa',
  textAreaPlaceholder: 'Escribe el giro de empresa',
  textAreaHelper: 'Como necesitas que aparezca en la factura.',
  modal: {
    title: (length: number) => {
      if (length === 0) return 'Ingresa el giro de tu empresa';

      return 'Selecciona el giro que necesitas que aparezca en tu factura';
    },
    description: (length: number, name: string) => {
      if (length === 0) return '';

      return `Encontramos ${length} giros para <b>${name}</b>`;
    },
    button: 'Continuar',
  },
  siiDataModal: {
    loading: {
      text: 'Estamos actualizando los datos desde el Servicio de Impuestos Internos (SII)',
      button: 'Cancelar',
    },
    nameText: 'Razón social',
    activityText: 'Giro',
    activityFinded: 'Giros encontrados',
    titleError: 'No pudimos encontrar los datos',
    titleSingleElement: 'Hemos encontrado estos datos',
    titleMultiElements: 'Encontramos estos datos  actualizados para tu empresa',
    description: (nameExist: boolean, singleElement: boolean) =>
      `${nameExist ? 'Esta razón social y est' : 'Est'}${
        singleElement ? 'e giro han sido obtenido' : 'os giros han sido obtenidos'
      } desde SII. <br> Recuerda que estos datos aparecerán en tu factura.`,
    descriptionError:
      'Hemos tenido problemas con la consulta de datos a Servicio de Impuestos Internos (SII).',
    subtitleError:
      'Puedes continuar con los datos de tu empresa que tenemos en nuestros registros.',
    button: 'Actualizar',
    buttonError: 'Entendido',
  },
};

export const modalAdminSelection = {
  addDataTitle: 'Ingresa los datos de la persona que administrará el seguro',
  selectAdminTitle: 'Selecciona al administrador',
  subtitle: '¿Quién será la persona que administrará el seguro?',
  buttonText: 'Continuar',
};

export const tabsText = {
  incorporation: 'Incorporación',
  progressPanel: 'Panel de progreso',
};

export const hiringTabsItems = [
  {
    id: 'hiring',
    text: 'Plan cotizado',
  },
  {
    id: 'steps',
    text: 'Pasos para contratar',
  },
  {
    id: 'incorporations',
    text: 'Incorporaciones',
  },
  {
    id: 'companyData',
    text: 'Datos de empresa',
  },
];

export const cardsTextProgressPanel = {
  title: 'Revisa la incorporación de los trabajadores',
  subtitle:
    'Todos tus trabajadores <span>incluido tú</span>, deben completar sus formularios de incorporación',
  totallyText: (total: number) => `De un total de ${total} trabajadores cotizados`,

  inProcess: {
    title: 'Formularios en proceso',
    iconTitle: 'En proceso',
    iconBody: 'Los trabajadores están en proceso de llenado del formulario de incorporación.',
  },

  forSigned: {
    title: 'Formularios por firmar',
    iconTitle: 'Por firmar',
    iconBody: 'Los trabajadores completaron los formularios y solo les queda firmar',
  },

  signed: {
    title: 'Formularios firmados',
    iconTitle: 'Firmado',
    iconBody: 'Los trabajadores completaron y firmaron el formulario de incorporación',
  },

  familyGroup: {
    title: 'Trabajadores y sus cargas',
    iconBody:
      'Los números corresponden a la cantidad de trabajadores que agregaron 1, 2 o más o ninguna carga.',
  },
};

export const panelProgressViewList = {
  incorporation: {
    title: (works: number, companyName: string) =>
      works > 1
        ? `${works} trabajadores de ${companyName.toUpperCase()}`
        : `${works} trabajador de ${companyName.toUpperCase()}`,
  },
  timePassedText: (lastRefresh: string, timePassed: string) =>
    `Actualizado por última vez el <b>${lastRefresh}</b> (${timePassed})`,
  refreshToasterText: 'Incorporaciones actualizadas',
  refreshButtonLabel: 'Actualizar',
};

export const panelProgressListAmount = {
  message: (actual: string | number, total: string | number) =>
    `Mostrando <b>${actual}</b> de <b>${total}</b>`,
  chunkSize: 8,
  moreChunks: 'Cargar más',
  searchBarPlaceholder: '¿A quién estás buscando?',
  downloadLabel: 'Descargar todos',
  companyCardDate: (date: string) => `Actualización ${formatIncorporationDate(date)}`,
  menuLabel: 'Abrir menú',
  restoreModal: {
    title: 'Restablecer formulario',
    cancelButton: 'Cancelar',
    submitButton: 'Restablecer',
    tooltipText: 'Restablecer formulario',
    alertText:
      'Esta acción es definitiva, por lo que una vez que le des a restablecer, ' +
      'todos <b>los datos del formulario actual se perderán de forma permanente.</b>',

    paragraph: (fullName: string) =>
      `¿Estás seguro que quieres restablecer el formulario de incorporación ${fullName}?`,
  },
  deleteModal: {
    title: 'Eliminar trabajador',
    cancelButton: 'Cancelar',
    submitButton: 'Eliminar',
    alertText:
      'Esta acción es definitiva, por lo que una vez que le des a eliminar, ' +
      'todos <b>los datos del trabajador se perderán de forma permanente.</b>',
    paragraph: (fullName: string) =>
      `¿Estás seguro que quieres eliminar al trabajador ${fullName} del proceso de contratación?`,
  },
  reminderModal: {
    title: 'Enviar recordatorio',
    cancelButton: 'Cancelar',
    submitButton: 'Enviar',
    alertText: null,
    paragraph: (fullName: string, email: string) =>
      `Enviarás un recordatorio para firmar el formulario al trabajador ${fullName} al correo ${email}`,
  },
  familyGroup: (loads: number) => {
    if (loads > 1) return loadOptions.TWO_OR_MORE_LOADS;
    if (loads === 1) return loadOptions.ONE_LOAD;

    return loadOptions.WITHOUT_LOADS;
  },
  footer: {
    message: (actual: string | number, total: string | number) =>
      `Mostrando <b>${actual}</b> de <b>${total}</b>`,
  },
  toaster: '¡Listo! Formulario restablecido',
  chargesDetail: {
    title: 'Detalle de cargas',
    description: 'A continuación, verás el número de trabajadores y la distribución de sus cargas.',
    adviser:
      '*Este detalle corresponde a las cargas ingresadas por los <b> trabajadores que cuentan con su formulario de incorporación completado y firmado.</b>',
    continueButton: 'Entiendo',
    chargesAmount: 'Cantidad de cargas',
    chargesWorkers: 'N° de trabajadores',
    button: 'Ver sus cargas',
  },
};

export const plansComparationTexts = {
  action: {
    title: '¿Quieres comparar nuestros planes?',
    button: 'Comparar planes',
  },
  modal: {
    selection: {
      title: 'Selecciona planes',
      description: (max: number) => `Selecciona ${max} planes para comparar`,
      counter: (counter: number) =>
        `${counter === 0 ? 'P' : 'p'}lan${counter === 1 ? '' : 'es'} a comparar`,
      premium: 'Planes Pyme Premium',
      standard: 'Planes Pyme Estándar',
      button: 'Comparar',
    },
    compare: {
      title: 'Resultado de comparación',
      description: 'Revisa el resultado de tu comparación',
      selectBtn: 'Seleccionar',
      bonus: 'Bonificación',
      maximum: 'Tope',
      noCoverage: (coverage: string) => `No incluye seguro ${coverage.toLowerCase()}`,
    },
    plan: (CLP: string, UF: string) => `${CLP} /${UF} mensual por trabajador`,
    detail: {
      all: 'Estos planes cubrirán',
      catastrophic: 'Los planes que incluyan esta cobertura cubrirán',
      single: (plan: string) => `El plan <b>${plan}</b> cubrirá`,
      remove: 'Este plan cubrirá',
      specificDental: (type: string, isFirst: boolean) =>
        `Los planes <b>${type}</b> ${isFirst ? 'que incluyan esta cobertura' : ''} cubrirán`,
    },
  },
};
export const successfulSignatureText = {
  title: 'Genial! Tu firma ya está lista',
  subTitle: 'Hemos enviado una copia del formulario firmado a tu correo electrónico',
  iconCheckSuccessCat: 'iconSuccessCheckCat' as const,
  titleSecondSection: '¿Quieres saber lo que BICEVIDA tiene para ti?',
  subTitleSecondSection: 'Te invitamos a conocer nuestros productos ',

  cards: [
    {
      img: imgChooseInsurance,
      text: 'Elige tu seguro',
      textBody:
        'Te enseñamos todo lo que debes saber para tomar una decisión completamente informado. ',
      link: 'https://eligetuseguro.bicevida.cl/conocimiento?utm_source=MiPYMESegura&utm_medium=Referral&utm_campaign=End-FirmaFormulario&utm_content=End-FirmaFormulario-Conoce',
    },
    {
      img: imgJubilolos,

      text: 'Jubilolos',
      textBody:
        'Conviértete en un "Jubilolo" y asegura tu pensión para toda la vida y con un monto que no se desvaloriza en el tiempo. ',
      link: 'https://www.bicevida.cl/productos/renta-vitalicia-previsional/?utm_source=MiPYMESegura&utm_medium=Referral&utm_campaign=End-FirmaFormulario&utm_content=End-FirmaFormulario-Conoce',
    },
    {
      img: imgSaveMoney,
      text: 'APV más seguro de vida',
      textBody:
        'Ahorro para aumentar tu futura pensión, con beneficios tributarios, y un seguro que protege a tu familia en caso de que fallezcas.',
      link: 'https://seguromasapv.bicevida.cl/?utm_source=pymes&utm_medium=crossselling',
    },
  ],

  textLink: 'Descubre más',
};

export const textMultipleReasonSocialInc = {
  preTitle: 'INFORMACIÓN PERSONAL',
  title: 'Ingresa estos datos laborales',
  placeHolderSelect: '¿Cuál es tu empleador?',
  textDateTitle: '¿Cuándo ingresaste a tu empresa?',
  textDateMessage: 'ingreso a la empresa',
  feedbackSelectCompanies: 'Olvidaste seleccionar tu empleador',
};

export const internationalCoverageText = {
  title: 'Coberturas en el extranjero',
  text: 'Los porcentajes de bonificación y topes son las mismas en el caso que uses el seguro en el extranjero.',
};

export const coveragesTabs = {
  coverages: 'Coberturas y bonificaciones',
  enforcement: '¿Cómo aplican las coberturas?',
};

export const bmiCoverageText = {
  title: 'Bonificación mínima institución previsional (BMI) ',
  description:
    'Es el porcentaje mínimo de cobertura que asumimos debe tener tu institución previsional de salud (Isapre o Fonasa). Por lo tanto consideramos que la cobertura es de al menos un 50% y si esta es mayor, se considerará la bonificación mayor.',
  alert:
    '* BMI aplica para todos todos los gastos médicos, exceptuando medicamentos ambulatorios y ópticas, y tratamientos psiquiátricos y/o psicológicos.',
  cards: [
    {
      title:
        'Si la Isapre o Fonasa te cubre el <b> 50% o más</b> del costo de la prestación de salud',
      subtitle:
        'El seguro se aplicará sobre el monto de la prestación no cubierta por la Isapre o Fonasa.',
    },
    {
      title:
        'Si la Isapre o Fonasa te cubre <b> menos del 50%</b> del costo de la prestación de salud',
      subtitle: 'El seguro se aplicará al 50% del valor real de la prestación.',
    },
  ],
};

export const helpDeskTexts = {
  headerTitle: '| Centro de ayuda',
  home: {
    title: 'Hola! ¿En qué podemos ayudarte?',
    bannerTitle: '¿Quieres comunicarte con nosotros?',
    bannerDescription: 'Nuestro servicio al cliente está para ayudarte',
    bannerBtn: 'Contáctanos',
  },
  contact: {
    breadcrumb: 'Contacto',
    title: 'Comunícate con nosotros',
    description: 'Responderemos todas tus preguntas.',
    email: {
      title: 'Correo electrónico',
      description:
        'Envíanos un correo a <a href="mailto:scliente@bicevida.cl">scliente@bicevida.cl</a>',
      icon: ICON_EMAIL,
    },
    phone: {
      title: 'Telefónicamente',
      description:
        'Puedes comunicarte con nuestro equipo llamando al <a href="tel:800202022">800 2020 22</a>',
      icon: ICON_PHONE,
    },
  },
  search: {
    title: 'Resultado de la búsqueda',
    isData: (searchLength: number, search: string) =>
      `${searchLength} resultado${
        searchLength > 1 ? 's' : ''
      } para "${search}" en todas las categorías`,
    isDataSpecific: (searchLength: number, search: string, category: string) =>
      `${searchLength} resultado${searchLength > 1 ? 's' : ''} para "${search}" en ${category}`,
    noData: (
      search: string
    ) => `No encontramos resultados para "${search}". Intenta buscando por otra palabra
        `,
    noURL: 'No encontramos resultados para tu búsqueda',
    lateral: {
      title: 'Por categoría',
      first: (optionsLength: number) => `Todas las categorías (${optionsLength})`,
    },
  },
};
export const useFulQuestionText = {
  title: '¿Te pareció útil esta respuesta?',
  usefulYes: '¡Sí, gracias!',
  usefulNo: 'No mucho',
};

export const modalNoUseFulText = {
  icon: KEEP_IMPROVING_MODAL,
  title: '¡Queremos seguir mejorando!',
  subtitle: 'Por favor dinos porque no te pareció útil la respuesta',
  buttonText: 'Enviar motivo',
  selectPlaceholder: 'Motivo',
  selectOptions: [
    {
      reasonId: '1',
      reasonDescription: 'La respuesta no fue clara',
    },
    {
      reasonId: '2',
      reasonDescription: 'La respuesta fue incompleta',
    },
    {
      reasonId: '3',
      reasonDescription: 'No era lo que estaba buscando',
    },
  ],
};

export const feedbackThanksText = {
  icon: FEEDBACK_THANKS_MODAL,
  title: 'Muchas gracias por tu opinión',
  subtitle: 'Trabajaremos para darte una mejor respuesta',
  actionText: 'Entendido',
};

export const topicSuggestBannerText = {
  question: '¿Tienes más preguntas?',
  link: 'Envía una solicitud',
};

export const plansFilterText = {
  premium: {
    type: 'premium',
    description: 'Pyme Premium',
    base: 'premium',
  },
  standard: {
    type: 'standard',
    description: 'Pyme Estándar',
    base: 'standard',
  },
  elite: {
    type: 'elite',
    description: 'Pyme Elite',
    base: 'elite',
  },
};

export const loadDistributionText = {
  countLoad: (worker: number, load: string) => `${worker} trabajadores ${load}`,
  title: 'Distribución de cargas cotizadas',
  totalLoadText: 'Total con cargas',
  buttonTxt: 'Volver',
};

export const upgradePlanTexts = {
  title: (price: string, toPremium: boolean) =>
    `Mejora tu plan cotizado ${toPremium ? 'a Premium' : ''} por <span>${price}</span>`,
  subtitle: 'Tus trabajadores lo agradecerán',
  alert: (email: string) =>
    `Si aceptas esta mejora, una nueva cotización será enviada a tu correo electrónico ${email}`,
  btnAction: 'Sí, me gusta',
  btnCancel: 'No, gracias',
  coveragesHeader: 'Coberturas',
  coveragesDescriptions: [
    {
      text: 'Seguro Catastrófico',
      shouldShow: true,
    },
    {
      text: 'Salud ambulatoria',
      shouldShow: true,
    },
    {
      text: 'Salud hospitalaria',
      shouldShow: true,
    },
    {
      text: 'Seguro Dental',
      shouldShow: true,
    },
    {
      text: 'Tope anual en salud',
      shouldShow: true,
    },
    {
      text: 'Seguro de vida',
      shouldShow: true,
    },
  ],
  planResume: 'Total Mensual',
  upgradingModal: {
    loadingText: 'Estamos actualizando tu cotización',
    successTitle: '¡Cotización actualizada!',
    successSubtitle: (planName: string, email: string) =>
      `Hemos mejorado tu plan cotizado a <b>${planName}</b>.  Una nueva cotización será enviada a tu correo electrónico ${email}`,
    btnAction: 'Volver',
    errorTitle: 'Tuvimos un problema al actualizar tu cotización',
    errorSubtitle: 'Por favor vuelve a intentarlo en unos minutos.',
  },
  currentBadge: {
    background: 'EBEFF5',
    text: 'COTIZADO',
    color: '58606E',
  },
  improvedBadge: {
    background: '54CA5C',
    text: 'RECOMENDADO',
    color: 'FFFFFF',
  },
};

export const metaDescription =
  'Cotiza ahora tu seguro colectivo para Pymes con BICE VIDA. Nuestros planes incluyen cobertura de vida, salud, dental y catastrófica.';

export const overviewText = {
  title: 'Revisa el progreso de las empresas',
  inProcess: {
    title: 'Empresas en proceso',
    iconTitle: 'En proceso',
    iconTextBody: 'Empresas que están en proceso de llenado del formulario de incorporación.',
  },
  haveSigned: {
    title: 'Empresas han firmado',
    iconTitle: 'Firmado',
    iconTextBody: 'Empresas que ya completaron y firmaron el formulario de incorporación.',
  },
};

export const overviewPanelText = {
  title: 'No hemos encontrado información',
  executiveMailLabel: 'Correo ejecutiva:',
  subtitle:
    'Aquí podrás revisar quienes de las empresas que cotizaron, han completado la solicitud de incorporación ',
  searchBarPlaceholder: '¿Qué estás buscando?',
  downloadExcelBtn: 'Descargar datos empresa',
  incorporationsDetailBtn: 'Ver formularios de incorporación',
  documentsRefBtn: 'Ver documentos de cierre',
  newEstimateBtn: 'Generar nueva cotización',
  excelFormat: {
    title: 'ANTECEDENTES PARA LA EMISIÓN DE NUEVA POLIZA',
    companyRut: 'RUT Contratante',
    companyName: 'Nombre o Razón Social Contratante',
    activity: 'Giro',
    address: 'Dirección',
    region: 'Ciudad',
    district: 'Comuna',
    companyPhone: 'Teléfono',
    adminName: 'Nombre Encargado',
    adminPosition: 'Cargo Encargado',
    adminEmail: 'Mail Contacto encargado',
    adminPhone: 'N° Contacto encargado',
    adminRut: 'Rut encargado',
    contribution: '100%',
    contributionLabel:
      'Contributoriedad (Forma Pago de Prima, indicar participación entre contratante y empleado).',
  },
  seeProgress: 'Ver avance',
  dropdown: {
    texts: {
      seeForms: 'Ver formularios de incorporación',
      generateNewQuotation: 'Generar nueva cotización',
      shareHiringLink: 'Compartir enlace de contratación',
      ShareIncorporationLink: 'Compartir enlace de incorporación',
    },
    icons: {
      eye: 'remove_red_eye',
      list: 'list_alt',
      link: 'link',
    },
    themes: {
      primary: 'primary',
    },
  },
  hiringProgressText: {
    [StepsEnum.COMPANY_NAME]: {
      icon: 'domain',
      text: 'Razón social adicional',
    },
    [StepsEnum.PYME_DATA]: {
      icon: 'dns',
      text: 'Datos de empresa',
    },
    [StepsEnum.INCORPORATION_FORM]: {
      icon: 'assignment',
      text: 'Completa el formulario',
    },
    [StepsEnum.SHARE_INCORPORATION]: {
      icon: 'share',
      text: 'Comparte el formulario',
    },
    [StepsEnum.CHECK_INCORPORATION]: {
      icon: 'people',
      text: (signed: number) =>
        signed === 1 ? `${signed} formulario firmado` : `${signed} formularios firmados`,
    },
  },
};

export const preLeadText = {
  personalInfoStep: 'Paso Información Personal',
  emailStep: 'Paso Email',
  workersStep: 'Paso Cantidad de Trabajadores',
  planStep: 'Paso Selección de Planes',
  coverageStep: 'Paso Selección de Coberturas',
  variantStep: 'Paso Distribución de Cargas',
  formStep: 'Paso Formulario',
};

export const authenticationTexts = {
  title: 'Hola!',
  subtitle: 'Ingresa para ver cómo van tus leads',
  buttonText: 'Iniciar sesión',
  warning: 'Tu correo electrónico y contraseña no coinciden, inténtalo nuevamente.',
};

export const leadManagerTexts = {
  greeting: (name: string) => `Hola ${name}!`,
  leadsAndCompanies: (leads: number, companies: any) => {
    let text = '';

    text += `${leads} ${leads !== 1 ? 'Leads nuevos ' : 'Lead nuevo '} `;
    text += `| ${companies.inProcess} empresa${companies.inProcess !== 1 ? 's' : ''} en proceso, ${
      companies.signed
    } ha${companies.signed !== 1 ? 'n' : ''} firmado`;

    return text;
  },
  downloadBtn: 'Descargar CSV',
  searchBarPlaceholder: '¿Qué estás buscando?',
  filterSearch: ['Cotización', 'Contratación'],
  ordering: {
    ufa: 'UFA',
    createdAt: 'Fecha de cotización',
  },
  tabs: {
    leads: {
      id: '1',
      text: 'Cotización',
    },
    general: {
      id: '2',
      text: 'Contratación',
    },
    quote: {
      id: '3',
      text: 'Generar nueva cotización',
    },
  },
  leadListDateFormat: (date?: Date | string) =>
    `Fecha cotización ${formatIncorporationDate(date, true)}`,
  leadListCardTooltip: 'Ir al lead',
};

export const textLeadManagerDetail = {
  titleContributorData: 'Datos cotizante',
  titleQuoteDetail: 'Detalles cotización',
  titleCompanyDetail: 'Datos Empresa',
  titleAdminData: 'Datos administrador',
  titleDocuments: 'Documentos de cierre',
  labelAdmin: {
    fullnameAdmin: 'Nombre',
    emailAdmin: 'Correo electrónico',
    phoneAdmin: 'Teléfono',
    positionAdmin: 'Cargo',
    rutAdmin: 'RUT',
  },
  labelContributor: {
    fullname: 'Nombre',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    position: 'Cargo',
  },
  labelCompany: {
    companyName: 'Razón social',
    companyRut: 'RUT',
    activity: 'Giro',
    phone: 'Teléfono',
    region: 'Región',
    district: 'Comuna',
    address: 'Dirección',
  },
  labelQuotation: {
    totalQuoted: 'Prima base mensual',
    totalVariant: 'Prima cargas',
    totalCompanyUf: 'Total mensual cotizado',
  },
  labelPlanInfo: {
    bussines: 'Fuente de la cotización',
    quotedPlan: 'Plan Cotizado',
    workers: 'Nº total de trabajadores',
  },
  labelDocumentCard: {
    companyData: 'Datos de empresa',
    releaseForm: 'Formulario de emisión',
    generateBtn: 'Generar y descargar',
    incorporationForm: 'Formularios de incorporación',
    incorporationZip: 'Descargar formularios de incorporación',
  },
  loadDistribution: 'Cargas',
  leadDetailtDateFormat: (date?: Date | string) =>
    `Fecha cotización ${formatIncorporationDate(date, true)}`,
  hiringDetailtDateFormat: (date?: Date | string) =>
    `Inicio Contratación ${formatStartHiringDate(date, true)}`,
  hiringUpdatedDateFormat: (date?: Date | string) =>
    `Últ. actualización ${formatStartHiringDate(date, true)}`,
  btnShare: 'Link contratación',
  btnEstimation: 'Actualizar cotización',
  withoutLoads: 'Sin carga',
  withLoads: 'Con cargas',
  withLoadsDistributed: 'Con cargas distribuidas',
  hiringStatusLabel: 'Contratación',
  modalShareTexts: {
    hiring: {
      shareLink: 'Compartir enlace de contratación',
      titlePrincipal: 'Comparte el enlace de la contratación',
      titleSecondary: (name?: string) =>
        `Copia y envía este enlace al cotizante para que continúe con la contratación de la empresa ${
          name ?? ''
        }`,
      link: (lead: string, client?: string) =>
        `${process.env.baseUrl}/contratacion/inicio?lead=${lead}&client=${client}&tab=steps`,
    },
    incorporation: {
      shareLink: 'Compartir enlace de incorporación',
      titlePrincipal: 'Comparte el enlace de la incorporación',
      titleSecondary:
        'Copia y envía este enlace al cotizante para que continúe con la incorporación',
      link: (lead: string) => `${process.env.baseUrl}/incorporacion?lead=${lead}`,
    },
  },
  titleTooltip: 'Distribución de cargas',
};

export const cookiesText = {
  description:
    'Utilizamos cookies propias y de terceros para mejorar la experiencia del usuario a través de tu navegación. Si continúas navegando aceptas su uso. Puedes revisar nuestra  <a href="https://www.bicevida.cl/seguridad/privacidad-y-uso-de-cookies/" target="_blank">política de cookies</a>',
};

export const leadManagerEstimateTexts = {
  title: 'Generar cotización',
  workers: {
    index: 1,
    title: 'Número de trabajadores',
    label: 'Número de trabajadores',
  },
  plans: {
    index: 2,
    title: 'Selecciona planes',
  },
  coverages: {
    index: 3,
    title: 'Selecciona coberturas',
  },
  variants: {
    index: 4,
    title: 'Distribuye cargas',
    counter: (workers: number) => `trabajador${workers === 1 ? '' : 'es'}`,
  },
  valueCLP: (value: number, UF: number) =>
    `${FORMATERS.formatCLP(value * UF)} / ${FORMATERS.formatUF(value)} mensual por trabajador`,
  review: {
    empty: 'Para visualizar tu resumen de cotización, comienza ingresando algún dato.',
    header: 'Resumen de cotización',
    total: 'Total mensual',
    amount: (totalCLP: number, totalUF: number) =>
      `<b>${FORMATERS.formatCLP(totalCLP)}</b> <span>/ ${FORMATERS.formatUF(totalUF)}</span>`,
    workersLabel: 'Número de trabajadores',
    prePlanLabel: 'Plan seleccionado',
    coveragesLabel: 'Coberturas',
    variantsLabel: 'Distribución de cargas',
    variantMoreLabel: (description: string) =>
      description.toLowerCase().includes('con cargas')
        ? 'con 2 o más cargas'
        : description.toLowerCase(),
  },
  estimateBtn: 'Generar cotización',
  warningUF: (valueUf: string, today: string) =>
    `Montos en pesos al día de hoy. Pueden cambiar según la variación de la UF. Valor UF: ${valueUf} al ${today}. Montos con IVA incluido.`,
};

export const cyberReviewText = {
  discount: (plan: string) => {
    const discount = plan === 'standard' ? '30' : plan === 'premium' ? '40' : '50';

    return (
      <>
        <strong>Te llevas un {discount}% de descuento</strong> en la facturación del tercer mes de
        tu seguro.
      </>
    );
  },
};

export const reQuote = {
  title: 'Simula una nueva cotización',
  quoteStepsTitles: [
    {
      title: 'Número de trabajadores',
    },
    {
      title: 'Selecciona planes',
    },
    {
      title: 'Selecciona coberturas',
    },
    {
      title: 'Distribuye cargas',
    },
  ],

  modal: {
    error: {
      title: 'Tuvimos un problema al generar la cotización',
      message: 'Por favor vuelve a intentarlo en unos minutos.',
      button: 'Volver',
    },
    success: {
      title: '¡Cotización actualizada!',
      message: 'Te redireccionaremos nuevamente al <br/><span>Resumen del plan a contratar</span>',
      button: 'Aceptar',
      icon: 'check',
    },
  },
  button: 'Actualizar cotización',
};

export const headerSections = [
  {
    label: 'Inicio',
    link: routes.landing,
    badge: false,
    id: 'landing',
  },
  {
    label: 'Planes',
    link: routes.pricesAndPlans,
    badge: false,
    id: 'pricesAndPlans',
  },
  {
    label: 'Coberturas',
    link: routes.coverages,
    badge: false,
    id: 'coverages',
  },
  {
    label: 'Beneficios',
    link: routes.benefits,
    badge: false,
    id: 'benefits',
  },
  {
    label: 'Centro de ayuda',
    link: routes.helpDesk,
    badge: false,
    id: 'helpDesk',
  },
];

export const landingText = {
  hero: {
    pill: 'Mi Pyme Segura',
    title: (
      <>
        Seguro Colectivo de salud y vida <span> para pymes </span>
      </>
    ),
    subtitles: [
      <>
        Descubre nuestros planes, coberturas y beneficios y elige lo mejor para ti y tus
        trabajadores.
      </>,
      <>
        <b> Cotiza y contrata en línea, </b> con asistencia personalizada.
      </>,
    ],

    subtitleSpan: `Pymes desde 5 trabajadores`,
    infoTitle: 'La salud y tranquilidad de tus trabajadores nos importa',
    infoList: [
      'Incentivamos el compromiso y fidelidad de tus trabajadores con tu Pyme.',
      'Resguardamos a tus trabajadores ante cualquier accidente laboral y protegemos su salud.',
      'Atrae más talentos, sabemos que los trabajadores valoran estar protegidos.',
    ],
    button: 'Cotiza ahora',
    buttonEstimateRecover: 'Retomar cotización',
    modal: {
      title: '¿Qué requisitos debo cumplir para contratar?',
      body: (
        <>
          <ul>
            <li>Tener 5 o más trabajadores (incluye socios y rep. legal)</li>
            <li>Que estén afiliados a isapre o Fonasa.</li>
            <li>Que tengan una edad máxima de 64 años y 364 días.</li>
          </ul>
        </>
      ),
      alert: (
        <p>
          Si tienes menos de 5 trabajadores te recomendamos{' '}
          <a target="_blank" href={bicevidaURLWindowIndividualInsurance} rel="noreferrer">
            Seguro Complementario de Salud Individual
          </a>
        </p>
      ),
      button: 'Entendido',
    },
  },
  features: {
    title: '¿Qué te ofrecemos?',
    subtitle:
      'Te entregamos coberturas de salud y vida, junto con beneficios y convenios para cuidar a tu equipo.',
    button: 'Ver detalles del seguro',
    cards: [
      {
        icon: featuresIcon.heart,
        title: 'Salud + Vida',
        paragraph:
          'Todos nuestros planes incluyen coberturas de salud y vida para proteger a tu equipo.',
      },
      {
        icon: featuresIcon.cross,
        title: 'Coberturas adicionales',
        paragraph: 'Puedes agregar Seguro Dental y/o Catastrófico para aumentar el bienestar.',
      },
      {
        icon: featuresIcon.notes,
        title: 'Elige dónde atenderte',
        paragraph:
          'Libre elección para que tú y tus trabajadores decidan dónde y con quién atenderse.',
      },
      {
        icon: featuresIcon.telemedicine,
        title: 'Telemedicina gratis',
        paragraph: 'Acceso ilimitado para medicina general y de especialidad.',
      },
      {
        icon: featuresIcon.pills,
        title: 'Convenios en farmacias',
        paragraph:
          'Tenemos convenios con más de 80 farmacias, para que accedas a los mejores precios.',
      },
      {
        icon: featuresIcon.money,
        title: 'Reembolsos rápidos',
        paragraph: 'Mediante I-MED, Sucursal Virtual, Aplicación móvil y Whatsapp.',
      },
    ],
  },
  coverages: {
    title: (mobile: boolean) =>
      mobile ? (
        <>
          <span>Coberturas adicionales</span> para cuidar a tu equipo
        </>
      ) : (
        <>
          <span>Aumenta la protección</span> de tu equipo con nuestras coberturas adicionales
        </>
      ),
    subtitle: (
      <>
        Si quieres una <strong>mayor protección para tu equipo,</strong> puedes agregar coberturas
        adicionales pagando un monto extra.
      </>
    ),
    button: 'Ver todas las coberturas',
    warningUF: (valueUf: string, today: string) =>
      `Montos en pesos al día de hoy. Pueden cambiar según la variación de la UF. Valor UF: ${valueUf} al ${today}. Montos con IVA incluido.`,
    cards: [
      {
        id: '5e4afcf3bc7b2e44b63dea59',
        icon: coveragesIcon.tooth,
        title: 'Seguro Dental',
        subtitle: (uf: React.ReactNode) => (
          <>
            Desde <>{uf}</> por trabajador.
          </>
        ),
        description:
          'Cobertura de gastos dentales en más de 15 prestaciones, incluyendo consultas y cirugías.',
        hash: 'segurodental',
      },
      {
        id: '5f871dfbffd98b7b3df51a88',
        icon: coveragesIcon.ambulance,
        title: 'Seguro Catastrófico',
        subtitle: (uf: React.ReactNode) => (
          <>
            Desde <>{uf}</> por trabajador.
          </>
        ),
        description:
          'Cobertura de gastos médicos adicionales que se activa al llegar al tope de tu seguro de salud.',
        hash: 'segurocatastrofico',
      },
    ],
  },
  availablePlans: {
    title: (
      <>
        Planes de salud y vida para <span>pymes con 5 o más trabajadores</span>
      </>
    ),

    subtitle: 'Con todos nuestros planes tienes la posibilidad de agregar las cargas que desees.',
    cardDescription: 'Seguro de Salud + Seguro de Vida',
    priceDescription: (value: number, UF: number) =>
      `<span>Desde</span> ${formatUF(value)}  <strong>/  ${FORMATERS.formatCLP(
        value * UF
      )}</strong>`,
    monthlyDescription: 'Mensual por trabajador',
    legend: 'Adicionalmente, podrás complementar tu plan con un seguro dental y/o catastrófico.',
    buttonDetail: 'Ver detalle de planes',
    buttonNow: 'Cotiza ahora',
  },
  benefits: {
    title: ['Descubre un mundo ', 'de beneficios para tus trabajadores'],
    mobileTitle: 'Un mundo ',
    subtitle:
      'Nuestro compromiso es ofrecer beneficios de salud, vida y bienestar que tus trabajadores puedan aprovechar en todo momento.',
    button: 'Ver todos los beneficios',
    cards: [
      {
        src: BENEFITS_IMAGES.TELEMEDICINE,
        colorBg: '#EBFBE2',
        colorText: '#256A31',
        textBadge: ['Todos los planes', 'Atención inmediata'],
        title: 'Telemedicina a costo $0',
        descriptions: [
          'Medicina general ilimitada y de atención inmediata.',
          'Medicina de especialidad ilimitada, con agendamiento.',
        ],
      },
      {
        src: BENEFITS_IMAGES.DRUGSTORE,
        colorBg: '#EEDEFF',
        colorText: '#7631BB',
        textBadge: ['Todos los planes', 'Reembolso fácil'],
        title: 'Convenios con farmacias',
        descriptions: [
          'Descuentos en Farmacias Salcobrand, Cruz Verde y Ahumada.',
          'Más de 80 farmacias adheridas a nuestro rembolso en línea.',
        ],
      },
      {
        src: BENEFITS_IMAGES.REFUND,
        colorBg: '#F1F6FE',
        colorText: '#1F61F7',
        textBadge: ['Fácil y en línea', 'Sin tope de monto'],
        title: 'Reembolso fácil y rápido',
        descriptions: [
          'Solicítalos en nuestra Sucursal Virtual, App móvil y Whatsapp.',
          'Reembolsa directamente con huella imed.',
        ],
      },
      {
        src: BENEFITS_IMAGES.HOSPITAL_COVERAGE,
        colorBg: '#EBFBE2',
        colorText: '#256A31',
        textBadge: ['A nivel nacional', 'Atención preferente'],
        title: 'Coberturas hospitalarias',
        descriptions: [
          'Hasta 100% de cobertura hospitalaria en más de 20 prestadores preferentes.',
          'Con un tope anual de UF 300.',
        ],
      },
      {
        src: BENEFITS_IMAGES.DIGITAL,
        colorBg: '#EEDEFF',
        colorText: '#7631BB',
        textBadge: ['Salud y bienestar', 'Acompañamiento'],
        title: 'Ecosistema de Salud Digital',
        descriptions: [
          'Plataforma de bienestar integral para el cuidado de la salud.',
          'Programa de seguimiento para acompañar a pacientes crónicos.',
        ],
      },
      {
        src: BENEFITS_IMAGES.CIRCLE,
        colorBg: '#F1F6FE',
        colorText: '#1F61F7',
        textBadge: ['Descuentos en salud', 'Hogar y wellness'],
        title: 'Círculo BICE VIDA',
        descriptions: [
          'Beneficios en atención médica, dental y mucho más.',
          'Descuentos en productos para el hogar y el disfrute del tiempo libre.',
        ],
      },
    ],
  },
  circle: {
    paragraph:
      'Accede a beneficios, convenios y descuentos exclusivos para nuestros clientes en el sitio Círculo BICE VIDA',
    button: 'Ir a Círculo',
  },
  quotation: {
    title: 'Cotiza fácil, rápido y sin papeleo',
    paragraph:
      'Accederás a beneficios, convenios y descuentos exclusivos para nuestros clientes en el sitio Círculo BICE VIDA',
    buttonQuotation: 'Comenzar cotización',
    buttonHire: '¿Qué necesitas para contratar?',
    list: [
      {
        paragraph:
          '<span>Cotiza</span> en minutos y obtén un plan según las necesidades de tu Pyme.',
        button: 'Comenzar cotización',
        link: false,
      },
      {
        paragraph:
          '<span>Contrata 100% en línea</span> desde cualquier lugar y sin evaluación de riesgo.',
        button: '¿Qué necesitas para contratar?',
        link: true,
      },
      {
        paragraph:
          '<span>Incorpora</span> a tus trabajadores con nuestro formulario 100% digital y firma electrónica.',
      },
    ],
    modal: {
      title: '¿Qué necesitas para contratar?',
      description: 'Ten en cuenta cumplir estos requisitos al momento de contratar',
      requirements: [
        {
          title: 'Requisitos Pyme',
          items: [
            'Debes incorporar al 100% de tus trabajadores (incluido tú).',
            'Tu Pyme debe contar con al menos 5 trabajadores.',
          ],
        },
        {
          title: 'Requisitos trabajadores',
          items: [
            'Tener entre 18 y 64 años.',
            'Deben estar afiliados a Fonasa, isapre u otro sistema de salud.',
            'Contar con contrato vigente.',
          ],
        },
        {
          title: 'Requisitos cargas de trabajadores',
          items: [
            'Los hijos e hijas podrán ingresar como carga si tienen menos de 23 años .',
            'El cónyuge o conviviente civil del trabajador podrá ingresar como carga si tiene menos de 64 años.',
          ],
        },
      ],
    },
  },
  whyChoose: {
    title: '¿Por qué elegir nuestro seguro complementario para empresas?',

    paragraphTitle: ' de 450 Pymes nos han valorado',
    paragraphCard: 'Más de 1.500.000 trabajadores protegidos',
    bottomCards: [
      {
        icon: whyChooseIcon.domain,
        paragraph: 'Actualmente más de 2.645 empresas han confiado en nosotros',
        alt: 'Icono de empresa',
      },
      {
        icon: whyChooseIcon.union,
        paragraph: 'Clasificación AA+ de riesgo: Garantía de excelencia en nuestro servicio.',
        alt: 'Icono de Clasificación AA+',
      },
      {
        icon: whyChooseIcon.place,
        paragraph: 'Contamos con 19 sucursales de Arica a Punta Arenas. ',
        alt: 'Icono de sucursales',
      },
    ],
  },
  banner: {
    title: '¿Todavía tienes dudas?',
    description:
      'En nuestro centro de ayuda podrás encontrar más información sobre nuestros planes, condiciones y beneficios.',
    button: 'Ir al Centro de ayuda',
  },
  helpBanner: {
    title: '¿Tienes dudas?',
    body: (
      <>
        En nuestro <strong>Centro de ayuda</strong> encontrarás información sobre contratación,
        condiciones del seguro, reembolsos y mucho más.
      </>
    ),
    button: 'Ir al centro de ayuda',
  },
  pawer: {
    title: '¡Perros y gatos felices! Cuida a tus peludos con nuestro plan Elite',
    body: 'Contrata este plan y obtén beneficios exclusivos para cuidar a tus mascotas y las de tus trabajadores.',
  },
  requirementsToContract: {
    title: ['¿Qué necesitas saber para contratar uno de nuestros ', 'seguros para pyme?'],

    tabs: [
      {
        id: 'conditions',
        text: 'Condiciones de contratación',
        cards: [
          {
            icon: REQUERIMENTS_TO_CONTRACT.folder,
            title: '¿Qué requisitos debe cumplir mi pyme?',
            descriptions: [
              'Tener 5 o más trabajadores.',
              'Que estén afiliados a isapre o Fonasa.',
              'Que tengan una edad máxima de 64 años y 364 días. ',
            ],
          },
          {
            icon: REQUERIMENTS_TO_CONTRACT.like,
            title: 'Trabajadores con contrato y a honorarios',
            descriptions: [
              'Puedes incorporar al seguro a trabajadores con <b>contrato</b> y <b>a honorarios.</b>',
              'Si son trabajadores a honorarios, tendrás que hablar con nuestras ejecutivas para demostrar la relación laboral existente.',
            ],
          },
        ],
      },
      {
        id: 'howToContract',
        text: '¿Cómo contratar?',
        cards: [
          {
            icon: REQUERIMENTS_TO_CONTRACT.wifi,
            title: 'Cotiza y contrata 100% en línea',
            descriptions: [
              `Para cotizar y contratar sin trámites, ingresa a nuestro <a href="${process.env.baseUrl}/cotizacion/numero-trabajadores">cotizador</a> y sigue los pasos.`,
              'Nuestra plataforma te permite contratar de forma autónoma y segura.',
            ],
          },
          {
            icon: REQUERIMENTS_TO_CONTRACT.callcenter,
            title: 'Cotiza en línea y contrata con una ejecutiva',
            descriptions: [
              `Si necesitas ayuda, entra al <a href="${process.env.baseUrl}/cotizacion/numero-trabajadores">cotizador</a> e ingresa tus datos y los de tu empresa.`,
              'Al terminar de cotizar, nuestras ejecutivas recibirán una solicitud y se pondrán en contacto contigo.',
            ],
          },
        ],
        disclaimer: {
          title: '¿Quieres comunicarte con una ejecutiva?',
          description:
            'Encontrarás sus datos de contacto en el <b>correo electrónico que enviamos cuando finalizas tu cotización.</b>',
        },
      },
      {
        id: 'howItWorks',
        text: '¿Cómo funciona?',
        cards: [
          {
            icon: REQUERIMENTS_TO_CONTRACT.heart,
            title: 'Comienza a usar tu seguro',
            descriptions: [
              'Luego de contratar, la póliza entrará en vigencia el primer día del mes, según la fecha de contratación en que decidas comenzar con el seguro.',
              'Desde ese día, tú y tus trabajadores podrán reembolsar gastos en nuestra Sucursal Virtual. ',
            ],
          },
          {
            icon: REQUERIMENTS_TO_CONTRACT.notebook,
            title: 'Administra tu seguro en la Sucursal Virtual',
            descriptions: [
              'Podrás gestionar tu seguro en nuestra Sucursal Virtual.',
              'Desde ahí, también podrás ingresar tus solicitudes para <b>incorporar y excluir a tus trabajadores</b> y sus cargas de manera 100% online.',
            ],
          },
        ],
        disclaimer: {
          title: '¿Quieres saber más sobre el proceso?',
          description: `Explora nuestras <b>secciones de ayuda</b> sobre <a href="${process.env.baseUrl}/centro-de-ayuda/cotizacion-y-contratacion" target="_blank">Cotización y contratación </a> y <a href="${process.env.baseUrl}/centro-de-ayuda/gestion-del-seguro" target="_blank">Gestión del seguro.</a>`,
        },
      },
    ],
  },
};

export const coverages = {
  hero: {
    title: 'Coberturas cuando más lo necesitan',
    text: ' Queremos darle tranquilidad a tus trabajadores con nuestras coberturas de salud y vida, complementando con un seguro dental y catastrófico.',
    btn: 'Cotiza ahora',
  },
  coveragesAccordions: [
    {
      hash: 'segurodesalud' as const,
      title: 'Seguro de Salud',
      img: IMG_COVERAGE_HEALTH,
      imgPosition: 'right',
      alt: 'Cruz de salud',
      isIncludedInAllPlans: true,
      badgeText: 'Incluido en todos los planes',
      badgeIcon: ICON_CHECK,
      slogan: 'Cuidamos la salud de tus trabajadores',
      description:
        'Cubrimos los gastos de salud que tengan que pagar tras la cobertura de Fonasa o isapre.',
      howItWorks: {
        label: '¿Cómo funciona?',
        description1:
          'Reembolsa entre un 50% y 80% (dependiendo del plan) en consultas médicas, exámenes, medicamentos, atención hospitalaria, entre otras coberturas.',
        description2:
          'Este seguro cuenta con un deducible anual desde UF 0,5 por asegurado y considera un tope anual de hasta UF 500 máximo por beneficiario, según el plan escogido.',
      },
      whatItCovers: {
        label: '¿Qué cubre?',
        list: [
          'Gastos de salud ambulatorio como consultas y exámenes.',
          'Gastos hospitalarios.',
          'Maternidad (parto normal o cesárea).',
          'Medicamentos.',
          'Marcos, cristales ópticos y lentes de contacto.',
          'Tratamientos psiquiátricos y/o psicológicos.',
          'Y mucho más!',
        ],
        text: 'Revisa nuestros precios y planes para conocer las bonificaciones y topes. ',
        link: 'Ir a precios y planes',
        opensModal: false,
      },
      whatItDoesNotCover: {
        label: '¿Qué no cubre?',
        text1:
          'Este seguro de salud no cubre los gastos médicos que se hayan producido a consecuencia de:',
        list: [
          'Enfermedades y dolencias preexistentes. Además, el seguro no dará cobertura a tratamientos que estén en curso.',
          'Cirugías y/o tratamientos médicos, estéticos, cosméticos, etc. y otros tratamientos que sean con fines de embellecimiento o por malformaciones congénitas.',
          'Tratamientos por adicción a drogas, alcoholismo o tabaquismo y sus consecuencias.',
          'Tratamiento por Síndrome de Inmunodeficiencia Adquirida - SIDA',
        ],
        text2: 'Puedes revisar el detalle de estas y otras exclusiones en ',
        link: 'póliza del seguro de salud',
        pdfLink: `${process.env.assetsUrl}${PDFS.POLICY}#page=10`,
      },
    },
    {
      hash: 'segurodevida' as const,
      title: 'Seguro de Vida',
      img: IMG_COVERAGE_HEART,
      imgPosition: 'left',
      alt: 'Corazón',
      isIncludedInAllPlans: true,
      badgeText: 'Incluido en todos los planes',
      badgeIcon: ICON_CHECK,
      slogan: 'Protección financiera para momentos difíciles',
      description: 'Resguarda al trabajador en caso de fallecimiento o invalidez accidental',
      howItWorks: {
        label: '¿Cómo funciona?',
        description1:
          'Entregamos un monto fijo de 500 UF al trabajador en caso de invalidez o a su familia en caso de fallecimiento.',
      },
      whatItCovers: {
        label: '¿Qué cubre?',
        list: [
          'UF 500 a la familia o a los beneficiarios en caso de fallecimiento del trabajador.',
          'UF 500 (máximo) al trabajador en caso de invalidez total o parcial tras un accidente.',
        ],
        link: '¿Cómo aplica en caso de invalidez?',
        opensModal: true,
        hasInfo: true,
        infoBanner: 'Esta cobertura es sólo para trabajadores y no para las cargas',
      },
      whatItDoesNotCover: {
        label: '¿Qué no cubre?',
        text1:
          'Este seguro de Vida no cubre el fallecimiento del asegurado ante estas situaciones:',
        list: [
          'Suicidio o muerte producida a consecuencia de lesiones auto inferidas.',
          'Participación del asegurado en cualquier acto delictivo.',
          'Negligencia, imprudencia o culpa grave por parte del asegurado.',
          'Realización de una actividad o deporte riesgoso, aun cuando se practique en forma esporádica.',
        ],
        text2: 'Puedes revisar el detalle de estas y otras exclusiones en ',
        link: 'póliza del seguro de vida',
        pdfLink: `${process.env.assetsUrl}${PDFS.POLICY}#page=1`,
      },
    },
    {
      hash: 'segurodental' as const,
      title: 'Seguro Dental',
      img: IMG_COVERAGE_DENTAL,
      imgPosition: 'right',
      alt: 'Diente',
      isIncludedInAllPlans: false,
      id: plansId.standardDental,
      badgeText: (value: string) => `Adicional desde ${value} por trabajador`,
      slogan: 'Complementa tu plan con nuestra cobertura dental',
      description: 'Reembolso de gastos dentales en más de 15 prestaciones incluyendo cirugías.',
      howItWorks: {
        label: '¿Cómo funciona?',
        description1:
          'Reembolsa entre un 40% y 50% (dependiendo del plan) en consultas dentales, urgencias, entre otras coberturas dentales. Además, considera libre elección de centros dentales.',
        description2:
          'Este seguro cuenta con un deducible anual de UF 0,5 por asegurado y considera un tope máximo anual entre UF 10 y UF 15 por beneficiario dependiendo del plan.',
      },
      whatItCovers: {
        label: '¿Qué cubre?',
        list: [
          'Consulta y cirugía dental.',
          'Consulta de urgencia.',
          'Odontopediatra.',
          'Prevención e higiene.',
          'Ortodoncia.',
          'Prótesis.',
          'Y mucho más...',
        ],
        text: 'Revisa nuestros precios y planes para conocer las bonificaciones y topes.',
        link: 'Ir a precios y planes',
        opensModal: false,
      },
      whatItDoesNotCover: {
        label: '¿Qué no cubre?',
        text1: 'Este seguro dental no cubre los gastos dentales cuando se originen por:',
        list: [
          'Enfermedades y dolencias preexistentes. Además, el seguro no dará cobertura a tratamientos que estén en curso.',
          'El reemplazo de dentadura natural, excepto en el caso en que tales dentaduras sean necesarias para reemplazar piezas dentales extraídas.',
          'Procedimientos de prevención e higiene, salvo los incluidos en la prestación de prevención e higiene.',
          'Compra de medicamentos, exceptuando antibióticos, analgésicos, relajantes musculares y antinflamatorios recetados por el cirujano-dentista exclusivamente para el tratamiento dental.',
        ],
        text2: 'Puedes revisar el detalle de estas y otras exclusiones en ',
        link: 'póliza del seguro dental',
        pdfLink: `${process.env.assetsUrl}${PDFS.POLICY}#page=33`,
      },
    },
    {
      hash: 'segurocatastrofico' as const,
      title: 'Seguro Catastrófico',
      img: IMG_COVERAGE_AMBULANCE,
      imgPosition: 'left',
      alt: 'Ambulancia',
      isIncludedInAllPlans: false,
      id: plansId.standardCatastrophic,
      badgeText: (value: string) => `Adicional desde ${value} por trabajador`,
      slogan: 'Respaldo adicional para tus trabajadores',
      description:
        '¿Tu cobertura de salud no es suficiente? Compleméntala con este respaldo adicional que entrega hasta UF 1.000 más para los gastos de tus trabajadores.',
      howItWorks: {
        label: '¿Cómo funciona?',
        description1:
          'Reembolsa entre un 80% y 100% (dependiendo del plan) de lo que tenga que pagar el trabajador tras la cobertura de su sistema de salud y tras la aplicación del Seguro de Salud.',
        description2:
          'Este seguro cuenta con un deducible anual de UF 300 por asegurado, que corresponde al tope de cobertura del seguro de salud. Además considera un tope anual máximo de UF 1000 por beneficiario.',
      },
      whatItCovers: {
        label: '¿Qué cubre?',
        list: [
          'Gastos de salud ambulatorios como consultas y exámenes.',
          'Gastos en medicamentos.',
          'Gastos hospitalarios.',
          'Ambulancia.',
          'Cobertura en el extranjero.',
          'Y mucho más...',
        ],
        text: 'Revisa nuestros precios y planes para conocer las bonificaciones y topes.',
        link: 'Ir a precios y planes',
        opensModal: false,
      },
      whatItDoesNotCover: {
        label: '¿Qué no cubre?',
        text1:
          'Este seguro catastrófico no cubre los gastos médicos que tengan como causa una enfermedad o lesión y/o gastos que provengan de:',
        list: [
          'Enfermedades y dolencias preexistentes. Además, el seguro no dará cobertura a tratamientos que estén en curso.',
          'Cirugías y/o tratamientos médicos, estéticos, cosméticos, etc. y otros tratamientos que sean con fines de embellecimiento o por malformaciones congénitas.',
          'Tratamientos por adicción a drogas, alcoholismo o tabaquismo y sus consecuencias.',
          'Otras exclusiones se detallan en la cotización.',
        ],
      },
    },
    {
      hash: 'beneficiopawer' as const,
      title: 'Asistencia Pawer para  Mascotas',
      img: IMG_COVERAGE_PAWER,
      imgPosition: 'left',
      alt: 'Ambulancia',
      isIncludedInAllPlans: true,
      id: plansId.standardCatastrophic,
      badgeText: 'Incluido solo en plan elite',
      badgeIcon: ICON_CHECK_CIRCLE,
      slogan: 'Bienestar para perros y gatos',
      description: (
        <>
          <strong>¡Perros y gatos en buenas manos!</strong> Con Pawer te ofrecemos atención
          veterinaria, descuentos y asesorías para el cuidado de tus mascotas.
        </>
      ),
      howItWorks: {
        label: '¿Cómo funciona?',
        description1: (
          <>
            Al contratar nuestro <strong>Plan Elite</strong> , tú y tus trabajadores obtendrán una
            asistencia exclusiva para el <strong>cuidado de perros y gatos,</strong> que considera
            una mascota por titular.
          </>
        ),
        description2:
          'No es un seguro de vida, sino una asistencia complementaria que incluye servicios de atención veterinaria, descuentos, asesorías y más.',
      },
      whatItCovers: (onClick: () => void) => ({
        label: '¿Qué cubre?',
        text1: 'Junto a Pawer te ofrecemos una asistencia que cubre:',
        list: [
          'Ficha médica digital en la App de Pawer con la bitácora médica de tu mascota.',
          'Atención televeterinaria ilimitada.',
          'Servicios a domicilio y etología a precio preferencial.',
          'Desparasitación, vacunas legales y consulta de Control Sano una vez al año.',
          'Orientación telefónica para viajes y procesos legales.',
          'Postura de chip subcutáneo.',
          'Exámenes preventivos.',
          'Cremación.',
          'Baño y peluquería.',
          '¡Y mucho más!',
        ],
        opensModal: false,
        footer: (
          <span>
            Revisa los{' '}
            <span role="button" onClick={onClick} tabIndex={-1}>
              detalles de la asistencia
            </span>{' '}
            para conocer las condiciones y topes del servicio.
          </span>
        ),
      }),
      whatItDoesNotCover: (onClick: () => void) => ({
        label: '¿Qué no cubre?',
        text1: (
          <>
            Esta asistencia no cubre ningún tipo de gastos o servicios para mascotas que{' '}
            <strong>no sean perros o gatos.</strong>
          </>
        ),
        listText: 'Tampoco cubre',
        list: [
          'Gastos de accidentes sufridos por tu mascota.',
          'Responsabilidad civil por daños causados por tu mascota.',
          'Urgencias veterinarias.',
        ],
        footer: (
          <span>
            Revisa los{' '}
            <span role="button" onClick={onClick} tabIndex={-1}>
              detalles de la asistencia
            </span>{' '}
            para conocer las coberturas específicas.
          </span>
        ),
      }),
    },
  ],
  modal: {
    title: '¿Cómo aplica en caso de invalidez?',
    description:
      'En caso de invalidez accidental, se otorgará UF500 al trabajador, equivalente a la perdida total en los siguientes porcentajes:',
    percentages: [
      {
        percentage: 'El 100% ',
        description:
          '- la visión de ambos ojos, o - ambos brazos o ambas manos, o - ambas piernas o ambos pies, o - una mano y un pie.',
      },
      {
        percentage: 'El 50% ',
        description:
          '- la audición completa de ambos oídos, o - un brazo, o - una mano, o - una pierna, o - un pie, o - la visión de un ojo en caso de que el asegurado ya hubiese tenido ceguera total del otro, antes de contratar esta cláusula adicional.',
      },
      {
        percentage: 'El 35% ',
        description:
          '- la visión de un ojo en caso que no existiere ceguera total del otro, antes de contratar esta cláusula adicional.',
      },
      {
        percentage: 'El 25%',
        description:
          'por la pérdida total de: - la audición completa de un oído en caso de que el asegurado ya hubiese tenido sordera total del otro, antes de contratar este adicional.',
      },
      {
        percentage: 'El 20% ',
        description: '- pulgar derecho o izquierdo.',
      },
      {
        percentage: 'El 15% ',
        description: '- índice derecho o izquierdo.',
      },
      {
        percentage: 'El 13% ',
        description: '- la audición completa de un oído.',
      },
      {
        percentage: 'El 5% ',
        description: '- cualquiera de los otros dedos de la mano.',
      },
      {
        percentage: 'El 3% ',
        description: '- un dedo del pie, ortejo.',
      },
    ],
  },
  bannerPrimary: {
    description:
      'Tus trabajadores son tu activo más importante, protégelos hoy con estas increíbles coberturas.',
    btnEstimate: 'Cotiza ahora',
  },
  bannerSecondary: {
    title: 'Revisa en detalle nuestros precios y planes',
    description: 'Encuentra toda la información necesaria y toma la mejor decisión para tu Pyme.',
    btn: 'Ir a precios y planes',
  },
};

export const authorizationHoldingTexts = {
  message: () =>
    `<b>Opcional:</b> Autorizo <a id='holding_authorization_link'> compartir mis datos personales </a> a sociedades relacionadas con BICE VIDA para recibir ofertas de productos y servicios.`,
  modal: {
    title: 'Uso de datos personales en BICE Corp',
    subtitle: 'Junto a BICE Corp queremos estar siempre contigo para ayudarte',
    description:
      'Es por eso que pensamos en productos y servicios perfectos para cada etapa de tu vida, que podrás conocer al dar consentimiento para compartir tus datos de contacto.',
    accordions: [
      {
        title: 'Detalles legales',
        description:
          '<b>Consentimiento voluntario para tratamiento y comunicación de datos personales no sensibles</b><br/><br/>Los datos personales recolectados por BICE VIDA le pertenecen a la persona natural a la que se refieren los datos, esto es, al cliente de BICE VIDA.<br/><br/>Al marcar el recuadro “ACEPTO”, el cliente de BICE VIDA autoriza expresamente, entregando su consentimiento a BICE VIDA, para que pueda recolectar, procesar, almacenar, comunicar, ceder y/o transferir sus datos personales no sensibles a las sociedades relacionadas con BICE VIDA, incluyendo, pero sin limitar a: <b>Banco BICE, BICE Corredores de Seguros, BICE Hipotecaria, Clever by BICE, BICE Inversiones.</b> BICE VIDA no podrá comunicar, ceder y/o transferir los datos recolectados a sociedades diferentes a las indicadas.<br/><br/>Los datos personales no sensibles incorporados en este consentimiento son exclusivamente los siguientes: (i) el ser cliente de BICE VIDA y las características del seguro contratado, de ser el caso, (ii) nombre completo, (iii) datos de contacto (dirección, correo electrónico, números de teléfono) y (iv) número de RUT.<br/><br/>La comunicación, cesión y/o transferencia de los datos de carácter personal no sensibles del cliente de BICE VIDA a las sociedades citadas y relacionadas con BICE VIDA son <b>únicamente para fines comerciales, promocionales y de publicidad</b> de los productos y servicios que ofrecen dichas sociedades.<br/><br/>En dicho sentido, el presente consentimiento permitirá que las sociedades citadas y relacionadas con BICE VIDA puedan contactar al cliente de BICE VIDA y/o enviarle cualquier tipo de información comercial o promocional sobre los productos y servicios ofrecidos al público. Las sociedades relacionadas <b>no podrán utilizar los datos para una finalidad distinta a la señalada</b> precedentemente.',
      },
      {
        title: 'Revocación de consentimiento',
        description:
          'El cliente de BICE VIDA, <b>en cualquier momento, podrá revocar este consentimiento,</b> en caso que haya sido otorgado. Revocación que deberá hacerse por escrito y que no tendrá efecto retroactivo. El cliente de BICE VIDA podrá siempre solicitar a BICE VIDA información respecto de la recolección, procesamiento, almacenaje, comunicación, cesión y/o transferencia de sus datos personales.',
      },
    ],
  },
};

export const pricesAndPlansText = {
  hero: {
    title: 'Planes que incluyen seguros con coberturas de salud y vida',
    text: ' Elige la protección que más se adecúe a las necesidades de tus trabajadores',
    btn: 'Cotiza ahora',
  },
  types: {
    switchToggle: {
      pesos: 'Pesos',
      UF: 'UF',
    },
    cardDescription: 'Seguro de Salud + Seguro de Vida',
    monthlyDescription: 'Mensual por trabajador',
    someCoverages: 'Algunas coberturas',
    buttonDetail: 'Ver más detalles del plan',
    optional: '<span>Opcional</span> Complementa tu plan con cobertura dental y catastrófica.',
    priceDescription: (value: number, UF: number) =>
      `<span>Desde</span> ${formatUF(value)}  <strong>/  ${FORMATERS.formatCLP(
        value * UF
      )}</strong>`,
    plan: 'Plan',
    additionalCoverages: {
      title: 'Coberturas adicionales',
      warningUF: (valueUf: string, today: string) =>
        `Montos en pesos al día de hoy. Pueden cambiar según la variación de la UF. Valor UF: ${valueUf} al ${today}. Montos con IVA incluido.`,
      coverages: [
        {
          id: plansId.standardDental,
          title: 'Seguro Dental',
          monthlyPrice: (price: number) => `Desde <span>${price}</span> mensual por trabajador`,
          button: 'Ver Coberturas',
          link: '/planes-y-precios',
          image: IMG_PRICES_AND_PLANS.DENTAL,
          alt: 'Diente',
          insurance: {
            plan: {},
            coverage: {},
          },
        },
        {
          id: plansId.standardCatastrophic,
          title: 'Seguro Catastrófico',
          monthlyPrice: (price: number) => `Desde <span>${price}</span> mensual por trabajador`,
          button: 'Ver Coberturas',
          link: '/planes-y-precios',
          image: IMG_PRICES_AND_PLANS.AMBULANCE,
          alt: 'Ambulancia',
          insurance: {
            plan: {},
            coverage: {},
          },
        },
      ],
    },
  },
  documents: {
    title: 'Documentos',
    downloadIcon: ICON_DOWNLOAD,
    downloadIconAlt: 'Descargar',
    cards: [
      {
        title: 'Póliza',
        size: '100KB',
        pdf: `${process.env.assetsUrl}${PDFS.POLICY}`,
      },
    ],
  },
  banner: {
    description: 'Elige el plan ideal para tus trabajadores y protégelos al mejor precio.',
    button: 'Cotiza ahora',
  },
};

export const wizardSingleStep = {
  title: 'Generar cotización',
  subtitle: 'Plan Pyme Premium + dental + catastrófico',
  btnSendQuote: 'Enviar cotización',
  variants: [
    {
      description: '',
      monthlyPrice: (price: number) => `Desde <span>${price}</span> mensual por trabajador`,
    },
  ],
  steps: {
    workers: 'Número de trabajadores',
    plans: 'Selecciona planes',
    coverages: 'Agrega coberturas (opcional)',
    variants: 'Cargas por trabajador',
    companyInfo: 'Datos del cotizante',
  },

  modal: {
    title: '¿Quieres enviar esta cotización?',
    description: (name: string, email: string) =>
      `Al confirmar estarás enviando esta cotización a la empresa <span>${name},</span>  al correo electrónico ${email}`,
    btn_cancel: 'Cancelar',
    btn_confirm: 'Confirmar',
  },
  summary: {
    title: 'Tu cotización ha sido enviada',
    emailSubtitle: (emailUser: string) =>
      `Hemos enviado esta cotización a ${emailUser} y a tu correo electrónico.`,
    planSelected: 'Plan cotizado',
    workers: 'Número de trabajadores',
    btnCoverages: 'Ver coberturas',
    titleWorker: 'Número de trabajadores',
    description: (workers: number) => `${workers} trabajadores`,
    valuePerWorker: (value: number | string, ufValue: string) =>
      `${value} / ${ufValue} por trabajador`,
    warningUF: (valueUf: string, today: string) =>
      `Precios de planes con IVA incluído. Valor UF: ${valueUf} al ${today}.`,
    hireBtn: 'Comienza a contratar',
    backBtn: 'Volver a cotizar',
  },
  inputs: {
    workerQuantity: 'Número de trabajadores',
    companyRut: 'RUT empresa',
    companyName: 'Razón social empresa',
    name: 'Nombre',
    lastname: 'Apellidos',
    position: 'Cargo',
    phone: 'Teléfono cotizante',
    email: 'Correo electrónico cotizante',
  },
};
export const modalInformationFormText = {
  iconAlt: 'Formulario de emisión',
  principalText: '¿Quieres descargar el formulario de emisión?',
  secondaryText:
    'Ingresa fechas y seguro de deceso para que puedas generar y descargar el formulario.',
  badgeTitle: 'Fecha de vigencia',
  initialDate: {
    label: 'Fecha inicio de vigencia',
    feedback: 'Olvidaste seleccionar fecha inicio de vigencia',
  },
  acceptationDate: {
    label: 'Fecha de aceptación contratante',
    feedback: 'Olvidaste seleccionar fecha de aceptación contratante',
  },
  receptionDate: {
    label: 'Fecha de recepción antecedentes',
    feedback: 'Olvidaste seleccionar fecha de recepción antecedentes',
  },
  checkboxConditions: 'Quiero agregar el seguro de deceso al formulario. (Opcional)',
  renovation: '¿Es renovación?',
  generatorButton: 'Generar y descargar',
  PDF: {
    title: (isRenovation: boolean) => `Formulario de ${isRenovation ? 'renovación' : 'emisión'}`,
    labelEmissionDate: 'Fecha',
    executiveName: 'Nombre de ejecutivo',
    sections: {
      1: {
        title: '1. Nombre Holding',
      },
      2: {
        title: '2. Vigencia',
        labels: {
          initialDate: 'Inicio:',
          finalDate: 'Término:',
        },
      },
      3: {
        title: '3.- Datos Empleador Póliza',
        labels: {
          name: 'Nombre:',
          rut: 'Rut:',
        },
      },
      4: {
        title: '4.- Datos Contratante Póliza Madre',
        labels: {
          name: 'Nombre:',
          rut: 'Rut:',
          contributority: 'Contributoriedad',
          yes: 'Sí',
          no: 'No',
          percentage: '% de Aporte',
          business: 'Empresa',
          employee: 'Empleado',
        },
      },
      5: {
        title: '5. Datos Intermediario',
        labels: {
          direct: 'Directo',
          company: 'Agente Compañía',
          free: 'Agente Libre',
          broker: 'Corredor',
        },
        broker: {
          name: 'Nombre de Intermediario',
          rut: 'Rut',
          commision: '%',
        },
      },
      6: {
        title: '6. Datos Contacto de la Empresa',
        labels: {
          name: 'Nombre:',
          position: 'Cargo:',
          phone: 'Nº de contacto:',
          email: 'Mail:',
        },
      },
      7: {
        title: '7.- Periodicidad',
        labels: {
          monthly: 'Mensual',
          annual: 'Anual',
          other: 'Otro',
        },
      },
      8: {
        title: '8. Tipo de Pólizas',
        labels: {
          life: 'Vida',
          dental: 'Dental',
          health: 'Salud',
          catastrophic: 'Catastrófico',
          decease: 'Deceso',
        },
      },
      9: {
        title: '9. Tipo de Continuidad por Tipo de Póliza',
        labels: {
          totalContinuity: 'Continuidad Total',
          noContinuity: 'Sin Continuidad',
        },
      },
      10: {
        title: '10. Documentos',
        labels: {
          acceptationDate: 'Fecha Aceptación del Intermediario/Cliente:',
          receptionDate: 'Fecha Recepción todos los Antecedentes:',
        },
      },
      11: {
        title: '11. Datos Facturación y Emisión Póliza Madre',
        labels: {
          activity: 'Giro Comercial / Pólizas y Contratantes Asociados al Holding (Filiales)',
          region: 'Región',
          district: 'Comuna',
          billingAddress: 'Dirección Factura',
          billingMail: 'Mail de envío de Factura Electrónica',
          dispatchAddress: 'Dirección de despacho',
        },
      },
      12: {
        title: '12. Datos Facturación y Emisión Filiales',
        labels: {
          name: 'Nombre de Filial',
          rut: 'RUT',
        },
      },
    },
  },
  modalGeneratedPDF: {
    iconAlt: 'PDF Generado',
    principalText: 'Formulario de emisión descargado',
    secondaryText:
      'Recuerda descargar y enviar a producción todos los documentos de cierre para emitir la póliza y cerrar esta contratación.',
    backButton: 'Volver a contratación',
  },
};

export const promoBanner = {
  suiteFlow: (alternative: boolean) =>
    ` Contrata ahora y según el plan, obtén hasta un <b>${
      alternative ? '50%' : '75%'
    } de cashback</b> en dos meses del primer año de vigencia. `,
  quoteFlow: (alternative: boolean) =>
    ` Cotiza hoy y al contratar obtén hasta <b>${
      alternative ? '50%' : '75%'
    } de cashback</b> en dos meses.`,

  modal: {
    title: 'Detalles de la oferta Nubox',
    btn: 'Entiendo',
    body:
      'Contrata uno de nuestros planes y disfruta de <b>hasta 75% de cashback</b> en dos de tus primas durante tu primer año con nosotros.<br/><br/>' +
      '<b>¿Cómo funciona el cashback?</b><br/>Verás la devolución reflejada en la cuenta bancaria declarada al momento de la contratación, en los primeros 5 días del mes siguiente al pago de tu prima 3 y 6.<br/><br/>' +
      '<b>- Para el Plan Premium: Recibe 50% de devolución</b> en las primas del mes 3 y 6 del primer año.<br/><br/>' +
      '<b>- Para el Plan Elite: Recibe 75% de devolución</b> en las primas del mes 3 y 6 del primer año.',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.PROMO_NUBOX}" target="_blank">  bases legales de la promoción.</a>`,
  },
  cyber: {
    promoTitle: '¡Promo CYBER!',
    modalTitle: 'Detalles de la oferta Cyber Monday',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.CYBER}" target="_blank">  bases legales de la promoción.</a>`,
    suite: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.CYBER}" target="_blank">  bases legales de la promoción.</a>`,
  },

  extends: {
    promoTitle: '¡Extendimos la promo!',
    modalTitle: 'Detalles de la oferta Grandes Promociones',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.POST_CYBER}" target="_blank">  bases legales de la promoción.</a>`,
  },

  nubox: {
    promoTitle: '¡Promo Nubox!',
    modalTitle: 'Detalles de la oferta Nubox',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.PROMO_NUBOX}" target="_blank">  bases legales de la promoción.</a>`,
  },
  chipax: {
    promoTitle: '¡Promo Chipax!',
    modalTitle: 'Detalles de la oferta Chipax',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.PROMO_CHIPAX}" target="_blank">  bases legales de la promoción.</a>`,
  },
  aon: {
    promoTitle: '¡Promo Cyber AON!',
    modalTitle: 'Detalles de la oferta',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.PROMO_AON_CYBER}" target="_blank">  bases legales de la promoción.</a>`,
    body:
      'Contrata nuestro plan Premium y disfruta de un <b>50% de cashback</b> en dos de tus primas durante tu primer año con nosotros.<br/><br/>' +
      '<b>¿Cómo funciona el cashback?</b><br/>Verás la devolución reflejada en la cuenta bancaria declarada al momento de la contratación, en los primeros 5 días del mes siguiente al pago de tu prima 3 y 6.<br/><br/>' +
      '<b>- Para el Plan Premium: Recibe 50% de devolución</b> en las primas del mes 3 y 6 del primer año.<br/><br/>',
  },
};
export const quoteCyberDayText = {
  [IntervalCyber.CyberDay]: {
    promoTitle: '3, 4 Y 5 DE JUNIO',
    quoteFlow: (isMobile: boolean) =>
      isMobile ? (
        <>
          Contrata tu seguro y <span>llévate hasta un 50% dcto</span> en una facturación.
        </>
      ) : (
        <>
          Contrata hoy y <span>llévate hasta un 50% de descuento</span> en una facturación.
        </>
      ),
    modalTitle: 'Detalles de la oferta Cyber Monday',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.CYBER}" target="_blank">  bases legales de la promoción.</a>`,
    suite: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.CYBER}" target="_blank">  bases legales de la promoción.</a>`,
  },
  [IntervalCyber.Extension]: {
    promoTitle: '6 Y 7 DE JUNIO',
    quoteFlow: (isMobile: boolean) =>
      isMobile ? (
        <>
          <b>¡Extendimos!</b> Contrata y <span>obtén hasta 50% de dcto</span> en una facturación.
        </>
      ) : (
        <>
          <b>¡Extendimos la promo!</b> Contrata hoy y <span>llévate hasta un 50% de descuento</span>{' '}
          en una facturación.
        </>
      ),
    modalTitle: 'Detalles de la oferta Grandes Promociones',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.POST_CYBER}" target="_blank">  bases legales de la promoción.</a>`,
  },
  [IntervalCyber.CyberDayLastDay]: {
    promoTitle: '',
    quoteFlow: (isMobile: boolean) =>
      isMobile ? (
        <>
          <b>¡Extendimos!</b> Contrata y <span>obtén hasta 50% de dcto</span> en una facturación.
        </>
      ) : (
        <>
          <b>¡Extendimos la promo!</b> Contrata hoy y <span>llévate hasta un 50% de descuento</span>{' '}
          en una facturación.
        </>
      ),
    modalTitle: 'Detalles de la oferta Grandes Promociones',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.POST_CYBER}" target="_blank">  bases legales de la promoción.</a>`,
  },
};

export const cyberDayModal = {
  title: 'Detalles de la promoción Cyber Safe',
  btn: 'Entiendo',
  body: (
    <>
      Si contratas tu seguro colectivo <b>Mi Pyme Segura</b> durante los días de la promoción,
      obtendrás <b>hasta un 50% de descuento en una prima mensual.</b>
      <br />
      <br />
      <b>¿De cuánto será tu descuento?</b>
      <br />
      Dependerá del plan que contrates:
      <ul>
        <li>
          <b>Plan estándar:</b> 30% de descuento.
        </li>
        <li>
          <b>Plan Premium:</b> 40% de descuento.
        </li>
        <li>
          <b>Plan Elite:</b> 50% de descuento.
        </li>
      </ul>
      <b>¿Quiénes recibirán el descuento?</b>
      <ul>
        <li>
          Las pymes que <b>contraten un seguro durante la promoción.</b>
        </li>
        <li>
          Las pymes que{' '}
          <b>coticen durante la promoción y contraten un seguro en los 60 días siguientes.</b>
        </li>
      </ul>
      <b>¡Y listo!</b> El descuento se aplicará <b>al tercer mes de contratado el seguro.</b>
    </>
  ),
  subFooter: (extend: boolean) => (
    <>
      Para más información, revisa las{' '}
      <a
        href={process.env.assetsUrl + (extend ? PDFS.CYBER_EXTEND : PDFS.CYBER)}
        target="_blank"
        rel="noreferrer"
      >
        bases legales de la promoción.
      </a>
    </>
  ),
};
export const cyberDayText = {
  btn: 'Ver detalles',
  landing:
    "<div>Cotiza y al contratar obtén hasta <br><label>75%</label></div> <div>  en <b>1 gift card</b> <br> <a id='modal_link'> Ver detalles </a></div>",

  landingMobile:
    "<div>Cotiza HOY y llévate hasta <br><label>$200.000</label></div> <div>  en 1 gift card<br> <a id='modal_link'> Ver detalles </a></div>",

  quoteFlow: (workers: number) => {
    const selectedRange = ranges.find((range) => workers >= range.workers);

    if (selectedRange) {
      const formatAmount = formatMoney(selectedRange?.giftCardAmount || 0);

      return `<b>¡Elegiste ${workers} trabajadores!</b> Cotiza hasta el 2 de junio y al contratar <b>llévate 1 gift card de ${formatAmount}. <a id='modal_link'> Ver detalles </a></b>`;
    }

    return "<b>¡Extendimos la promo!</b> Cotiza y al contratar <b>llévate 1 gift card Cencosud de hasta $200.000.</b> <a id='modal_link'> Ver detalles </a>";
  },

  successPage: (workers: number) => {
    const selectedRange = ranges.find((range) => workers >= range.workers);
    const formatAmount = formatMoney(selectedRange?.giftCardAmount || 0);

    return `<b>¡Felicidades!</b> Después de realizar el segundo pago recibirás <b> 1 gift card de ${formatAmount}.</b>`;
  },

  suite: (workers: number) => {
    const selectedRange = ranges.find((range) => workers >= range.workers);

    if (selectedRange) {
      const formatAmount = formatMoney(selectedRange?.giftCardAmount || 0);

      return `<b>Han firmado ${workers} trabajadores!</b> Si cierras tu contratación ahora te llevarás <b> 1 gift card de ${formatAmount}.</b>`;
    }

    return 'Aún no firma el mínimo de trabajadores para que te lleves tu gift card promocional.';
  },

  panel: {
    infoDesk: `Aún no firma el mínimo de trabajadores para que te lleves tu gift card promocional.`,
  },

  modal: {
    title: 'Detalles de la promoción',
    btn: 'Entiendo',
    description:
      'Si cotizas tu Seguro Colectivo Mi Pyme Segura hasta el 2 de junio, te llevarás <b>1 gift card Cencosud de hasta $200.000</b> después de contratar tu seguro.',
    subtitle: '<b>¿Cómo funciona?</b>',

    body: ' El monto dependerá de la cantidad de trabajadores que contrates:<ul><li><b>$100.000</b> de 5 a 9 trabajadores.</li><li><b>$150.000</b> de 10 a 14 trabajadores.</li><li><b>$200.000</b> por 15 o más trabajadores.</li></ul>',
    footer:
      'Recibirás tu gift card cuando realices el segundo pago (mes 2) del producto que contrates.',
    subFooter: `Para más información, revisa las <a href="${process.env.assetsUrl}${PDFS.POST_CYBER}" target="_blank">  bases legales de la promoción.</a>`,
  },
};

export const dateValidationsFeedback = {
  incorporation:
    'Para continuar con este seguro debes tener mínimo 18 años y máximo 64 años y 364 días',
  spouse:
    'Para continuar con este seguro el familiar debe tener mínimo 18 años y máximo 64 años y 364 días',
  otherRelatives:
    'Para continuar con este seguro el familiar debe tener mínimo 14 días y máximo 23 años y 364 días',
  dateEntryJob: 'Ingresaste un rango de fecha invalido',
  invalidDate: 'Debes ingresar una fecha válida',
};

export const modalTokenError = {
  title: 'Tu sesión expiró',
  description: 'Para continuar en el Panel de Contratación, tienes que iniciar sesión nuevamente.',
  button: 'Iniciar sesión',
};

export const availablePlanCard = {
  productName: 'Seguro de Salud + Seguro de Vida',
  priceDescription: (value: number, UF: number) =>
    `<span>Desde</span> ${FORMATERS.formatCLP(value * UF)}<strong>/ ${formatUF(value)}</strong>`,
  monthlyWorkers: 'Mensual por trabajador',
  coveragesTitle: 'Algunas coberturas',
  optional: '<span>Opcional</span> Complementa tu plan con cobertura dental y catastrófica.',
  btn: 'Ver más detalles del plan',
  cyberText: {
    chips: {
      standard: [
        {
          label: '30% dcto',
          icon: 'whatshot',
          background: '4CEC57',
          color: '000000',
          show: true,
        },
        { label: 'CYBER SAFE', icon: '', background: '000000', color: '4CEC57', show: true },
      ],
      premium: [
        {
          label: '40% dcto',
          icon: 'whatshot',
          background: '4CEC57',
          color: '000000',
          show: true,
        },
        { label: 'CYBER SAFE', icon: '', background: '000000', color: '4CEC57', show: true },
      ],
      elite: [
        {
          label: '50% dcto',
          icon: 'whatshot',
          background: '4CEC57',
          color: '000000',
          show: true,
        },
        { label: 'CYBER SAFE', icon: '', background: '000000', color: '4CEC57', show: true },
      ],
    },
    discounts: {
      standard: 'Descuento de 30%',
      premium: 'Descuento de 40%',
      elite: 'Descuento de 50%',
    },
    sinceMonthText: 'En el tercer mes de facturación.',
  },
};
