import { setContext } from '@apollo/client/link/context';
import Cookies from 'universal-cookie';

const getToken = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('x-token');

    return token ? `Bearer ${token}` : '';
  }

  return '';
};

const getAdminToken = () => {
  if (typeof window !== 'undefined') {
    const adminToken = localStorage.getItem('x-admin-token');

    return adminToken ? `Bearer ${adminToken}` : '';
  }

  return '';
};

const getCookieToken = (): string => {
  const cookies = new Cookies();
  const client = cookies.get('x-amzn-client');

  return client ? `Bearer ${client}` : '';
};

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: getToken(),
    'x-admin-token': getAdminToken(),
    'x-amzn-client': getCookieToken(),
  },
}));

export default authLink;
