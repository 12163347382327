import { Reducer } from 'redux';
import {
  // Workers
  SET_WORKERS,
  RESET_WORKERS,

  // Pre Plan
  SET_PRE_PLAN_SELECTED,
  SET_PRE_PLAN_TYPE,
  RESET_PRE_PLAN_SELECTED,

  // Plans
  SET_PLAN_SELECTED,
  RESET_PLAN_SELECTED,

  // Coverages
  SET_DENTAL_COVERAGE,
  SET_CATASTROPHIC_COVERAGE,
  RESET_SELECTED_COVERAGES,

  // Variants
  SET_VARIANT_SELECTED,
  RESET_VARIANT_SELECTED,

  // Step
  SET_CURRENT_STEP,
  RESET_CURRENT_STEP,

  // Form
  SET_FORM_VALIDATION,
  RESET_FORM_VALIDATION,
  SET_USER_DATA,
  RESET_USER_DATA,
  SET_SEND_LEAD,
  RESET_SEND_LEAD,
  SET_FORM_DATA,

  // Wizard
  RESET_WIZARD,
  RECOVER_FROM_STORAGE,
  RESET_EMAIL,
  RESET_COMPANY_DATA,
  SET_EMAIL_VALIDATION,
  RESET_EMAIL_VALIDATION,

  // Personal Info, 1st Step
  SET_PERSONAL_INFO_VALIDATION,
  RESET_PERSONAL_INFO_VALIDATION,
  SET_LOADING_WIZARD,
  SET_TOASTER_WIZARD,
  SET_BUSINESS,
  RESET_BUSINESS,

  // Upselling Plan
  SET_IS_UPSELLING_PLAN,

  // Branding
  SET_BRANDING,
  RESET_BRANDING,

  // Executive
  SET_EXECUTIVE,
  RESET_EXECUTIVE,
} from './actionTypes';

import { wizardPersonalInfoStep, wizardWorkersStep } from '../../utils/constants';
import { PayloadVariant } from '@/src/types/variants';

const initialState = {
  quantity: 0,
  emailValidation: false,
  personalInfoValidation: false,
  prePlanSelected: undefined as any,
  planSelected: undefined as any,
  variantSelected: {
    distributed: false,
    variantId: '',
    selected: [],
  } as PayloadVariant,
  currentStep: wizardWorkersStep,
  formValidation: false,
  userData: {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    companyName: '',
    position: '',
    companyRut: '',
  },
  sendLead: false,
  loading: false,
  toaster: '',
  business: '',
  coveragesSelection: {
    life: true,
    dental: false,
    catastrophic: false,
  },
  prePlanType: '',
  isUpselling: false,
  branding: {
    code: '',
    primary: '',
    character: '',
    logo: '',
    promo: false,
  },
  executive: {
    email: '',
    name: '',
  },
  extraFormData: {
    referCode: undefined as string | undefined,
    assistCode: undefined as string | undefined,
    holdingAuthorization: false,
  },
};

export type State = typeof initialState;

const reducer: Reducer<State> = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    // Workers
    case SET_WORKERS:
      return {
        ...state,
        quantity: Number(payload),
      };

    case RESET_WORKERS:
      return {
        ...state,
        quantity: 0,
      };

    // Pre Plan
    case SET_PRE_PLAN_SELECTED:
      return {
        ...state,
        prePlanSelected: payload,
      };

    case SET_PRE_PLAN_TYPE:
      return {
        ...state,
        prePlanType: payload,
      };

    case RESET_PRE_PLAN_SELECTED:
      return {
        ...state,
        prePlanSelected: undefined,
        prePlanType: '',
      };

    // Plans
    case SET_PLAN_SELECTED:
      return {
        ...state,
        planSelected: payload,
      };

    case RESET_PLAN_SELECTED:
      return {
        ...state,
        planSelected: state.prePlanSelected,
      };

    // Coverages
    case SET_DENTAL_COVERAGE:
      return {
        ...state,
        coveragesSelection: {
          ...state.coveragesSelection,
          dental: payload,
        },
      };

    case SET_CATASTROPHIC_COVERAGE:
      return {
        ...state,
        coveragesSelection: {
          ...state.coveragesSelection,
          catastrophic: payload,
        },
      };

    case RESET_SELECTED_COVERAGES:
      return {
        ...state,
        coveragesSelection: {
          ...state.coveragesSelection,
          dental: false,
          catastrophic: false,
        },
      };

    // Variants
    case SET_VARIANT_SELECTED:
      return {
        ...state,
        variantSelected: payload,
      };

    case RESET_VARIANT_SELECTED:
      return {
        ...state,
        variantSelected: initialState.variantSelected,
      };

    // Step
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload,
      };

    // Step
    case RESET_CURRENT_STEP:
      return {
        ...state,
        currentStep: wizardPersonalInfoStep,
      };

    // Form
    case SET_FORM_VALIDATION:
      return {
        ...state,
        formValidation: payload,
      };

    case SET_FORM_DATA:
      return {
        ...state,
        extraFormData: { ...state.extraFormData, ...payload },
      };

    case RESET_FORM_VALIDATION:
      return {
        ...state,
        formValidation: false,
      };

    case SET_USER_DATA:
      return {
        ...state,
        userData: { ...initialState.userData, ...payload },
      };

    case RESET_USER_DATA:
      return {
        ...state,
        userData: initialState.userData,
      };

    case SET_SEND_LEAD:
      return {
        ...state,
        sendLead: payload,
      };

    case RESET_SEND_LEAD:
      return {
        ...state,
        sendLead: false,
      };

    case RESET_WIZARD:
      return {
        ...state,
        ...initialState,
      };

    case RECOVER_FROM_STORAGE:
      return {
        ...state,
        quantity: Number(payload.workersSelected),
        prePlanSelected: payload.prePlanSelected,
        prePlanType: payload.prePlanType,
        planSelected: payload.planSelected,
        coveragesSelection: payload.coveragesSelection,
        variantSelected: payload.variantSelected,
        currentStep: payload.stepRecovery,
        userData: {
          ...state.userData,
          email: payload.userData.email,
          name: payload.userData.name,
          lastname: payload.userData.lastname,
        },
      };

    case RESET_EMAIL:
      return {
        ...state,
        userData: {
          ...payload,
          email: '',
        },
      };

    case RESET_COMPANY_DATA:
      return {
        ...state,
        userData: {
          ...payload,
          phone: '',
          companyName: '',
          position: '',
          companyRut: '',
        },
      };

    // Email Step
    case SET_EMAIL_VALIDATION:
      return {
        ...state,
        emailValidation: payload,
      };

    case RESET_EMAIL_VALIDATION:
      return {
        ...state,
        emailValidation: initialState.emailValidation,
      };

    // Personal Info Step
    case SET_PERSONAL_INFO_VALIDATION:
      return {
        ...state,
        personalInfoValidation: payload,
      };

    case RESET_PERSONAL_INFO_VALIDATION:
      return {
        ...state,
        personalInfoValidation: initialState.personalInfoValidation,
      };

    case SET_LOADING_WIZARD:
      return {
        ...state,
        loading: payload,
      };

    case SET_TOASTER_WIZARD:
      return {
        ...state,
        toaster: payload,
      };

    case SET_BUSINESS:
      return {
        ...state,
        business: payload,
      };

    case RESET_BUSINESS:
      return {
        ...state,
        business: initialState.business,
      };

    case SET_IS_UPSELLING_PLAN:
      return {
        ...state,
        isUpselling: payload,
      };

    // Branding
    case SET_BRANDING:
      return {
        ...state,
        branding: payload,
      };
    case RESET_BRANDING:
      return {
        ...state,
        branding: initialState.branding,
      };

    // Executive

    case SET_EXECUTIVE:
      return {
        ...state,
        executive: payload,
      };
    case RESET_EXECUTIVE:
      return {
        ...state,
        executive: initialState.executive,
      };

    // Default

    default:
      return state;
  }
};

export default reducer;
