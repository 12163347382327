import { SET_LOGIN_DATA } from './actionTypes';

const initialState: LoginData = {
  leadId: '',
  companyRut: '',
  email: '',
};

const reducer = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_LOGIN_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default reducer;
