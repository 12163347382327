import { AvailablePlans } from '@/src/types/availablePlans';
import { SET_AVAILABLE_PLANS } from './actionTypes';

const initialState = { availablePlans: [] as AvailablePlans };

const reducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SET_AVAILABLE_PLANS:
      return {
        ...state,
        availablePlans: payload,
      };

    default:
      return state;
  }
};

export default reducer;
