'use client';

import { datadogRum } from '@datadog/browser-rum';

const ddApplicationId = process.env.ddApplicationId ?? '';
const ddClientToken = process.env.ddClientToken ?? '';
const isProduction = process.env.stage === 'prod';

export default function DatadogInit(): null {
  if (isProduction) {
    datadogRum.init({
      applicationId: ddApplicationId,
      clientToken: ddClientToken,
      site: 'datadoghq.com',
      service: 'front-colectivos-pyme',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });
  }

  return null;
}
