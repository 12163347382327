import {
  SET_COMPANY_FORM_VALIDATION,
  RESET_COMPANY_FORM_VALIDATION,
  SET_EMAIL_PERSIST,
  RESET_EMAIL_PERSIST,
  SET_COMPANY_DATA,
  RESET_COMPANY_DATA,
  SET_SEND_COMPANY_DATA,
  RESET_SEND_COMPANY_DATA,
  SET_LEAD_DATA,
  RESET_LEAD_DATA,
  SET_LEAD_CREATED,
  RESET_LEAD_CREATED,
  HAS_SUB_COMPANIES,
  SET_SUB_COMPANIES,
  RESET_HIRING_ALL,
  SET_SUB_COMPANIES_VALID,
  SET_SUB_COMPANIES_ENTRY_VALID,
  SET_SELECT_ADMIN_VALIDATION,
  RESET_SELECT_ADMIN_VALIDATION,
  SET_ADMIN_FORM_VALIDATION,
  RESET_ADMIN_FORM_VALIDATION,
  SET_ADMIN_DEFAULT,
  SET_ADMIN_DATA,
  RESET_ADMIN_DATA,
  SET_COMPLETE_COMPANY_DATA,
  RESET_COMPLETE_COMPANY_DATA,
  RESET_IS_INCORPORATION_RANGE,
  SET_IS_INCORPORATION_RANGE,
  SET_FINAL_CONFIRMATION_DATA,
} from './actionTypes';
import { Hiring } from '@/redux/hiring/hiring';

const initialState: Hiring = {
  emailPersist: '',
  leadCreated: '',
  companyFormValidation: false,
  companyData: {
    id: '',
    name: '',
    rut: '',
    phone: '',
    activity: '',
    region: '',
    district: '',
    address: '',
    primary: true,
  },
  hasSubCompanies: undefined,
  subCompanies: [],
  isSubCompaniesValid: false,
  isSubCompaniesEntryValid: false,
  leadData: {
    leadId: '',
    rut: '',
    name: '',
    position: '',
    fullname: '',
    phone: '',
    activities: [],
    createdAt: '',
    stepsHiring: [],
    entryDate: '',
  },
  admin: {
    name: '',
    lastname: '',
    rut: '',
    position: '',
    email: '',
    phone: '',
  },
  sendCompanyData: false,
  selectAdminValidation: false,
  adminFormValidation: false,
  completeCompanyData: false,
  isIncorporationRange: false,
  adminDefault: '',
};

const reducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_COMPANY_FORM_VALIDATION:
      return {
        ...state,
        companyFormValidation: payload,
      };

    case RESET_COMPANY_FORM_VALIDATION:
      return {
        ...state,
        companyFormValidation: false,
      };

    case SET_COMPANY_DATA:
      return {
        ...state,
        companyData: { ...initialState.companyData, ...payload },
      };

    case RESET_COMPANY_DATA:
      return {
        ...state,
        companyData: initialState.companyData,
      };

    case SET_SEND_COMPANY_DATA:
      return {
        ...state,
        sendCompanyData: payload,
      };

    case RESET_SEND_COMPANY_DATA:
      return {
        ...state,
        sendCompanyData: false,
      };

    case SET_EMAIL_PERSIST:
      return {
        ...state,
        emailPersist: payload,
      };

    case RESET_EMAIL_PERSIST:
      return {
        ...state,
        emailPersist: initialState.emailPersist,
      };

    case SET_LEAD_DATA:
      return {
        ...state,
        leadData: { ...initialState.leadData, ...payload },
      };

    case RESET_LEAD_DATA:
      return {
        ...state,
        leadData: initialState.leadData,
      };

    case SET_LEAD_CREATED:
      return {
        ...state,
        leadCreated: payload,
      };

    case RESET_LEAD_CREATED:
      return {
        ...state,
        leadCreated: initialState.leadCreated,
      };

    case HAS_SUB_COMPANIES:
      return {
        ...state,
        hasSubCompanies: payload,
      };

    case SET_SUB_COMPANIES:
      return {
        ...state,
        subCompanies: payload,
      };

    case RESET_HIRING_ALL:
      return {
        ...state,
        companyFormValidation: false,
        sendCompanyData: false,
        isIncorporationRange: false,
        isCompleteCompanyData: false,
        companyData: initialState.companyData,
        leadData: initialState.leadData,
        subCompanies: initialState.subCompanies,
        hasSubCompanies: initialState.hasSubCompanies,
        isSubCompaniesValid: initialState.isSubCompaniesValid,
        isSubCompaniesEntryValid: initialState.isSubCompaniesEntryValid,
      };

    case SET_SUB_COMPANIES_VALID:
      return {
        ...state,
        isSubCompaniesValid: payload,
      };

    case SET_SUB_COMPANIES_ENTRY_VALID:
      return {
        ...state,
        isSubCompaniesEntryValid: payload,
      };

    case SET_SELECT_ADMIN_VALIDATION:
      return {
        ...state,
        selectAdminValidation: payload,
      };

    case RESET_SELECT_ADMIN_VALIDATION:
      return {
        ...state,
        selectAdminValidation: false,
      };

    case SET_ADMIN_FORM_VALIDATION:
      return {
        ...state,
        adminFormValidation: payload,
      };

    case RESET_ADMIN_FORM_VALIDATION:
      return {
        ...state,
        adminFormValidation: false,
      };

    case SET_ADMIN_DEFAULT:
      return {
        ...state,
        adminDefault: payload,
      };

    case SET_ADMIN_DATA:
      return {
        ...state,
        admin: { ...initialState.admin, ...payload },
      };

    case RESET_ADMIN_DATA:
      return {
        ...state,
        admin: initialState.admin,
      };

    case SET_COMPLETE_COMPANY_DATA:
      return {
        ...state,
        completeCompanyData: payload,
      };

    case RESET_COMPLETE_COMPANY_DATA:
      return {
        ...state,
        completeCompanyData: false,
      };

    case SET_IS_INCORPORATION_RANGE:
      return {
        ...state,
        isIncorporationRange: payload,
      };

    case RESET_IS_INCORPORATION_RANGE:
      return {
        ...state,
        isIncorporationRange: false,
      };

    case SET_FINAL_CONFIRMATION_DATA:
      return {
        ...state,
        leadData: { ...state.leadData, ...payload },
      };

    default:
      return state;
  }
};

export default reducer;
