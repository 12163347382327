import Joi from 'joi';
import { sub } from 'date-fns';
import { email as joiEmail } from '@hapi/address';

import { minLimitWorkers, maxLengthNumberAccount, regExpAlphanumeric } from './constants';
import componentsId from './componentsId';

/* Reference
https://github.com/jlobos/rut.js/blob/c29ee603d4dc08cd014def593365dc48758af0e2/index.js#L3-L43
*/

const calculateDate = ({ years, days }: { years: number; days: number }): Date => {
  const dateCalculate = sub(new Date(), {
    years,
    days,
  });

  return dateCalculate;
};

const validateRut = (_rut: string, helpers: any) => {
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(_rut)) {
    return helpers.message(helpers.prefs.messages.validateRut);
  }

  const rut = _rut.replace(/^0+|[^0-9kK]+/g, '').toLowerCase();

  let t = parseInt(rut.slice(0, -1), 10);
  let m = 0;
  let s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }

  const v = s > 0 ? `${s - 1}` : 'k';

  if (v === rut.slice(-1)) {
    return rut;
  }

  return helpers.message(helpers.prefs.messages.validateRut);
};

const validateInputSpaces = (value: string, helpers: any) => {
  if (value.replace(/\s/g, '').length) {
    return value;
  }

  return helpers.message(helpers.prefs.messages.validateSpaces);
};

export const validateRutCompany = (rutCompany: string) => {
  const validRutCompany = rutCompany.replace(/^0+|[^0-9kK]+/g, '').toLowerCase();

  return Number(rutCompany.slice(0, 2)) >= 40 && validRutCompany.length === 9;
};

export const formHireValidationSchema = Joi.object()
  .keys({
    companyName: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el nombre de tu empresa',
      'string.min': 'El nombre no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El nombre no está bien escrito, vuelve a intentarlo',
    }),

    companyRut: Joi.string().min(7).max(12).custom(validateRut).messages({
      'string.empty': 'Olvidaste ingresar el RUT de tu empresa',
      'string.min': 'El RUT no está bien escrito, vuelve a intentarlo',
      validateRut: 'El RUT no está bien escrito, vuelve a intentarlo',
    }),
  })
  .unknown();

export const workersValidation = Joi.object().keys({
  workersValue: Joi.number()
    .integer()
    .greater(minLimitWorkers - 1)
    .less(10000)
    .messages({
      'number.base': 'Olvidaste ingresar el número de trabajadores',
      'number.empty': 'Olvidaste ingresar el número de trabajadores',
      'number.greater': `El mínimo de trabajadores para cotizar es ${minLimitWorkers}`,
      'number.less': 'El máximo de trabajadores para cotizar es 9.999',
    }),
});

export const personalInfoValidation = (assist: boolean) =>
  Joi.object().keys({
    name: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar tu nombre',
      'string.min': 'Tu nombre no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'Tu nombre no está bien escrito, vuelve a intentarlo',
    }),
    lastname: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar tu apellido',
      'string.min': 'Tu apellido no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'Tu apellido no está bien escrito, vuelve a intentarlo',
    }),

    phone: Joi.string().min(10).max(10).messages({
      'string.empty': 'Olvidaste ingresar tu número de teléfono',
      'string.min': 'Ingresa tu número de {#limit - 1} dígitos',
      'string.max': 'Ingresa tu número de {#limit - 1} dígitos',
    }),

    email: Joi.string()
      .email({ tlds: { allow: false } })
      .messages({
        'string.empty': 'Olvidaste ingresar tu correo electrónico',
        'string.email': 'Tu correo no está bien escrito, vuelve a intentarlo',
        validateEmail: 'Tu correo no está bien escrito, vuelve a intentarlo',
      }),

    referCode: Joi.string().min(8).max(12).custom(validateRut).allow('').messages({
      'string.min':
        'Este código no es válido. Por favor ingresa un código de al menos 8 caracteres',
      validateRut:
        'Este código no es válido como referido. Solicita nuevamente el código a la empresa e ingrésalo.',
    }),

    ...(!assist
      ? {
          assistCode: Joi.string().allow(''),
        }
      : {
          assistCode: Joi.string().min(8).max(12).custom(validateRut).messages({
            'string.empty': 'Este rut es necesario',
            'string.min': 'Este rut no es válido.',
            'string.max': 'Este rut no es válido.',
            validateRut: 'Este rut no es válido.',
          }),
        }),
  });

export const companyInfoValidation = Joi.object().keys({
  companyRut: Joi.string().min(7).max(12).custom(validateRut).messages({
    'string.empty': 'Olvidaste ingresar el RUT de tu empresa',
    'string.min': 'El RUT no está bien escrito, vuelve a intentarlo',
    validateRut: 'El RUT no está bien escrito, vuelve a intentarlo',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .custom((value: string, helpers: any) => {
      if (joiEmail.isValid(value)) {
        return value;
      }

      return helpers.message(helpers.prefs.messages.validateEmail);
    })
    .messages({
      'string.empty': 'Olvidaste ingresar tu correo electrónico',
      'string.email': 'Tu correo no está bien escrito, vuelve a intentarlo',
      validateEmail: 'Tu correo no está bien escrito, vuelve a intentarlo',
    }),
});

export const emailValidation = Joi.object().keys({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .custom((value: string, helpers: any) => {
      if (joiEmail.isValid(value)) {
        return value;
      }

      return helpers.message(helpers.prefs.messages.validateEmail);
    })
    .messages({
      'string.empty': 'Olvidaste ingresar tu correo electrónico',
      'string.email': 'Tu correo no está bien escrito, vuelve a intentarlo',
      validateEmail: 'Tu correo no está bien escrito, vuelve a intentarlo',
    }),
  emailConfirm: Joi.string().equal(Joi.ref('email')).messages({
    'string.empty': 'Olvidaste ingresar correo electrónico contratante',
    'string.email': 'El correo no está bien escrito, vuelve a intentarlo',
    validateEmail: 'El correo no está bien escrito, vuelve a intentarlo',
    'any.only': 'Los correos electrónicos deben ser los mismos',
  }),
});

export const riskyActivitiesStepSchema = Joi.object()
  .keys({
    activity: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar un deporte o actividad riesgosa',
      'string.min': 'El deporte o act. no está bien escrito, reinténtalo',
      validateSpaces: 'El deporte o act. no está bien escrito, reinténtalo',
    }),
    frecuency: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar la frecuencia',
      'string.min': 'La frecuencia no está bien escrito, reinténtalo',
      validateSpaces: 'La frecuencia no está bien escrito, reinténtalo',
    }),
  })
  .unknown();

export const insuredPersonalInfoValidation = Joi.object().keys({
  name: Joi.string().min(2).custom(validateInputSpaces).messages({
    'string.empty': 'Olvidaste ingresar tus nombres',
    'string.min': 'Tus nombres no están bien escritos, reinténtalo',
    validateSpaces: 'Tus nombres no están bien escritos, reinténtalo',
  }),
  paternalLastname: Joi.string().min(2).custom(validateInputSpaces).messages({
    'string.empty': 'Olvidaste ingresar tu apellido',
    'string.min': 'Tu apellido no está bien escrito, reinténtalo',
    validateSpaces: 'Tu apellido no está bien escrito, reinténtalo',
  }),
  maternalLastname: Joi.string().allow('').optional().min(2).messages({
    'string.min': 'Tu apellido no está bien escrito, reinténtalo',
  }),
});

export const insuredMoreInfoValidation = Joi.object().keys({
  rut: Joi.string().min(7).max(12).custom(validateRut).messages({
    'string.empty': 'Olvidaste ingresar tu rut',
    'string.min': 'Tu rut no está bien escrito, vuelve a intentarlo',
    'string.max': 'Tu rut no está bien escrito, vuelve a intentarlo',
    validateRut: 'Tu rut no está bien escrito, vuelve a intentarlo',
  }),
});

export const customDate = ({
  min,
  max,
  feedback,
}: {
  min: { years: number; days: number };
  feedback?: string;
  max: { years: number; days: number };
}) =>
  Joi.object().keys({
    date: Joi.date()
      .max(calculateDate(max))
      .min(calculateDate(min))
      .messages({
        'date.max': feedback ?? '',
        'date.min': feedback ?? '',
      }),
  });

export const insuredJobInfoValidation = Joi.object().keys({
  companyName: Joi.string().min(2).custom(validateInputSpaces).messages({
    'string.empty': 'Olvidaste ingresar el nombre de tu empresa',
    'string.min': 'El nombre no está bien escrito, vuelve a intentarlo',
    validateSpaces: 'El nombre no está bien escrito, vuelve a intentarlo',
  }),
  companyRut: Joi.string().min(7).max(12).custom(validateRut).messages({
    'string.empty': 'Olvidaste ingresar el RUT de tu empresa',
    'string.min': 'El RUT no está bien escrito, vuelve a intentarlo',
    'string.max': 'El RUT no está bien escrito, vuelve a intentarlo',
    validateRut: 'El RUT no está bien escrito, vuelve a intentarlo',
  }),
});

export const refundInfoValidation = Joi.object().keys({
  account: Joi.string().trim().min(2).max(maxLengthNumberAccount).messages({
    'string.empty': 'Olvidaste ingresar tu nº de cuenta',
    'string.trim': 'Tu cuenta no está bien escrita, vuelve a intentarlo',
    'string.min': 'Tu cuenta no está bien escrita, vuelve a intentarlo',
    'string.max': 'Tu cuenta no esta bien escrita, vuelve a intentarlo',
  }),
});

export const medicalInfoStepSchema = Joi.object()
  .keys({
    disease: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar una enfermedad o dolencia',
      'string.min': 'La enfermedad no está bien escrita, reinténtalo',
      validateSpaces: 'La enfermedad no está bien escrita, reinténtalo',
    }),
  })
  .unknown();

export const familyGroupStepSchema = Joi.object()
  .keys({
    name: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar los nombres',
      'string.min': 'Los nombres no están bien escritos',
      validateSpaces: 'Los nombres no están bien escritos',
    }),
    paternalLastname: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar tu apellido',
      'string.min': 'Tu apellido no está bien escrito',
      validateSpaces: 'Tu apellido no está bien escrito',
    }),
    maternalLastname: Joi.string().allow('').optional().min(2).messages({
      'string.min': 'Tu apellido no está bien escrito, reinténtalo',
    }),
    rut: Joi.string().min(7).max(12).custom(validateRut).messages({
      'string.empty': 'Olvidaste ingresar el RUT',
      'string.min': 'El rut no está bien escrito, vuelve a intentarlo',
      'string.max': 'El rut no está bien escrito, vuelve a intentarlo',
      validateRut: 'El rut no está bien escrito, vuelve a intentarlo',
    }),
  })
  .unknown();

export const familyMedicalInfoStepSchema = Joi.object()
  .keys({
    disease: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar una enfermedad o dolencia',
      'string.min': 'La enfermedad no está bien escrita, reinténtalo',
      validateSpaces: 'La enfermedad no está bien escrita, reinténtalo',
    }),
    diagnosisDate: Joi.string().messages({
      'string.empty': 'Olvidaste ingresar una fecha de diagnóstico',
    }),
  })
  .unknown();

export const beneficiariesStepSchema = Joi.object()
  .keys({
    name: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar los nombres',
      'string.min': 'Los nombres no están bien escritos',
      validateSpaces: 'Los nombres no están bien escritos',
    }),
    paternalLastname: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el apellido',
      'string.min': 'El apellido no está bien escrito',
      validateSpaces: 'El apellido no está bien escrito',
    }),
    maternalLastname: Joi.string().allow('').optional().min(2).messages({
      'string.min': 'Tu apellido no está bien escrito, reinténtalo',
    }),
    rut: Joi.string().min(7).max(12).custom(validateRut).messages({
      'string.empty': 'Olvidaste ingresar el RUT',
      'string.min': 'El rut no está bien escrito, vuelve a intentarlo',
      'string.max': 'El rut no está bien escrito, vuelve a intentarlo',
      validateRut: 'El rut no está bien escrito, vuelve a intentarlo',
    }),
    relationship: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el parentesco',
      'string.min': 'El parentesco no está bien escrito',
      validateSpaces: 'El parentesco no está bien escrito',
    }),
  })
  .unknown();

export const beneficiariesStepAditionalInfoSchema = Joi.object()
  .keys({
    address: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar la dirección',
      'string.min': 'La dirección no está bien escrita',
      validateSpaces: 'La dirección no está bien escrita',
    }),
    phone: Joi.string().min(9).max(9).messages({
      'string.empty': 'Olvidaste ingresar el número de teléfono',
      'string.min': 'Ingresa el número de {#limit - 1} dígitos',
      'string.max': 'Ingresa el número de {#limit - 1} dígitos',
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .custom((value: string, helpers: any) => {
        if (joiEmail.isValid(value)) {
          return value;
        }

        return helpers.message(helpers.prefs.messages.validateEmail);
      })
      .messages({
        'string.empty': 'Olvidaste ingresar el correo electrónico',
        'string.email': 'El correo no está bien escrito',
        validateEmail: 'El correo no está bien escrito',
      }),
  })
  .unknown();

export const dateDay = (textDate: string) =>
  Joi.object().keys({
    day: Joi.number()
      .greater(0)
      .min(1)
      .max(31)
      .messages({
        'number.greater': `Olvidaste ingresar el día de ${textDate}`,
        'number.min': `Olvidaste ingresar el día de ${textDate}`,
        'number.max': `El dia no está bien escrito, vuelve a intentarlo`,
      }),
  });
export const dateMonth = (textDate: string) =>
  Joi.object().keys({
    month: Joi.number()
      .greater(0)
      .min(1)
      .max(12)
      .messages({
        'number.greater': `Olvidaste ingresar el mes de ${textDate}`,
        'number.min': `Olvidaste ingresar el mes de ${textDate}`,
        'number.max': `El mes no está bien escrito, vuelve a intentarlo`,
      }),
  });

export const dateYear = (textDate: string, maxYear: number) =>
  Joi.object().keys({
    year: Joi.number()
      .greater(0)
      .max(maxYear)
      .messages({
        'number.greater': `Olvidaste ingresar el año de ${textDate}`,
        'number.max': `El año no está bien escrito, vuelve a intentarlo`,
      }),
  });

export const hiringValidationSchema = Joi.object()
  .keys({
    activity: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el giro de la pyme',
      'string.min': 'El giro no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El giro no está bien escrito, vuelve a intentarlo',
    }),
    phone: Joi.string().min(10).max(10).messages({
      'string.empty': 'Olvidaste ingresar el número de teléfono de tu empresa',
      'string.min': 'Por favor ingresa un número de {#limit -1} dígitos',
      'string.max': 'Por favor ingresa un número de {#limit -1} dígitos',
    }),
    address: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar la dirección de tu empresa',
      'string.min': 'La dirección no está bien escrita, vuelve a intentarlo',
      validateSpaces: 'La dirección no está bien escrita, vuelve a intentarlo',
    }),
    streetNumber: Joi.string().min(1).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el número de calle',
      'string.min': 'El número de calle no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El número de calle no está bien escrito, vuelve a intentarlo',
    }),
  })
  .unknown();

export const CompanySchema = Joi.object()
  .keys({
    name: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el nombre de tu empresa',
      'string.min': 'El nombre no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El nombre no está bien escrito, vuelve a intentarlo',
    }),
    rut: Joi.string().min(7).max(12).custom(validateRut).messages({
      'string.empty': 'Olvidaste ingresar el RUT de tu empresa',
      'string.min': 'El RUT no está bien escrito, vuelve a intentarlo',
      'string.max': 'El RUT no está bien escrito, vuelve a intentarlo',
      validateRut: 'El RUT no está bien escrito, vuelve a intentarlo',
    }),
    activity: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar el giro de la pyme',
      'string.min': 'El giro no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El giro no está bien escrito, vuelve a intentarlo',
    }),
    phone: Joi.string().min(10).max(10).messages({
      'string.empty': 'Olvidaste ingresar el número de teléfono de tu empresa',
      'string.min': 'Ingresa tu número de {#limit - 1} dígitos',
      'string.max': 'Ingresa tu número de {#limit - 1} dígitos',
    }),
    address: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar la dirección de tu empresa',
      'string.min': 'La dirección no está bien escrita, vuelve a intentarlo',
      validateSpaces: 'La dirección no está bien escrita, vuelve a intentarlo',
    }),
  })
  .unknown();

export const MultipleReasonsSocialValidation = Joi.object()
  .keys({
    companyName: Joi.string().min(0).messages({
      'string.empty': 'Olvidaste seleccionar tu empleador',
    }),
  })
  .unknown();

export const userAuthenticationchema = Joi.object()
  .keys({
    username: Joi.string()
      .email({ tlds: { allow: false } })
      .custom((value: string, helpers: any) => {
        if (joiEmail.isValid(value)) {
          return value;
        }

        return helpers.message(helpers.prefs.messages.validateEmail);
      })
      .messages({
        'string.empty': 'Olvidaste ingresar el correo electrónico',
        'string.email': 'El correo no está bien escrito',
        validateEmail: 'El correo no está bien escrito',
      }),
    password: Joi.string().min(8).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar tu contraseña',
      'string.min': 'Tu contraseña debe tener al menos 8 caracteres',
      validateSpaces: 'Tu contraseña no está bien escrita',
    }),
  })
  .unknown();

export const adminValidationSchema = Joi.object()
  .keys({
    name: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar un nombre',
      'string.min': 'El nombre no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El nombre no está bien escrito, vuelve a intentarlo',
    }),
    lastname: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar los apellidos',
      'string.min': 'Los apellidos no están bien escritos, vuelve a intentarlo',
      validateSpaces: 'Tu apellido no está bien escrito, vuelve a intentarlo',
    }),
    rut: Joi.string().min(7).max(12).custom(validateRut).messages({
      'string.empty': 'Olvidaste ingresar un RUT',
      'string.min': 'El RUT no está bien escrito, vuelve a intentarlo',
      'string.max': 'El RUT no está bien escrito, vuelve a intentarlo',
      validateRut: 'El RUT no está bien escrito, vuelve a intentarlo',
    }),
    position: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar un cargo',
      'string.min': 'El cargo no está bien escrito, vuelve a intentarlo',
      validateSpaces: 'El cargo no está bien escrito, vuelve a intentarlo',
    }),
    phone: Joi.string().min(9).max(9).messages({
      'string.empty': 'Olvidaste ingresar un número de teléfono',
      'string.min': 'Por favor ingresa un número de {#limit} dígitos',
      'string.max': 'Por favor ingresa un número de {#limit} dígitos',
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .custom((value: string, helpers: any) => {
        if (joiEmail.isValid(value)) {
          return value;
        }

        return helpers.message(helpers.prefs.messages.validateEmail);
      })
      .messages({
        'string.empty': 'Olvidaste ingresar un correo electrónico',
        'string.email': 'El correo no está bien escrito, vuelve a intentarlo',
        validateEmail: 'El correo no está bien escrito, vuelve a intentarlo',
      }),
  })
  .unknown();

export const wizardSingleStepValidation = Joi.object()
  .keys({
    companyRut: Joi.string().min(11).max(12).custom(validateRut).messages({
      'string.empty': 'Olvidaste ingresar el RUT de la empresa',
      'string.min': 'El RUT no está bien escrito, vuelve a intentarlo',
      'string.max': 'El RUT no está bien escrito, vuelve a intentarlo',
      validateRut: 'El RUT no está bien escrito, vuelve a intentarlo',
    }),

    companyName: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar la razón social de la empresa',
      'string.min': 'La razón social no está bien escrita, vuelve a intentarlo',
      validateSpaces: 'La razón social no está bien escrita, vuelve a intentarlo',
    }),

    name: Joi.string().min(3).custom(validateInputSpaces).regex(regExpAlphanumeric).messages({
      'string.empty': 'Olvidaste ingresar el nombre',
      'string.min': 'El nombre no está bien escrito, vuelve a intentarlo',
      'string.pattern.base': 'Ingresa nombre sin caracteres especiales',
      validateSpaces: 'El nombre no está bien escrito, vuelve a intentarlo',
    }),

    lastname: Joi.string().min(2).custom(validateInputSpaces).messages({
      'string.empty': 'Olvidaste ingresar los apellido',
      'string.min': 'Los apellidos no están bien escritos, vuelve a intentarlo',
      validateSpaces: 'Los apellidos no están bien escritos, vuelve a intentarlo',
    }),

    phone: Joi.string().min(10).max(10).messages({
      'string.empty': 'Olvidaste ingresar un número de teléfono',
      'string.min': 'Por favor ingresa un número de {#limit} dígitos',
      'string.max': 'Por favor ingresa un número de {#limit} dígitos',
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .custom((value: string, helpers: any) => {
        if (joiEmail.isValid(value)) {
          return value;
        }

        return helpers.message(helpers.prefs.messages.validateEmail);
      })
      .messages({
        'string.empty': 'Olvidaste ingresar un correo electrónico',
        'string.email': 'El correo no está bien escrito, vuelve a intentarlo',
        validateEmail: 'El correo no está bien escrito, vuelve a intentarlo',
      }),
  })

  .unknown();

export const getFormValidation = (formData: any, formErrors: any, validateFormType: any) => {
  let hasError = false;

  const feedback = Object.keys(formData).reduce((accum: any, key: string) => {
    const datas: any = { ...formData };
    const data: string = datas[key];

    const validation = validateFormType.validate({ [key]: data }, { convert: false });

    if (validation.error) {
      hasError = true;

      return {
        ...accum,
        [key]: {
          hasError: true,
          feedback: validation.error.message,
        },
      };
    }

    return {
      ...accum,
    };
  }, formErrors);

  return hasError ? feedback : null;
};

export const validateActivitySelectionForm = Joi.object().keys({
  [componentsId.activitySelectionFormId.inputs.name]: Joi.string()
    .min(2)
    .custom(validateInputSpaces)
    .messages({
      'string.empty': 'Olvidaste ingresar razón social',
      'string.min': 'La razón social no está bien escrita, vuelve a intentarlo',
      'string.pattern.base': 'Ingresa razón social sin caracteres especiales',
      validateSpaces: 'La razón social no está bien escrita, vuelve a intentarlo',
    }),
  [componentsId.activitySelectionFormId.inputs.activity]: Joi.string()
    .min(2)
    .custom(validateInputSpaces)
    .messages({
      'string.empty': 'Olvidaste ingresar el giro de la pyme',
      'string.min': 'El giro no está bien escrito, vuelve a intentarlo',
      'string.pattern.base': 'Ingresa giro sin caracteres especiales',
      validateSpaces: 'El giro no está bien escrito, vuelve a intentarlo',
    }),
});
