import { createHttpLink } from '@apollo/client';

export const httpLink = (graphqlGateway: any) =>
  createHttpLink({
    uri: graphqlGateway,
    fetchOptions: {
      mode: 'cors',
    },
    fetch,
  });
