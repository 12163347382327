import { SET_QUESTION_FEEDBACK } from './actionTypes';

const initialState: UserFeedback = {
  categories: [],
};

const reducer = (state: UserFeedback = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_QUESTION_FEEDBACK: {
      if (state.categories.length > 0) {
        const existingCategory = state.categories.find(
          (category) => category.id === payload.categoryId
        );

        if (existingCategory !== undefined) {
          let filteredQuestions: FeedbackQuestion[] = [];

          const userQuestion = existingCategory.questions.find(
            (question) => question.id === payload.questionId
          );

          if (userQuestion === undefined) {
            filteredQuestions = [...existingCategory.questions];
          }

          if (userQuestion !== undefined) {
            filteredQuestions = existingCategory.questions.filter(
              (question) => question.id !== userQuestion.id
            );
          }

          const categoryResult: FeedbackQuestionCategory = {
            id: existingCategory.id,
            questions: [
              ...filteredQuestions,
              {
                id: payload.questionId,
                useful: payload.useful,
              },
            ],
          };

          const filteredCategories = state.categories.filter(
            (category) => category.id !== existingCategory.id
          );

          return {
            ...state,
            categories: [...filteredCategories, categoryResult],
          };
        }
      }

      const newCategory: FeedbackQuestionCategory = {
        id: payload.categoryId,
        questions: [{ id: payload.questionId, useful: payload.useful }],
      };

      return {
        ...state,
        categories: [...state.categories, newCategory],
      };
    }

    default:
      return state;
  }
};

export default reducer;
