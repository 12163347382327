import { onError } from '@apollo/client/link/error';

import routes from '../../utils/routes';

const GRAPHQL_ERROR_LIST_USER = ['INVALID_TOKEN'];
const GRAPHQL_ERROR_LIST_ADMIN = ['INVALID_TOKEN_COGNITO'];

interface CustomWindow {
  location: {
    href: any;
  };
}

declare let window: CustomWindow;

const networkErrorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => {
      if (GRAPHQL_ERROR_LIST_USER.includes(message)) {
        window.location.href = process.env.baseUrl;
      }

      if (GRAPHQL_ERROR_LIST_ADMIN.includes(message)) {
        window.location.href = `${process.env.baseUrl}${routes.panel}`;
      }
    });
});

export default networkErrorLink;
