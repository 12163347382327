// Session
import wizardReducer from '../wizard/reducers';
import plansReducer from '../plans/reducers';
import featureFlagsReducer from '../featureFlags/reducers';
import ufReducer from '../uf/reducers';
import appReducer from '../app/reducers';
import hiringReducer from '../hiring/reducers';
import panelReducer from '../panel/reducers';
import loginReducer from '../login/reducers';

// Local
import incorporationReducer from '../incorporation/reducers';
import feedbackReducer from '../feedback/reducers';
import leadManagerReducer from '../leadManager/reducers';
import filterReducer from '../filters/reducers';

export default {
  wizard: wizardReducer,
  plans: plansReducer,
  uf: ufReducer,
  app: appReducer,
  incorporation: incorporationReducer,
  hiring: hiringReducer,
  panel: panelReducer,
  feedback: feedbackReducer,
  leadManager: leadManagerReducer,
  filters: filterReducer,
  login: loginReducer,
  featureFlags: featureFlagsReducer,
};
