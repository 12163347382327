import { AvailablePlan } from '@/src/types/availablePlans';
import apolloClient from '../apollo-client';
import getUFToday from '../queries/getUFToday';
import listPlans from '../queries/listPlans';
import getFeatureFlag from '../queries/getFeatureFlag';
import { getBasePlans } from '@/utils/estimationFunctions';

const getUFService = async () => {
  const { data } = await apolloClient.query({
    query: getUFToday,
    fetchPolicy: 'no-cache',
  });

  return data.getUFToday;
};

export const getFlags = async () => {
  const [cyberDay, cyberExtension, cyberLastDay] = (
    await Promise.all([
      apolloClient.query({
        query: getFeatureFlag,
        fetchPolicy: 'no-cache',
        variables: {
          name: 'cyber',
        },
      }),
      apolloClient.query({
        query: getFeatureFlag,
        fetchPolicy: 'no-cache',
        variables: {
          name: 'cyber-extension',
        },
      }),
      apolloClient.query({
        query: getFeatureFlag,
        fetchPolicy: 'no-cache',
        variables: {
          name: 'cyber-last-day',
        },
      }),
    ])
  ).map((p) => p.data?.getFeatureFlag?.active);

  return { cyberDay, cyberExtension, cyberLastDay };
};

const getPlansService = async () => {
  const { data } = await apolloClient.query({
    query: listPlans,
    fetchPolicy: 'no-cache',
  });

  const [lowestPricePlan] = getBasePlans({
    plans: data.listPlans || [],
    filterByHide: true,
    ascending: true,
  });

  return data.listPlans.filter(
    (plan: AvailablePlan) =>
      (plan.base !== null && plan.hidePlan !== true) || plan.type === lowestPricePlan.base
  );
};

export { getUFService, getPlansService };
