import { gql } from '@apollo/client';
import coverageFragment from '../fragments/coverageFragment';

export default gql`
  fragment planFragment on Plan {
    _id
    description
    base
    badge {
      text
      color
      icon
      label
    }
    badges {
      label
      icon
      color
      background
      show
    }
    price {
      uf
    }
    coverage {
      health {
        ...coverageFragment
      }
      life {
        ...coverageFragment
      }
      dental {
        ...coverageFragment
      }
      catastrophic {
        ...coverageFragment
      }
    }
    variants {
      _id
      description
      texts {
        title
        description
        showCLP
        showUF
        distribution {
          label
          summary
          showValue
          description
        }
      }
      active
      visible
      price {
        uf
      }
      icon
    }
    type
    idUpselling
    originalPrice
    assistances {
      description
      title
      provider
      innerTitle
    }
    promo {
      description
      label
      show
      icon
    }
    hidePlan
    cardCoverages
    deleted
    createdAt
    updatedAt
  }
  ${coverageFragment}
`;
