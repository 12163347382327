import {
  SET_LEAD_MANAGER_FILTERS,
  SET_LEAD_MANAGER_ORDERING,
  SET_LEAD_MANAGER_SEARCHBAR,
} from './actionTypes';

const initialState = {
  leadPanel: {
    searchbar: '',
    ordering: undefined,
    filters: undefined,
  },
};

const reducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_LEAD_MANAGER_FILTERS:
      return {
        ...state,
        leadPanel: {
          ...state.leadPanel,
          filters: payload,
        },
      };

    case SET_LEAD_MANAGER_ORDERING:
      return {
        ...state,
        leadPanel: {
          ...state.leadPanel,
          ordering: payload,
        },
      };

    case SET_LEAD_MANAGER_SEARCHBAR:
      return {
        ...state,
        leadPanel: {
          ...state.leadPanel,
          searchbar: payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
