import { SET_UF } from './actionTypes';

const initialState = {};

const reducer = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_UF:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default reducer;
