import * as dateFns from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import esLocale from 'date-fns/locale/es';

import { incorporationStatusTexts, regExpOnlyNumbers, regExpPositiveIntegers } from './constants';
import { UF } from '../types/uf';
import { FeatureFlags } from '@/src/types/featureFlags';

export const format = (value: string | number = 0, options: any) => {
  const newValue = options.round ? Math.round(Number(value)) : parseFloat(value.toString());

  const valueFixed = options.fixedDecimals ? newValue.toFixed(options.decimals) : newValue;

  return valueFixed
    .toString()
    .replace('.', options.separator.decimal)
    .replace(/\B(?=(\d{3})+(?!\d))/g, options.separator.miles);
};

export const formatMoney = (value: string | number, options?: any) => {
  const optionsDefault = {
    symbol: '$',
    separator: {
      miles: '.',
      decimal: ',',
    },
    round: true,
    decimals: 0,
    fixedDecimals: true,
    ...options,
  };

  return `${optionsDefault.symbol}${format(value, optionsDefault)}`;
};

export const formatUF = (value: string | number, options?: any) => {
  const optionsDefault = {
    symbol: 'UF',
    separator: {
      miles: '.',
      decimal: ',',
    },
    round: false,
    decimals: 2,
    fixedDecimals: true,
    ...options,
  };

  return `${optionsDefault.symbol} ${format(value, optionsDefault)} `;
};

export const formatRut = (_rut: string) => {
  const rut = _rut.replace(/^0+|[^0-9kK]+/g, '').toLowerCase();

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;

  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return result;
};

export const unformatRut = (rut: string) =>
  !!rut ? rut.replace(/^0+|[^0-9kK]+/g, '').toLowerCase() : rut;

export const formatRutWithoutDots = (rut: string) =>
  !!rut ? rut.replace(/\./g, '').toLowerCase() : rut;

export const formatRutPDF = (rut: string) => {
  if (!rut) {
    return rut;
  }

  const unformatedRut = unformatRut(rut);

  return `${unformatedRut.slice(0, unformatedRut.length - 1)}-${unformatedRut.substr(
    unformatedRut.length - 1
  )}`;
};

export const formatPositiveIntegers = (workers: number | string) => {
  return Number(
    regExpOnlyNumbers.test(workers.toString())
      ? workers
      : workers.toString().replace(regExpPositiveIntegers, '')
  );
};

export const capitalizeString = (string: string) => {
  if (typeof string !== 'string' || !string) return string;

  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const formatIncorporationStatus = (status: string) => incorporationStatusTexts[status];

export const formatIncorporationDate = (date: any, showWeekDay = false) => {
  const newDate = date ? new Date(date) : new Date();

  if (showWeekDay) {
    return capitalizeString(dateFns.format(newDate, "dd 'de' MMM 'de' yyyy", { locale: esLocale }));
  }

  return dateFns.format(newDate, "dd 'de' MMMM", { locale: esLocale });
};

export const formatStartHiringDate = (date: any, showWeekDay = false) => {
  const newDate = date ? new Date(date) : new Date();

  if (showWeekDay) {
    return capitalizeString(dateFns.format(newDate, 'dd MMM yyyy', { locale: esLocale }));
  }

  return dateFns.format(newDate, 'dd MMMM', { locale: esLocale });
};

export const formatIncorporationName = (
  name: string,
  paternalLastname: string,
  maternalLastname: string
) => {
  const parts = [name, paternalLastname];

  maternalLastname && parts.push(`${maternalLastname.charAt(0)}.`);

  return parts.join(' ');
};

export const splitInChunks = <T>(arrayList: T[], chunkSize: number) => {
  const temporalList = [];

  for (let i = 0; i < arrayList.length; i += chunkSize) {
    temporalList.push(arrayList.slice(i, i + chunkSize));
  }

  return temporalList;
};

export const formatPhone = (phone: string) => {
  return phone.replace(/\D+/g, '').replace(/(\d{1})(\d{1,8})/, '$1 $2');
};

export const unFormatPhone = (phone: string) => {
  return phone.replace(/\D+/g, '');
};

export const formatBytes = (size: number, decimals = 2) => {
  if (size === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(size) / Math.log(k));

  return `${parseFloat((size / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const isVisibleByCyberDates = (date: string) => {
  const formatDate = dateFns.parseISO(date);

  const isAvailable = dateFns.isWithinInterval(formatDate, {
    start: new Date(1685329200000),
    end: new Date(1685764740000),
  });

  return isAvailable;
};

export enum IntervalCyber {
  CyberDay,
  CyberDayLastDay,
  Extension,
  ExtensionLastDay,
}

export const getCyberByFlags = (flags: FeatureFlags): IntervalCyber | null => {
  if (flags.cyberLastDay) return IntervalCyber.CyberDayLastDay;
  if (flags.cyberExtension) return IntervalCyber.Extension;
  if (flags.cyberDay) return IntervalCyber.CyberDay;

  return null;
};
export const getIntervalByCyberDates = (date: string): IntervalCyber | null => {
  const formatDate = dateFns.parseISO(date);

  const intervals = [
    {
      id: IntervalCyber.CyberDay,
      start: new Date(1696212000000),
      end: new Date(1696388340000),
    },
    {
      id: IntervalCyber.CyberDayLastDay,
      start: new Date(1696388341000),
      end: new Date(1696474740000),
    },
    {
      id: IntervalCyber.Extension,
      start: new Date(1696474741000),
      end: new Date(1696561140000),
    },
    {
      id: IntervalCyber.ExtensionLastDay,
      start: new Date(1696561141000),
      end: new Date(1696644000000),
    },
  ];

  const interval = intervals.find((interval) => dateFns.isWithinInterval(formatDate, interval));

  return interval ? interval.id : null;
};

export const formatDate = (date: Date) => {
  const [day, hour] = formatInTimeZone(date, 'America/Santiago', 'dd/MM/yyyy HH:mm:ss').split(' ');

  return {
    day,
    hour,
  };
};

export const isEmptyValue = (json: any) => {
  const isEmpty = Object.values(json).some((x) => x === '' || x === null);

  return !isEmpty;
};

export const startChargeCurrentMonth = (date: string) => {
  const formatDate = dateFns.parseISO(date);

  const firstDateOfMonth = dateFns.format(formatDate, 'yyyy-MM-01');
  const fortnightDateOfMonth = dateFns.format(formatDate, 'yyyy-MM-16');

  const chargeCurrentMonth = dateFns.isWithinInterval(formatDate, {
    start: new Date(firstDateOfMonth),
    end: new Date(fortnightDateOfMonth),
  });

  return chargeCurrentMonth;
};

export const getStartChargeDay = (date: string) => {
  let formatDate = dateFns.parseISO(date);

  if (startChargeCurrentMonth(date)) {
    return formatInTimeZone(formatDate, 'America/Santiago', '01/MM/yyyy');
  }

  formatDate = dateFns.addMonths(formatDate, 1);

  return formatInTimeZone(formatDate, 'America/Santiago', '01/MM/yyyy');
};

export const formatUFValue = (value: number | string, uf: UF, bold = false): string => {
  if (typeof value === 'string') return `${value}`;
  if (value === 0) return `Sin costo adicional`;
  const ufValue = value.toFixed(2).replace('.', ',');
  const clpValue = (value * uf.value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${bold ? `<b>UF ${ufValue}</b>` : ` UF ${ufValue}`} / $${clpValue}`;
};

export const getLastIncorporatesRefreshed = (date: Date) => {
  return formatInTimeZone(date, 'America/Santiago', "dd 'de' MMMM 'de' yyyy 'a las' HH:mm a", {
    locale: esLocale,
  });
};
