// Workers
export const SET_WORKERS = 'SET_WORKERS';
export const RESET_WORKERS = 'RESET_WORKERS';

// Pre Plan
export const SET_PRE_PLAN_SELECTED = 'SET_PRE_PLAN_SELECTED';
export const SET_PRE_PLAN_TYPE = 'SET_PRE_PLAN_TYPE';
export const RESET_PRE_PLAN_SELECTED = 'RESET_PRE_PLAN_SELECTED';

// Plans
export const SET_PLAN_SELECTED = 'SET_PLAN_SELECTED';
export const RESET_PLAN_SELECTED = 'RESET_PLAN_SELECTED';

// Coverages
export const SET_DENTAL_COVERAGE = 'SET_DENTAL_COVERAGE';
export const SET_CATASTROPHIC_COVERAGE = 'SET_CATASTROPHIC_COVERAGE';
export const RESET_SELECTED_COVERAGES = 'RESET_SELECTED_COVERAGES';

// Variants
export const SET_VARIANT_SELECTED = 'SET_VARIANT_SELECTED';
export const RESET_VARIANT_SELECTED = 'RESET_VARIANT_SELECTED';

// Step
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const RESET_CURRENT_STEP = 'RESET_CURRENT_STEP';

// Form
export const SET_FORM_VALIDATION = 'SET_FORM_VALIDATION';
export const SET_FORM_DATA = 'SET_FORM_DATA';

export const RESET_FORM_VALIDATION = 'RESET_FORM_VALIDATION';

export const SET_USER_DATA = 'SET_USER_DATA';
export const RESET_USER_DATA = 'RESET_USER_DATA';

export const SET_SEND_LEAD = 'SET_SEND_LEAD';
export const RESET_SEND_LEAD = 'RESET_SEND_LEAD';

// Wizard
export const RESET_WIZARD = 'RESET_WIZARD';
export const RECOVER_FROM_STORAGE = 'RECOVER_FROM_STORAGE';

export const RESET_EMAIL = 'RESET_EMAIL';
export const RESET_COMPANY_DATA = 'RESET_COMPANY_DATA';

export const SET_EMAIL_VALIDATION = 'SET_EMAIL_VALIDATION';
export const RESET_EMAIL_VALIDATION = 'RESET_EMAIL_VALIDATION';

export const SET_EMAIL_PERSIST = 'SET_EMAIL_PERSIST';
export const RESET_EMAIL_PERSIST = 'RESET_EMAIL_PERSIST';

export const SET_LOADING_WIZARD = 'SET_LOADING_WIZARD';
export const SET_TOASTER_WIZARD = 'SET_TOASTER_WIZARD';

export const SET_BUSINESS = 'SET_BUSINESS';
export const RESET_BUSINESS = 'RESET_BUSINESS';

// Personal Info Step
export const SET_PERSONAL_INFO_VALIDATION = 'SET_PERSONAL_INFO_VALIDATION';
export const RESET_PERSONAL_INFO_VALIDATION = 'RESET_PERSONAL_INFO_VALIDATION';

// Upselling Plan
export const SET_IS_UPSELLING_PLAN = 'SET_IS_UPSELLING_PLAN';

// Branding
export const SET_BRANDING = 'SET_BRANDING';
export const RESET_BRANDING = 'RESET_BRANDING';

// Executive
export const SET_EXECUTIVE = 'SET_EXECUTIVE';
export const RESET_EXECUTIVE = 'RESET_EXECUTIVE';
