import { leadManagerTexts } from '@/utils/generalTexts';
import {
  SET_LEADS_LIST,
  UPDATE_LEADS_LIST,
  SET_INCORPORATION_LIST,
  SET_ACTIVE_TAB,
  SET_USER,
  RESET_USER,
} from './actionTypes';

const initialState: LeadManagerDetailT = {
  leads: [],
  incorporations: [],
  activeTab: leadManagerTexts.tabs.leads.id,
  user: {
    name: '',
    role: '',
  },
};

const reducer = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_LEADS_LIST:
      return {
        ...state,
        leads: payload,
      };

    case UPDATE_LEADS_LIST:
      return {
        ...state,
        leads: payload,
      };

    case SET_INCORPORATION_LIST:
      return {
        ...state,
        incorporations: payload,
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };

    case SET_USER:
      return {
        ...state,
        user: payload,
      };

    case RESET_USER:
      return {
        ...state,
        user: initialState.user,
      };

    default:
      return state;
  }
};

export default reducer;
