import { AvailablePlan, AvailablePlans } from '../types/availablePlans';
import { regExpOnlyNumbers } from './constants';
import { formatPositiveIntegers } from './format';
import { workersValidation } from './validations';

type ErrorT = {
  hasError: boolean;
  feedback: string;
};

const onChangeWorkersHandler = async (
  event: any,
  onChange: (workers: number, dataError?: ErrorT) => void,
  errorOnChange: boolean
) => {
  const { value } = event.target;
  const workers = formatPositiveIntegers(value);

  try {
    await workersValidation.validateAsync({ workersValue: workers });

    onChange(workers, {
      hasError: false,
      feedback: '',
    });
  } catch (error) {
    if (errorOnChange) {
      onChange(workers, {
        hasError: true,
        feedback: (error as Error).message,
      });
    } else {
      onChange(workers);
    }
  }
};

const onBlurWorkersHandler = async (event: any, onError: (dataError: ErrorT) => void) => {
  const { value } = event.target;
  const workers = !!value ? formatPositiveIntegers(value) : value;

  try {
    await workersValidation.validateAsync({ workersValue: workers });

    onError({
      hasError: false,
      feedback: '',
    });
  } catch (error) {
    onError({
      hasError: true,
      feedback: (error as Error).message,
    });
  }
};

const onKeypressWorkersHandler = (event: any) => {
  if (!regExpOnlyNumbers.test(event.key)) {
    event.preventDefault();
  }
};

export const workersFunctions = {
  onChange: onChangeWorkersHandler,
  onBlur: onBlurWorkersHandler,
  onKeypress: onKeypressWorkersHandler,
};

type getBasePlansT = {
  plans: AvailablePlans;
  filterByHide?: boolean;
  ascending?: boolean;
};

export const getBasePlans = ({ plans, filterByHide = false, ascending = true }: getBasePlansT) => {
  const basePlans = plans
    .filter(
      (plan: AvailablePlan) => plan.base !== null && (filterByHide ? plan.hidePlan !== true : true)
    )
    .sort((a: AvailablePlan, b: AvailablePlan) => {
      const aPrice = a?.price?.uf || 0;
      const bPrice = b?.price?.uf || 0;

      return ascending ? aPrice - bPrice : bPrice - aPrice;
    });

  return basePlans;
};

export const getDentalPlanByType = (plans: AvailablePlans, prePlan: AvailablePlan) => {
  const prePlanType = prePlan.type;
  const prePlanCost = prePlan?.price?.uf || 0;

  const plansByType = plans.filter(({ type }: any) => type === prePlanType);

  const dentalPlan = plansByType.find(
    (plan: AvailablePlan) => plan.coverage.dental !== null && plan.coverage.catastrophic === null
  );

  const dentalCost = dentalPlan?.price?.uf || 0;
  const cost = Math.abs(dentalCost - prePlanCost);

  return {
    plan: dentalPlan,
    cost,
  };
};

export const getCatastrophicPlanByType = (plans: AvailablePlans, prePlan: AvailablePlan) => {
  const prePlanType = prePlan.type;
  const prePlanCost = prePlan?.price?.uf || 0;

  const plansByType = plans.filter(({ type }: any) => type === prePlanType);

  const catastrophicPlan = plansByType.find(
    (plan: AvailablePlan) => plan.coverage.catastrophic !== null && plan.coverage.dental === null
  );

  const catastrophicCost = catastrophicPlan?.price?.uf || 0;
  const cost = Math.abs(catastrophicCost - prePlanCost);

  return {
    plan: catastrophicPlan,
    cost,
  };
};

export const getAllPlanByType = (plans: AvailablePlans, prePlan: AvailablePlan) => {
  const prePlanType = prePlan.type;
  const prePlanCost = prePlan?.price?.uf || 0;

  const plansByType = plans.filter(({ type }: any) => type === prePlanType);

  const allPlan = plansByType.find(
    (plan: AvailablePlan) => plan.coverage.catastrophic !== null && plan.coverage.dental !== null
  );

  const catastrophicCost = allPlan?.price?.uf || 0;
  const cost = Math.abs(catastrophicCost - prePlanCost);

  return {
    plan: allPlan,
    cost,
  };
};
