export const SET_COMPANY_FORM_VALIDATION = 'SET_COMPANY_FORM_VALIDATION';
export const RESET_COMPANY_FORM_VALIDATION = 'RESET_COMPANY_FORM_VALIDATION';

export const SET_SEND_COMPANY_DATA = 'SET_SEND_COMPANY_DATA';
export const RESET_SEND_COMPANY_DATA = 'RESET_SEND_COMPANY_DATA';

export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const RESET_COMPANY_DATA = 'RESET_COMPANY_DATA';

export const SET_EMAIL_PERSIST = 'SET_EMAIL_PERSIST';
export const RESET_EMAIL_PERSIST = 'RESET_EMAIL_PERSIST';

export const SET_LEAD_DATA = 'SET_LEAD_DATA';
export const RESET_LEAD_DATA = 'RESET_LEAD_DATA';

export const SET_LEAD_CREATED = 'SET_LEAD_CREATED';
export const RESET_LEAD_CREATED = 'RESET_LEAD_CREATED';

export const HAS_SUB_COMPANIES = 'HAS_SUB_COMPANIES';
export const SET_SUB_COMPANIES = 'SET_SUB_COMPANIES';
export const SET_SUB_COMPANIES_VALID = 'SET_SUB_COMPANIES_VALID';
export const SET_SUB_COMPANIES_ENTRY_VALID = 'SET_SUB_COMPANIES_ENTRY_VALID';
export const RESET_HIRING_ALL = 'RESET_HIRING_ALL';

export const SET_SELECT_ADMIN_VALIDATION = 'SET_SELECT_ADMIN_VALIDATION';
export const RESET_SELECT_ADMIN_VALIDATION = 'RESET_SELECT_ADMIN_VALIDATION';

export const SET_ADMIN_FORM_VALIDATION = 'SET_ADMIN_FORM_VALIDATION';
export const RESET_ADMIN_FORM_VALIDATION = 'RESET_ADMIN_FORM_VALIDATION';

export const SET_ADMIN_DEFAULT = 'SET_ADMIN_DEFAULT';
export const SET_COMPLETE_COMPANY_DATA = 'SET_COMPLETE_COMPANY_DATA';
export const RESET_COMPLETE_COMPANY_DATA = 'RESET_COMPLETE_COMPANY_DATA';
export const SET_ADMIN_DATA = 'SET_ADMIN_DATA';
export const RESET_ADMIN_DATA = 'RESET_ADMIN_DATA';

export const SET_IS_INCORPORATION_RANGE = 'SET_IS_INCORPORATION_RANGE';
export const RESET_IS_INCORPORATION_RANGE = 'RESET_IS_INCORPORATION_RANGE';

export const SET_FINAL_CONFIRMATION_DATA = 'SET_FINAL_CONFIRMATION_DATA';
