import crypto from 'crypto';

import { filterToday, filterLast7Days, filterLast14Days, filterLastMonth } from '@/utils/constants';

export const priorityLeadUFA = (UFA: number) => {
  if (UFA >= 0 && UFA <= 79) {
    return 1;
  }

  if (UFA >= 80 && UFA <= 129) {
    return 2;
  }

  if (UFA >= 130 && UFA <= 199) {
    return 3;
  }

  return 4;
};

export const validateDate = (filter: any, outDate: Date) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const externalDate = new Date(outDate);
  const externalDay = externalDate.getDate();
  const externalMonth = externalDate.getMonth();
  const externalYear = externalDate.getFullYear();

  if (filter === filterToday) {
    return currentDate === externalDate;
  }

  if (filter === filterLast7Days) {
    const currentLast7Days = new Date(currentYear, currentMonth, currentDay);
    const last7Days = new Date(currentYear, currentMonth, currentDay - 7);
    const externalDate = new Date(externalYear, externalMonth, externalDay);

    return externalDate >= last7Days && externalDate <= currentLast7Days;
  }

  if (filter === filterLast14Days) {
    const currentLast14Days = new Date(currentYear, currentMonth, currentDay);
    const last14Days = new Date(currentYear, currentMonth, currentDay - 14);
    const externalDate = new Date(externalYear, externalMonth, externalDay);

    return externalDate >= last14Days && externalDate <= currentLast14Days;
  }

  if (filter === filterLastMonth) {
    const lastMonthDate = new Date(currentYear, currentMonth, 1);
    const currentDateLastMonth = new Date(currentYear, currentMonth, currentDay);
    const externalDate = new Date(externalYear, externalMonth, externalDay);

    return externalDate >= lastMonthDate && externalDate <= currentDateLastMonth;
  }

  return null;
};

export const dataLayerPush = (
  event: string,
  eventCategory: string,
  eventAction?: string,
  entries?: any
) => {
  Object.keys(entries).forEach((key: string) => {
    entries[key] = String(entries[key])
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '');
  });

  // window?.dataLayer?.push({
  //   event,
  //   eventCategory,
  //   eventAction,
  //   ...entries,
  // });
};

export const dataLayerPageView = () => {
  // window?.dataLayer?.push({
  //   event,
  //   pageName,
  // });
};

export const conjuntion = {
  format: (arr: string[]) =>
    arr.join(', ').replace(/,(?:.(?!,))+$/, (sub) => {
      return sub.replace(',', ' y');
    }),
};

export const hashString = async (str: string) => {
  const buffer = new TextEncoder().encode(str);
  const hash = await crypto.subtle.digest('SHA-256', buffer);
  const hexHash = Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return hexHash;
};

export const userHash = (string: string) => {
  const newString = crypto.createHmac('sha256', string).update('bice').digest('hex');

  return newString;
};
