const routes: any = {
  landing: '/',
  wizard: '/cotizacion/numero-trabajadores',
  wizardEmail: '/cotizacion/correo-electronico',
  wizardWorkers: '/cotizacion/numero-trabajadores',
  wizardPlan: '/cotizacion/plan',
  wizardCoverages: '/cotizacion/coberturas',
  wizardVariants: '/cotizacion/cargas',
  wizardForm: '/cotizacion/info-empresa',
  wizardSummary: '/cotizacion/resumen',
  wizardQuery: 'nombre-apellido',
  wizardPersonal: '/cotizacion/data-personal',

  incorporation: '/incorporacion',
  hiringDashboard: '/contratacion/inicio',
  hiringConfirmation: '/contratacion/aceptacion',
  hiringSuccess: '/contratacion/exito',
  hiring: '/contratacion/info-empresa',
  admin: '/contratacion/administrador',
  adminInfo: '/contratacion/info-administrador',
  companies: '/contratacion/razones-sociales',
  companiesEntry: '/contratacion/razones-sociales/ingreso',
  personalInformationNameLastname: '/incorporacion/info-personal/nombre-y-apellido',
  personalInformationEmail: '/incorporacion/info-personal/correo-electronico',
  personalInformationMoreInfo: '/incorporacion/info-personal/mas-informacion',
  personalInformationWorkingInfo: '/incorporacion/info-personal/datos-laborales',
  familyGroupPersonalInformation: '/incorporacion/familiar-info-personal',
  familyGroupPersonalInformationEntry: '/incorporacion/familiar-info-personal/ingreso',
  familyGroupMedicalInformation: '/incorporacion/familiar-info-medica',
  familyGroupMedicalInformationEntry: '/incorporacion/familiar-info-medica/ingreso',
  lifeInsuranceBeneficiaries: '/incorporacion/beneficiarios-seguro-de-vida',
  medicalInformation: '/incorporacion/info-medica',
  medicalInformationEntry: '/incorporacion/info-medica/ingreso',
  riskyActivities: '/incorporacion/deportes-o-act-riesgosas',
  riskyActivitiesEntry: '/incorporacion/deportes-o-act-riesgosas/ingreso',
  refund: '/incorporacion/reembolso',
  downloadDocument: '/incorporacion/descarga-tu-documento',
  summaryDocument: '/incorporacion/resumen',
  template: '/template/incorporation/[id]',
  formularioInconrporacion: '/formulario-incorporacion',
  succesfulSignature: '/incorporacion/firma-exito',
  helpDesk: '/centro-de-ayuda',
  helpDeskContact: '/centro-de-ayuda/contacto',
  helpDeskSearch: '/centro-de-ayuda/busqueda',
  topicSuggestions: '/centro-de-ayuda/sugerencia',
  overviewDetail: '/administrador/detalle-incorporacion',
  hiringDetail: '/administrador/detalle-cotizacion',
  panel: '/panel',
  leadManager: '/administrador',
  leadDetail: '/administrador/detalle-lead',
  leadEstimation: '/administrador/detalle-cotizar',

  benefits: '/beneficios',

  coverages: '/coberturas',

  pricesAndPlans: '/precios-y-planes',
  recoverHiring: '/recuperar-contratacion',
  recoverHiringCode: '/recuperar-contratacion/autenticacion',
};

export default routes;
