import '@BICE_Vida/components/dist/main.css';
import '@BICE_Vida/scp-components/dist/main.css';
import React, { Fragment } from 'react';
import App from 'next/app';
import Head from 'next/head';
import smoothscroll from 'smoothscroll-polyfill';
import { withRouter } from 'next/router';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { Poppins } from 'next/font/google';
import localFont from 'next/font/local';
import DatadogInit from './datadog-init';
import withRedux from '../redux/withRedux';

// eslint-disable-next-line
import '@/src/styles/app.scss';

import routes from '../utils/routes';
import { appTitle, asyncHideClass } from '../utils/constants';

import client from '@/graphQL/apollo-client';
import { getFlags } from '@/graphQL/onLoad/getProps';
import { setFeatureFlags } from '@/redux/featureFlags/actions';

export const poppins = Poppins({
  weight: ['400', '500', '600', '700', '800'],
  display: 'swap',
  subsets: ['latin'],
  variable: '--font-poppins',
});

const materialIcons = localFont({
  src: [
    {
      path: '../../public/fonts/Material-Round.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  display: 'swap',
});

class FrontApp extends App {
  persistor: any;

  constructor(props: any) {
    super(props);
    this.persistor = persistStore(props.reduxStore);
  }

  componentDidMount() {
    smoothscroll.polyfill();
    this.props.reduxStore.dispatch(setFeatureFlags(this.props.flags));
  }

  componentDidUpdate(newProps: any) {
    const {
      router: { route },
    } = newProps;

    if (route === routes.wizard) {
      !document.documentElement.classList.contains(asyncHideClass) &&
        document.documentElement.classList.add(asyncHideClass);
    }
  }

  static getInitialProps = async (appContext: any) => {
    const appProps = await App.getInitialProps(appContext);
    const flags = await getFlags();

    return { ...appProps, flags };
  };

  render() {
    const { Component, pageProps, reduxStore, flags }: any = this.props;

    return (
      <Fragment>
        <Head>
          <title>{appTitle}</title>
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        </Head>
        <ApolloProvider client={client}>
          <Provider store={reduxStore}>
            <PersistGate loading={null} persistor={this.persistor}>
              <style jsx global>{`
                :root {
                  --poppins-font: ${poppins.style.fontFamily};
                  --material-icons: ${materialIcons.style.fontFamily};
                }
              `}</style>
              <DatadogInit />
              <Component {...pageProps} />
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </Fragment>
    );
  }
}

export default withRedux(withRouter(FrontApp));
