import {
  SET_ERROR,
  UNSET_ERROR,
  SET_LOADING,
  SET_AB_TESTING,
  UNSET_AB_TESTING,
} from './actionTypes';

const initialState = {
  error: false,
  errorDetail: undefined,
  loading: false,
  abTesting: {
    hasABTesting: false,
    experimentId: '',
    variantSelected: '0',
  },
};

const reducer = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_ERROR:
      return {
        ...state,
        error: true,
        errorDetail: payload,
      };

    case UNSET_ERROR:
      return {
        ...state,
        error: false,
        errorDetail: undefined,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SET_AB_TESTING:
      return {
        ...state,
        abTesting: payload,
      };

    case UNSET_AB_TESTING:
      return {
        ...state,
        abTesting: initialState.abTesting,
      };

    default:
      return state;
  }
};

export default reducer;
