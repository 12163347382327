import { ICON_USER } from './assets';

export const headerLogoColor = 'color';
export const headerLogoWhite = 'white';
export const cheapestPlan = 0.47;
export const summaryStepCat = '/summary-step-cat.jpg';
export const personalInfoStepCat = '/icon-personal-info-cat.png';
export const hiringStepCat = '/icon-hiring-cat.jpg';

export const headerDefaultHeightDesktop = 80; // By UI/UX designs
export const headerDefaultHeightMobile = 64; // By UI/UX designs

export const breakPointXl = 1200;
export const breakPointLg = 992;
export const breakPointMd = 768;
export const breakPointSm = 576;

// Wizard Steps
export const wizardPersonalInfoStep = 'personalInfo';
export const wizardEmailStep = 'email';
export const wizardWorkersStep = 'workers';
export const wizardPlansStep = 'plans';
export const wizardCoveragesStep = 'coverages';
export const wizardVariantsStep = 'variants';
export const wizardSummaryStep = 'summary';
export const wizardFormStep = 'form';

// Progress Panel Modals
export const PanelModals = {
  hiringSumaryModals: {
    availablePlan: 'plan',
  },
  hiringStepsModals: {
    companyName: 'company-name',
    shareIncorporation: 'share',
    advertise: 'company-advertise',
  },
  hiringCompanyModals: {
    company: 'company',
    admin: 'admin',
  },
  hiringIncorporationsModals: {
    charges: 'charges',
  },
};

// RegEx
export const regExpOnlyNumbers = /^\d+$/;
export const regExpRut = /[0-9kK.-]/;
export const regExpAlphabetic = /[a-zA-Z'&\u00f1\u00d1 ]+/;
export const regExpAlphanumeric = /[a-zA-Z0-9\u00f1\u00d1 ]+/;
export const regExpAlphanumericSpecialCharacters = /[a-zA-Z0-9À-ÿ'&\u00f1\u00d1\u0023 ]+/;
export const regExpSpecialCharacters = /[\s\S]/;
export const regExpPositiveIntegers = /(\D+)/g;
export const regExpDate = /[0-9/]/;
export const regExpUpperCaseNames = /(^\w{1})/g;
export const regExpAccents = /[\u0300-\u036f]/g;
export const regExpLettersAccents = /[À-ÿ]+/;
export const regExpNumberTextfieldPattern = '[0-9]*';

// Delay
export const delayLoader = 2000;
export const delayReviewAmount = 1000;
export const delayToaster = 4000;
export const delayPlanUpgrade = 5000;
export const delayPageLoader = 1000;
export const delayEstimateSteps = 3000;

export const coveragesKeys = {
  health: 'health',
  life: 'life',
  dental: 'dental',
  catastrophic: 'catastrophic',
};

export const coveragesTexts: any = {
  health: 'Salud',
  life: 'Vida',
  dental: 'Dental',
  catastrophic: 'Catastrófico',
};

export const maxLengthWorkers = 4;
export const maxLengthRut = 9;
export const bicevidaURL = 'https://www.bicevida.cl/';

export const fontsObservedDelay = 15000;

export const asyncHideClass = 'async-hide';

export const preleadStatus = {
  EMPTY: 'EMPTY',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

export const bicevidaURLBenefits = 'https://circulo.bicevida.cl/';
export const bicevidaURLDentalBenefits = 'https://circulo.bicevida.cl/beneficio/cam-dental';
export const bicevidaURLHealthBenefits = 'https://circulo.bicevida.cl/beneficio/salud';
export const bicevidaURLPartnersBenefits = 'https://www.bicevida.cl/prestadores-preferentes/';
export const bicevidaURLWindowBenefits = 'https://www.bicevida.cl/ventanilla-unica/';

export const bicevidaURLWindowIndividualInsurance =
  'https://segurocomplementariosalud.bicevida.cl/?utm_source=bicevida&utm_medium=referral&utm_campaign=pyme-modal/';

export const benefitsListURL = 'https://www.bicevida.cl/beneficios/';
export const summaryBannerBg = '/summary-banner-wave.svg';
export const imgSaveMoney = '/savemoney.png';
export const imgChooseInsurance = '/choose_insurance.png';
export const imgJubilolos = '/jubilolos.png';
export const minLimitWorkers = 5;

export const footerHeightSize = 80;
export const optionsHeightSize = 40;
export const spacingHeightSize = 50;
export const maxHeightSize = 240;

export const dateSelectInitialDate = new Date().getFullYear();
export const dateSelectLeftArrow = '\u276E';
export const dateSelectRightArrow = '\u276F';
export const unicode = 'NFD';
export const bankStateOfChile = 'Banco Estado';
export const accountRut = 'Cuenta Rut';
export const accountVista = 'Cuenta Vista';
export const fonasa = 'Fonasa';

export const genderOptions = { Masculino: 'M', Femenino: 'F' };
export const genderOptionsTexts = { man: 'M', woman: 'F' };
export const spouseText = 'Cónyuge/Conviviente civil';

export const relationshipList = ['Cónyuge/Conviviente civil', 'Hija', 'Hijo'];
export const relationshipListTexts = {
  spouse: 'Cónyuge/Conviviente civil',
  daugther: 'Hija',
  son: 'Hijo',
};

export const rangeValidations = {
  incorporation: {
    max: { years: 18, days: 0 },
    min: { years: 65, days: 1 },
  },
  spouse: {
    max: { years: 18, days: 0 },
    min: { years: 65, days: 1 },
  },
  otherRelatives: {
    max: { years: 0, days: 14 },
    min: { years: 23, days: 364 },
  },
  entryDateToCompany: {
    max: { years: 0, days: 0 },
    min: { years: 100, days: 0 },
  },
};

export const healthSystemList = [
  { title: 'Fonasa', code: 1 },
  { title: 'Banmédica', code: 9 },
  { title: 'Capredena', code: 24 },
  { title: 'Chuquicamata', code: 33 },
  { title: 'Colmena Golden Cross', code: 3 },
  { title: 'Consalud', code: 4 },
  { title: 'Cruz Blanca', code: 6 },
  { title: 'Cruz del Norte', code: 34 },
  { title: 'Dipreca', code: 37 },
  { title: 'Esencial', code: 42 },
  { title: 'Fundación', code: 20 },
  { title: 'Ferrosalud', code: 30 },
  { title: 'Fusat', code: 28 },
  { title: 'Nueva Masvida', code: 15 },
  { title: 'Río Blanco', code: 46 },
  { title: 'San Lorenzo', code: 22 },
  { title: 'Sisan', code: 35 },
  { title: 'Vida Tres', code: 7 },
];
export const riskyActivitiesCardIcon = '/risky-sport-card-icon.jpg';
export const medicalInfoCardIcon = '/icon-medical-info-card.jpg';
export const familyMemberWife = '/family-group-wife-card-icon.jpg';
export const familyMemberHusband = '/family-group-husband-card-icon.jpg';
export const familyMemberDaugther = '/family-group-daugther-card-icon.jpg';
export const familyMemberSon = '/family-group-son-card-icon.jpg';
export const familyMemberGenericParent = '/family-group-generic-card-icon.jpg';
export const beneficiariesIcon = '/beneficiaries-card.icon.jpg';

export const maxDiseaseYears = 65;

export const maxLengthNumberAccount = 20;

export const incorporationSectionStatus: any = {
  pending: 'pending',
  blocked: 'blocked',
  valid: 'valid',
};
export const incorporationSectionIcons: any = {
  pending: null,
  blocked: 'https',
  valid: 'check',
  close: 'remove',
  open: 'add',
};

export const currentYear = new Date().getFullYear();
export const minYearBeneficiaries = new Date().getFullYear() - 17;

export const dayOfMonth = 31;
export const monthOfYear = 12;

export const incorporationStatus = {
  SENT: 'OUT_FOR_SIGNATURE',
  SIGNED: 'SIGNED',
  IN_PROCESS: 'IN_PROCESS',
  HIRED: 'HIRED',
};

export const incorporationStatusTexts: any = {
  IN_PROCESS: 'En proceso',
  OUT_FOR_SIGNATURE: 'Por firmar',
  SIGNED: 'Firmado',
  HIRED: 'Cerrado',
};

export const incorporationListInfo: any = {
  IN_PROCESS: {
    class: 'yellow',
    percentage: 30,
  },
  OUT_FOR_SIGNATURE: {
    class: 'blue',
    percentage: 70,
  },
  SIGNED: {
    class: 'green',
    percentage: 100,
  },
  HIRED: {
    class: 'green',
    percentage: 100,
  },
};

enum ProgressEnumColor {
  YELLOW = 'yellow',
  BLUE = 'blue',
  GREEN = 'green',
}

export const leadStatusProgress = {
  CREATED: {
    class: ProgressEnumColor.YELLOW,
    label: 'Creado',
    type: 'IN_PROCESS',
  },
  IN_PROCESS: {
    class: ProgressEnumColor.YELLOW,
    label: 'En proceso',
    type: 'IN_PROCESS',
  },
  HIRING: {
    class: ProgressEnumColor.BLUE,
    label: 'Contratación',
    type: 'HIRING',
  },
  SIGNED: {
    class: ProgressEnumColor.BLUE,
    label: 'Firmado',
    type: 'SIGNED',
  },
  HIRED: {
    class: ProgressEnumColor.GREEN,
    label: 'Cerrado',
    type: 'HIRED',
  },
};

export const districtsByRegion: any = {
  'Región Metropolitana de Santiago': [
    'Alhué',
    'Buin',
    'Calera de Tango',
    'Cerrillos',
    'Cerro Navia',
    'Colina',
    'Conchalí',
    'Curacaví',
    'El Bosque',
    'El Monte',
    'Estación Central',
    'Huechuraba',
    'Independencia',
    'Isla de Maipo',
    'La Cisterna',
    'La Florida',
    'La Granja',
    'La Pintana',
    'La Reina',
    'Lampa',
    'Las Condes',
    'Lo Barnechea',
    'Lo Espejo',
    'Lo Prado',
    'Macul',
    'Maipú',
    'María Pinto',
    'Melipilla',
    'Ñuñoa',
    'Padre Hurtado',
    'Paine',
    'Pedro Aguirre Cerda',
    'Peñaflor',
    'Peñalolén',
    'Pirque',
    'Providencia',
    'Pudahuel',
    'Puente Alto',
    'Quilicura',
    'Quinta Normal',
    'Recoleta',
    'Renca',
    'San Bernardo',
    'San Joaquín',
    'San José de Maipo',
    'San Miguel',
    'San Pedro',
    'San Ramón',
    'Santiago',
    'Talagante',
    'Til Til',
    'Vitacura',
  ],
  'Región de Antofagasta': [
    'Antofagasta',
    'Calama',
    'María Elena',
    'Mejillones',
    'Ollagüe',
    'San Pedro de Atacama',
    'Sierra Gorda',
    'Taltal',
    'Tocopilla',
  ],
  'Región de Arica y Parinacota': ['Arica', 'Camarones', 'General Lagos', 'Putre'],
  'Región de Atacama': [
    'Alto del Carmen',
    'Caldera',
    'Chañaral',
    'Copiapó',
    'Diego de Almagro',
    'Freirina',
    'Huasco',
    'Tierra Amarilla',
    'Vallenar',
  ],
  'Región de Aysén del General Carlos Ibáñez del Campo': [
    'Aysén',
    'Chile Chico',
    'Cisnes',
    'Cochrane',
    'Coyhaique',
    'Guaitecas',
    'Lago Verde',
    "O'Higgins",
    'Río Ibáñez',
    'Tortel',
  ],
  'Región de Coquimbo': [
    'Andacollo',
    'Canela',
    'Combarbalá',
    'Coquimbo',
    'Illapel',
    'La Higuera',
    'La Serena',
    'Los Vilos',
    'Monte Patria',
    'Ovalle',
    'Paihuano',
    'Punitaqui',
    'Río Hurtado',
    'Salamanca',
    'Vicuña',
  ],
  'Región de La Araucanía': [
    'Angol',
    'Carahue',
    'Cholchol',
    'Collipulli',
    'Cunco',
    'Curacautín',
    'Curarrehue',
    'Ercilla',
    'Freire',
    'Galvarino',
    'Gorbea',
    'Lautaro',
    'Loncoche',
    'Lonquimay',
    'Los Sauces',
    'Lumaco',
    'Melipeuco',
    'Nueva Imperial',
    'Padre Las Casas',
    'Perquenco',
    'Pitrufquén',
    'Pucón',
    'Purén',
    'Renaico',
    'Saavedra',
    'Temuco',
    'Teodoro Schmidt',
    'Toltén',
    'Traiguén',
    'Victoria',
    'Vilcún',
    'Villarrica',
  ],
  'Región de Los Lagos': [
    'Ancud',
    'Calbuco',
    'Castro',
    'Chaitén',
    'Chonchi',
    'Cochamó',
    'Curaco de Vélez',
    'Dalcahue',
    'Fresia',
    'Frutillar',
    'Futaleufú',
    'Hualaihué',
    'Llanquihue',
    'Los Muermos',
    'Maullín',
    'Osorno',
    'Palena',
    'Puerto Montt',
    'Puerto Octay',
    'Puerto Varas',
    'Puqueldón',
    'Purranque',
    'Puyehue',
    'Queilén',
    'Quellón',
    'Quemchi',
    'Quinchao',
    'Río Negro',
    'San Juan de la Costa',
    'San Pablo',
  ],
  'Región de Los Ríos': [
    'Corral',
    'Futrono',
    'La Unión',
    'Lago Ranco',
    'Lanco',
    'Los Lagos',
    'Máfil',
    'Mariquina',
    'Paillaco',
    'Panguipulli',
    'Río Bueno',
    'Valdivia',
  ],
  'Región de Magallanes y de la Antártica Chilena': [
    'Antártica',
    'Cabo de Hornos',
    'Laguna Blanca',
    'Natales',
    'Porvenir',
    'Primavera',
    'Punta Arenas',
    'Río Verde',
    'San Gregorio',
    'Timaukel',
    'Torres del Paine',
  ],
  'Región de Ñuble': [
    'Bulnes',
    'Chillán',
    'Chillán Viejo',
    'Cobquecura',
    'Coelemu',
    'Coihueco',
    'El Carmen',
    'Ñiquén',
    'Ninhue',
    'Pemuco',
    'Pinto',
    'Portezuelo',
    'Quillón',
    'Quirihue',
    'Ránquil',
    'San Carlos',
    'San Fabián',
    'San Ignacio',
    'San Nicolás',
    'Treguaco',
    'Yungay',
  ],
  'Región de Tarapacá': [
    'Alto Hospicio',
    'Camiña',
    'Colchane',
    'Huara',
    'Iquique',
    'Pica',
    'Pozo Almonte',
  ],
  'Región de Valparaíso': [
    'Algarrobo',
    'Cabildo',
    'Calle Larga',
    'Cartagena',
    'Casablanca',
    'Catemu',
    'Concón',
    'El Quisco',
    'El Tabo',
    'Hijuelas',
    'Isla de Pascua',
    'Juan Fernández',
    'La Calera',
    'La Cruz',
    'La Ligua',
    'Limache',
    'Llay-Llay',
    'Los Andes',
    'Nogales',
    'Olmué',
    'Panquehue',
    'Papudo',
    'Petorca',
    'Puchuncaví',
    'Putaendo',
    'Quillota',
    'Quilpué',
    'Quintero',
    'Rinconada',
    'San Antonio',
    'San Esteban',
    'San Felipe',
    'Santa María',
    'Santo Domingo',
    'Valparaíso',
    'Villa Alemana',
    'Viña del Mar',
    'Zapallar',
  ],
  'Región del Biobío': [
    'Alto Biobío',
    'Antuco',
    'Arauco',
    'Cabrero',
    'Cañete',
    'Chiguayante',
    'Concepción',
    'Contulmo',
    'Coronel',
    'Curanilahue',
    'Florida',
    'Hualpén',
    'Hualqui',
    'Laja',
    'Lebu',
    'Los Álamos',
    'Los Ángeles',
    'Lota',
    'Mulchén',
    'Nacimiento',
    'Negrete',
    'Penco',
    'Quilaco',
    'Quilleco',
    'San Pedro de La Paz',
    'San Rosendo',
    'Santa Bárbara',
    'Santa Juana',
    'Talcahuano',
    'Tirúa',
    'Tomé',
    'Tucapel',
    'Yumbel',
  ],
  "Región del Libertador General Bernardo O'Higgins": [
    'Chépica',
    'Chimbarongo',
    'Codegua',
    'Coinco',
    'Coltauco',
    'Doñihue',
    'Graneros',
    'La Estrella',
    'Las Cabras',
    'Litueche',
    'Lolol',
    'Machalí',
    'Malloa',
    'Marchihue',
    'Mostazal',
    'Nancagua',
    'Navidad',
    'Olivar',
    'Palmilla',
    'Paredones',
    'Peralillo',
    'Peumo',
    'Pichidegua',
    'Pichilemu',
    'Placilla',
    'Pumanque',
    'Quinta de Tilcoco',
    'Rancagua',
    'Rengo',
    'Requínoa',
    'San Fernando',
    'San Vicente',
    'Santa Cruz',
  ],
  'Región del Maule': [
    'Cauquenes',
    'Chanco',
    'Colbún',
    'Constitución',
    'Curepto',
    'Curicó',
    'Empedrado',
    'Hualañé',
    'Licantén',
    'Linares',
    'Longaví',
    'Maule',
    'Molina',
    'Parral',
    'Pelarco',
    'Pelluhue',
    'Pencahue',
    'Rauco',
    'Retiro',
    'Río Claro',
    'Romeral',
    'Sagrada Familia',
    'San Clemente',
    'San Javier',
    'San Rafael',
    'Talca',
    'Teno',
    'Vichuquén',
    'Villa Alegre',
    'Yerbas Buenas',
  ],
};

export const imageInstruction = {
  catThinkMobile: '../../../cat_thinking_mobile.png',
};

export const plansComparation = {
  heightOffDesktop: 208,
  heightOffMobile: 192,
};

export const separatorSplit = '|';

export const stepRoutePersonalInfo = 'nombre-apellido';
export const stepRouteEmail = 'correo-electronico';
export const stepRouteWorkers = 'numero-trabajadores';
export const stepRoutePlan = 'plan';
export const stepRouteCoverages = 'coberturas';
export const stepRouteVariants = 'cargas';
export const stepRouteForm = 'info-empresa';
export const stepRouteSummary = 'resumen';

export const leadOrigin = 'MIPYMESEGURA';
export const appTitle = 'Seguro colectivo Pyme | BICE VIDA';

export const dropdownAll = 'Todos';

export const incorporationStatusFilter: any = {
  ALL: dropdownAll,
  IN_PROCESS: 'En proceso',
  OUT_FOR_SIGNATURE: 'Por firmar',
  SIGNED: 'Firmado',
  HIRED: 'Cerrado',
};

export const panelListChunkLength = 8;

export const leadStatus = {
  ALL: 'Todos',
  ESTIMATION: 'Cotización',
  HIRING: 'Contratación',
  SIGNED: 'Firmado',
  HIRED: 'Cerrado',
};

export const leadManagerUser = {
  name: 'Deborah',
  img: ICON_USER,
};

export const loadOptions = {
  WITHOUT_LOADS: 'Sin cargas',
  ONE_LOAD: '1 carga',
  TWO_OR_MORE_LOADS: '2 cargas o más',
};

export const totalMonths = 12;

export const maxLengthString = 50;

export const rolesManager = {
  adminRole: 'admin',
  cognitoGroups: 'cognito:groups',
  isSupervisor: 'isSupervisor',
  isExecutiveMS: 'isExecutiveMS',
};

export const filterToday = 'Hoy';
export const filterLast7Days = 'Últimos 7 días';
export const filterLast14Days = 'Últimos 14 días';
export const filterLastMonth = 'Último mes';

export const COOKIE_EXPIRATION_DAYS = 5;

export const landingImageHero = [
  {
    src: '/worker-1.png',
    width: 153,
    height: 222,
    alt: 'Trabajadores protegidos con su seguro colectivo',
  },
  {
    src: '/worker-2.png',
    width: 153,
    height: 222,
    alt: 'Trabajadora protegida con su seguro colectivo',
  },
  {
    src: '/worker-3.png',
    width: 153,
    height: 222,
    alt: 'Trabajadores protegidos con su seguro colectivo',
  },
];

export const pillImage = '/icon-cat-celebrating.png';

export const coveragesIcon = {
  ambulance: '/icon-coverages-card-ambulance.png',
  tooth: '/icon-coverages-card-tooth.png',
  heart: '/icon-coverages-card-heart.png',
  cross: '/icon-coverages-card-cross.png',
};

export const benefitsIcon = {
  phone: '/icon-phone.png',
  pills: '/icon-pills.png',
  todo: '/icon-todo.png',
  cross: '/icon-cross.png',
  pc: '/icon-pc.png',
};

export const quotationIcon = {
  watch: '/icon-why-watch.png',
  cup: '/icon-why-cup.png',
  laptop: '/icon-why-laptop.png',
};

export const whyChooseIcon = {
  domain: '/icon-why-domain.svg',
  union: '/icon-why-union.svg',
  place: '/icon-why-location.svg',
  people: '/icon-why-people.svg',
};

export const featuresIcon = {
  heart: '/hero-icon-heart.png',
  telemedicine: '/features-icon-telemedicine.png',
  cross: '/features-icon-cross.png',
  pills: '/features-icon-pills.png',
  notes: '/features-icon-circle-notes.png',
  money: '/features-icon-money.png',
};

export const benefitsFeaturesIcon = {
  monitor: '/benefits-features-monitor.png',
  capsule: '/benefits-features-capsule.png',
  cross: '/benefits-features-cross.png',
  doctor: '/benefits-features-doctor.png',
  tennis: '/benefits-features-tennis.png',
  todo: '/benefits-features-todo.png',
};

export const iconCircle = '/icon-circle.png';

export const assistancesImg = { pawer: '/pawer_assistance.png' };

export const catGreetings = { png: '/cat-greeting.png', webp: '/cat-greetings.webp' };

export const carouselSliderValues = {
  benefits: {
    steps: 3,
    cardsWidth: 718,
    cardsGap: 14,
  },

  benefitsRefund: {
    steps: 2,
    cardsWidth: 1024,
    btnDistance: 7,
  },
};

export const plansId: any = {
  standard: '5e4afcdbbc7b2e44b63dea55',
  standardDental: '5e4afcf3bc7b2e44b63dea59',
  standardCatastrophic: '5f871dfbffd98b7b3df51a88',
};

export const DATE_FORMAT_STRUCTURE = 'EEE, d LLL yyyy';
export const DATE_FORMAT_FORM = 'dd/MM/yyyy';

export const INITIAL_DATE_MONTHS_TO_ADD = 12;
export const INITIAL_DATE_DAYS_TO_SUBTRACT = 1;

export const MAX_LENGTH_PHONE_NUMBER = 10;

export const EMAILS_TOP_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'msn.com',
  'bice.cl',
];
export const DOMAINS_TO_SUGGESTION = ['com', 'cl'];

export enum StepsEnum {
  COMPANY_NAME = 'COMPANY_NAME',
  SHARE_INCORPORATION = 'SHARE_INCORPORATION',
  INCORPORATION_FORM = 'INCORPORATION_FORM',
  PYME_DATA = 'PYME_DATA',
  CHECK_INCORPORATION = 'CHECK_INCORPORATION',
}

export enum StatesEnum {
  DISABLE = 'DISABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
}

export enum LeadStatusEnum {
  'HIRED' = 'HIRED',
  'PENDING' = 'PENDING',
  'CREATED' = 'CREATED',
}

export const CALL_CENTER_CODE = 'callcenter';

export const EMAIL_CODE_ACTION = {
  retrieve: 'retomar',
  confirm: 'confirmar',
};
export const MINIMUM_SIGNED = 5;

export const GRAPHQL_SESSION_EXPIRE_ERROR = 'GRAPHQL_SESSION_EXPIRE_ERROR';
export const GRAPHQL_PAYLOAD_ERROR = 'GRAPHQL_SESSION_EXPIRE_ERROR';
export const TIME_TO_EXPIRE = 60 * 60 * 24 * 2;

export const ranges = [
  { workers: 15, giftCardAmount: 200000 },
  { workers: 10, giftCardAmount: 150000 },
  { workers: 5, giftCardAmount: 100000 },
];

export enum OriginTypes {
  MIPYMESEGURA = 'MI_PYME_SEGURA',
  LEAD_MANAGER = 'LEAD_MANAGER',
  LINKEDIN = 'LINKEDIN',
  REFERRED = 'REFERRED',
  LINK_FACTORY = 'LINK_FACTORY',
}

export const benefitIcons = {
  pills: '/benefit-icon-circle-pill.png',
  heart: '/benefit-icon-circle-heart.png',
  health: '/benefit-icon-circle-health.png',
};

export const originList = [
  { description: 'Mi Pyme Segura', code: OriginTypes.MIPYMESEGURA },
  { description: 'Lead Manager', code: OriginTypes.LEAD_MANAGER },
  { description: 'Linkedin', code: OriginTypes.LINKEDIN },
  { description: 'Referido', code: OriginTypes.REFERRED },
  { description: 'Link Factor', code: OriginTypes.LINK_FACTORY },
];

export const brandingCompanyHeroes = 'losheroes';
