export const CAT = {
  CAT_FACE_FRONT_SMILE: '/landing-hero-cat.jpg',
  CAT_FULL_FRONT: '/landing-banner-cat.png',
  CAT_FULL_CALL_CENTER: '/cat-full-call-center.png',
  CAT_FULL_BANNER_MOBILE: '/cat-full-banner-mobile.png',
  CAT_FULL_BANNER: '/cat-full-banner.png',
  CAT_BANNER_LANDING_MOBILE: '/cat-landing-banner-mobile.png',
  CAT_BANNER_LANDING: '/cat-landing-banner.png',
  CAT_BANNER_COVERAGES_MOBILE: '/cat-banner-coverages-mobile.png',
  CAT_BANNER_COVERAGES: '/cat-banner-coverages.png',
  CAT_HIRING_FLOATING_BOX_IMAGE: 'icon-cat-float-box.png',
  CAT_SUCCESS_YELLOW_CIRCLE: '/cat_success.png',
  CAT_THINKING: '/cat-thinking.png',
  THINKING_TRANSPARENT: '/cat-thinking-transparent.png',
  CAT_OPEN_ARMS: '/cat_open_arms.png',
  CAT_POINTING: '/cat_pointing.png',
  CAT_CIRCLE: '/cat-circle.png',
  CAT_ENERGY_ROUND: '/icon-cat-energy-round.png',
  CAT_THINKING_ROUND: '/icon-cat-thinking-round.png',
  CAT_CYBERDAY: '/cat-cyberday.png',
  CAT_POINTING_BENEFITS: '/cat-pointing.png',
  CAT_WELCOME_BENETITS: '/cat-welcome-round.png',
};

export const CAT_V2 = {
  CAT_JACKET: '/cat_jacket.png',
};

export const CAT_LANDING_BANNER = {
  src: '/landing-banner-cat.png',
  webP: '/landing-banner-cat.webp',
};

export const LOGO = {
  WHITE: '/logo-white.svg',
  COLOR: '/logo-color.svg',
};
export const RRSS = {
  FACEBOOK: '/icon-footer-facebook.svg',
  TWITTER: '/icon-footer-twitter.svg',
  INSTAGRAM: '/icon-footer-instagram.svg',
  YOUTUBE: '/icon-footer-youtube.svg',
  X: '/icon-footer-x.svg',
};
export const ICON_SHIELD = '/landing-hero-cat-shield.svg';
export const ICON_STAR = '/landing-hero-star.svg';
export const COVID = '/icon-toaster-covid.svg';
export const INSURANCE_HEALTH = '/landing_insurance_health.svg';
export const INSURANCE_LIFE = '/landing_insurance_life.svg';
export const INSURANCE_DENTAL = '/landing_insurance_dental.svg';
export const INSURANCE_CATASTROPHIC = '/landing_insurance_catastrophic.svg';
export const BENEFIT_MASK = '/benefit-imgmask.jpg';
export const BENEFIT_NUTRI = '/benefit-imgnutri.jpg';
export const BENEFIT_KINE = '/benefit-imgkine.jpg';
export const BENEFIT_BV_MORE = '/icon-benefits-bv.svg';
export const BENEFITS_CARD_PYME = {
  src: '/benefits-card-pyme.jpg',
  webP: '/benefits-card-pyme.webp',
};
export const BENEFITS_CARD_DIGITAL = '/benefits-card-digital.png';
export const BENEFITS_CARD_TELEHEALTH = '/benefits-telehealth.png';

export const TELEMEDICINE_CARD = '/telemedicine-card.png';

export const NUBOX_CARD = '/benefits-nubox.png';

export const BENEFITS_CARD_AGREEMENT = '/benefits-card-agreement.jpg';

export const BENEFITS_CARD_CIRCLE = '/benefits-card-circle.jpg';

export const BENEFITS_CARD_COVERAGE = '/benefits-card-coverage.jpg';

export const BENEFITS_HEALTH_SCAN = {
  src: '/benefit-imgscan.jpg',
  webP: '/benefit-imgscan.webp',
};
export const BENEFITS_HEALTH_DOCTOR = {
  src: '/benefit-imgdoctor.jpg',
  webP: '/benefit-imgdoctor.webp',
};
export const BENEFITS_HEALTH_TEST = {
  src: '/benefit-imgtest.jpg',
  webP: '/benefit-imgtest.webp',
};
export const BENEFITS_HEALTH_EYE = {
  src: '/benefit-imgeye.jpg',
  webP: '/benefit-imgeye.webp',
};
export const BENEFITS_HEALTH_MEDICINE = {
  src: '/benefit-imgmedicine.jpg',
  webP: '/benefit-imgmedicine.webp',
};
export const BENEFITS_HEALTH_PILL = {
  src: '/benefit-imgpill.jpg',
  webP: '/benefit-imgpill.webp',
};
export const BENEFITS_LOGO_HOSPITAL_ONE = '/logo-hospital-one.jpg';
export const BENEFITS_LOGO_HOSPITAL_TWO = '/logo-hospital-two.jpg';
export const BENEFITS_LOGO_HOSPITAL_THREE = '/logo-hospital-three.jpg';
export const BENEFITS_LOGO_HOSPITAL_FOUR = '/logo-hospital-four.jpg';
export const BENEFITS_HOSPITAL_BRANDS = '/benefits-hospital-brands.png';
export const BENEFITS_ICON_PILLS = '/benefits-icon-pills.png';
export const BENEFITS_ICON_MEDICAL_CASE = '/benefits-icon-medical-case.png';
export const BENEFITS_ICON_SNAKE = '/benefits-icon-snake.png';
export const BENEFITS_ICON_TUBE = '/benefits-icon-tube.png';
export const BENEFITS_PHARMACY_REFUND = '/benefits_pharmacy_refund.png';
export const BENEFITS_PHARMACY = '/benefits_pharmacy.png';
export const ICON_BLUE_DOCUMENT = '/icon-blue-document.svg';
export const DEMO_ICON = '/demo-icon.png';

export const BENEFITS_CIRCLE = {
  CUP: '/circle_bice_cup.png',
  HEART: '/circle_bice_heart.png',
  LAMP: '/circle_bice_lamp.png',
  PILLS: '/circle_bice_pills.png',
  TOOTH: '/circle_bice_tooth.png',
  LOGO: '/circle_bice_logo.png',
};

export const BENEFITS_IMAGES = {
  TELEMEDICINE: '/benefit-telemedicine.png',
  DRUGSTORE: '/benefit-drugstore.png',
  REFUND: '/benefit-refund.png',
  HOSPITAL_COVERAGE: '/benefit_hospital_coverage.png',
  DIGITAL: '/benefit_digital.png',
  CIRCLE: '/benefit_circle.png',
};

export const HERO_ICON = {
  HEART: '/hero-icon-heart.png',
  SHIELD: '/hero-icon-shield.png',
  FOLDERS: './hero-folder.png',
};

export const ESTIMATE_CUSTOMIZE = '/icon-estimate-customize.svg';
export const ESTIMATE_CONTACT = '/icon-estimate-contact.svg';
export const ESTIMATE_HIRE = '/icon-estimate-hire.svg';
export const WAVE_BANNER_LANDING = '/landing-banner-wave.svg';
export const ICON_WORKING = '/icon-working.svg';
export const ICON_HIRE_MODAL = '/icon-hire-landing-modal.png';
export const MODAL_INFORMATION_FORM = '/modal-information-form.png';
export const ICON_MODAL_CHECK = '/form_modal_check.png';
export const LOGO_BICE = '/logo-bice.png';

export const HELP_DESK_ICONS = (icon: string) => `/help-desk-icon-${icon}.svg`;
export const ICON_EMAIL = '/email.svg';
export const ICON_PHONE = '/icon-phone.svg';
export const FEEDBACK_THANKS_MODAL = '/duo-hearts.svg';
export const KEEP_IMPROVING_MODAL = '/keep-improving.svg';
export const INSURANCE_LIFE_PLUS_CIRCLE = '/icon-coverages-card-heart.png';
export const INSURANCE_DENTAL_CIRCLE = '/icon-coverages-card-tooth.png';
export const INSURANCE_CATASTROPHIC_CIRCLE = '/icon-coverages-card-ambulance.png';
export const ICON_CHARGES: any = {
  iconWithCharges: '/icon-with-charges.svg',
  iconWithoutCharges: '/icon-without-charges.svg',
  iconWithMoreCharges: '/icon-with-more-charges.svg',
};
export const ICON_ARROW_CAT_DIALOGUE = '/icon-arrow-cat-dialogue.svg';
export const IMG_GREETING_CAT_DIALOGUE = '/icon-personal-info-cat.png';
export const IMG_EMAIL_CAT_DIALOGUE = '/icon-email-cat.png';
export const IMG_WORKERS_CAT_DIALOGUE = '/icon-workers-cat.png';
export const IMG_PLANS_CAT_DIALOGUE = '/icon-plans-cat.png';
export const ICON_WORKERS_DIALOGUE = '/icon-workers-thinking-cat.svg';
export const IMG_COVERAGES_CAT_DIALOGUE = '/icon-coverages-cat.png';
export const IMG_FORM_CAT_DIALOGUE = '/icon-form-cat.png';
export const IMG_SUCCESS_CHECK = '/icon-success-check-cat.svg';
export const IMG_SUMMARY_STEP_CAT = '/summary-step-cat.jpg';
export const IMG_CAT_BOX = '/icon-cat-box.png';
export const ICON_PROMO = '/icon_promo.svg';

export const ICON_UP_ARROW_CIRCLE_GREEN = '/up_icon_green_circle.svg';
export const ICON_CLOSE_CIRCLE_RED = '/close_icon_red_circle.svg';
export const IMG_ERROR_CIRCLE_RED = '/form_modal_fail.svg';
export const IMG_META_SOCIAL_CAT = '/meta_social_cat.png';

export const IMG_COFFEE = '/coffe.svg';

export const ICON_USER = '/icon-user.svg';

export const IMG_LINK = '/link.svg';

export const SEARCH = '/search.svg';

export const ICON_HIRING_ADMIN_PROFILE = '/icon-hiring-admin-profile.png';
export const IMG_COVERAGE_AMBULANCE = '/img_coverage_ambulance.png';
export const IMG_COVERAGE_DENTAL = '/img_coverage_dental.png';
export const IMG_COVERAGE_HEALTH = '/img_coverage_health.png';
export const IMG_COVERAGE_HEART = '/img_coverage_heart.png';
export const IMG_COVERAGE_PAWER = '/pawer-cat-and-dog.png';

export const IMG_TARGET = {
  DESKTOP: '/target-image.png',
  MOBILE: '/target-image-mobile.png',
};

export const ICON_CHECK = '/icon-check.svg';
export const ICON_CHECK_CIRCLE = '/form_modal_check.svg';

export const IMG_PRICES_AND_PLANS = {
  AMBULANCE: '/prices-and-plans-ambulance.png',
  DENTAL: '/prices-and-plans-dental.png',
};

export const ICON_DOWNLOAD = '/download-document-step.svg';

export const LOGO_CYBER_DAY = '/logo-cyberday.png';

export const CYBER_IMAGES = {
  LOGO: '/promo_banner/cyber-logo.png',
  CAT_POINTER: '/promo_banner/cat-pointer.png',
  CAT_POINTER_CIRCLE: '/promo_banner/cat-pointer-circle.png',
  GRADIENT_ARC: '/promo_banner/gradient-arc.png',
  REVIEW: '/promo_banner/cyber-review.png',
};

export const PDFS = {
  PLANS: '/Coberturas_Seguro_Colectivo_Pyme.pdf',
  POLICY: '/Poliza_Seguro_Colectivo_Pyme.pdf',
  BROCHURE: '/Folleto_Mi_Pyme_Segura.pdf',
  CYBER: '/pdfs/bases/Bases_PYME_Campana_Cyber_Rev_2024.pdf',
  POST_CYBER: '/pdfs/bases/Bases_PYME_Extendimos_octubre_2023.pdf',
  CYBER_EXTEND: '/pdfs/bases/Bases_PYME_Extendimos_Cyber_Rev_2024.pdf',
  PROMO_NUBOX: '/pdfs/bases/Bases_Promo_Nubox.pdf',
  PROMO_CHIPAX: '/pdfs/bases/Bases_Promo_Chipax.pdf',
  PROMO_AON_CYBER: '/pdfs/bases/Bases_Promo_Aon_octubre_2023.pdf',
  TELEHEALTH: '/pdfs/telehealth.pdf',
  PHARMACY: '/pdfs/Red_Farmacias_abr2024.pdf',
};

export const PYME_DATA_ICON = '/pyme-data-icon.png';
export const PYME_FORM_ICON = '/pyme-form-icon.png';
export const PYME_PROGRESS_ICON = '/pyme-progress-icon.png';
export const SUMMARY_INFO_ICON = '/icon-alert-info-summary.svg';
export const WARNING_CIRCLE_ICON = '/warning-icon-circle.png';
export const WARNING_ICON = '/warning-icon.png';
export const EMPTY_COMPANY_DATA_ICON = '/empty-company-data-icon.png';
export const TODO_LIST_ICON = '/todo-list.png';
export const TODO_LIST_ICON_V2 = '/todo_list_v2.png';

export const BENEFITS_LOGOS = [
  { img: '/logo-imed.png', alt: 'imed' },
  { img: '/logo-cruzverde.png', alt: 'cruz verde' },
  { img: '/logo-placevendome.png', alt: 'place vendome' },
  { img: '/logo-gmo.png', alt: 'gmo' },
  { img: '/logo-chilling.png', alt: 'chilling' },
  { img: '/logo-rotterykrauss.png', alt: 'rotter y krauss' },
];

export const HIRING_COMPANY_ICONS = {
  COMPANY_DATA_ICON: '/company-data-icon.png',
  ADMIN_ICON: '/who-admin-icon.png',
};

export const SVG_LIST = {
  incorporationPreModal: '/incorporation-pre-modal.svg',
  iconDocumentSummary: '/icon-document.svg',
  iconWithCharges: '/icon-with-charges.svg',
  iconSummaryInfo: '/icon-summary-info.svg',
  formModalCheck: '/form_modal_check.svg',
  formModalFail: '/form_modal_fail.svg',
  iconWorking: '/icon-working.svg',
  iconHotPopularplan: '/icon-hot-popular-plan.svg',
  imgModalIndividualInsurance: '/icon-workers-warning.png',
  riskyActivitiesInfoModal: '/risky-sport-info-modal-step.svg',
  familyGroupInfoModal: '/family-group-info-modal-step.svg',
  beneficiariesInfoModal: '/beneficiaries-info-modal-step.svg',
  downloadDocument: '/download-document-step.svg',
  incorporationSent: '/incorporation-sent.svg',
  incorporationPending: '/incorporation-pending.svg',
  iconCoffe: '/coffe.svg',
  sendEmail: '/email.svg',
  iconSuccessCheckCat: '/icon-success-check-cat.svg',
  iconModalHoldingCat: '/cat-thinking.png',
  iconTaskSuccess: '/task-success.svg',
  iconCalendar: '/icon-calendar.svg',
  variantFolder: '/icon-folder-circle-variant.png',
};

export const REQUERIMENTS_TO_CONTRACT = {
  callcenter: '/icon-callcenter-circle.png',
  folder: '/icon-folder-circle.png',
  heart: '/icon-heart-circle.png',
  like: '/icon-like-circle.png',
  notebook: '/icon-notebook-circle.png',
  wifi: '/icon-wifi-circle.png',
};
