import { gql } from '@apollo/client';

export default gql`
  fragment ufFragment on UF {
    _id
    day
    month
    year
    date
    value
    deleted
    createdAt
    updatedAt
  }
`;
