import { gql } from '@apollo/client';

export default gql`
  fragment coverageFragment on Coverage {
    description
    type
    value
    details {
      title
      description
      categories {
        title
        default
        totalMaximum {
          description
          value {
            uf
          }
        }
        items {
          description
          bonus {
            value {
              percentage
            }
          }
          maximum {
            includeValue
            description
            value {
              uf
            }
          }
        }
      }
    }
  }
`;
