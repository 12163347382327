import { SET_FEATURE_FLAGS } from './actionTypes';
import { FeatureFlags } from '@/src/types/featureFlags';

const initialState = {};

const reducer = (state: FeatureFlags = initialState, { type, payload }: any): FeatureFlags => {
  switch (type) {
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default reducer;
