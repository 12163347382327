export const SET_INCORPORATION_PROGRESS = 'SET_INCORPORATION_PROGRESS';
export const RESET_INCORPORATION_PROGRESS = 'RESET_INCORPORATION_PROGRESS';

export const SET_FORMS_IN_PROGRESS = 'SET_FORMS_IN_PROGRESS';
export const RESET_FORMS_IN_PROGRESS = 'RESET_FORMS_IN_PROGRESS';

export const SET_FORMS_TO_SIGN = 'SET_FORMS_TO_SIGN';
export const RESET_FORMS_TO_SIGN = 'RESET_FORMS_TO_SIGN';

export const SET_FORMS_SIGNED = 'SET_FORMS_SIGNED';
export const RESET_FORMS_SIGNED = 'RESET_FORMS_SIGNED';

export const SET_WORKERS_PROGRESS = 'SET_WORKERS_PROGRESS';
export const RESET_WORKERS_PROGRESS = 'RESET_WORKERS_PROGRESS';

export const SET_EMPTY_PROGRESS = 'SET_EMPTY_PROGRESS';
export const RESET_EMPTY_PROGRESS = 'RESET_EMPTY_PROGRESS';

export const SET_FORMS_BY_STATUS = 'SET_FORMS_BY_STATUS';
export const RESET_FORMS_BY_STATUS = 'RESET_FORMS_BY_STATUS';

export const SET_LAST_REFRESH = 'SET_LAST_REFRESH';
