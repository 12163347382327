import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import networkErrorLink from './link/networkErrorLink';
import authLink from './link/authLink';
import { httpLink } from './link/httpLink';

const gateway = process.env.graphqlGateway;

const apolloClient = new ApolloClient({
  uri: gateway || '',
  link: from([networkErrorLink, authLink, httpLink(gateway)]),
  cache: new InMemoryCache(),
});

export default apolloClient;
